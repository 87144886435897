<template>
  <div v-if="d_selectedProjectData" id="polestar-wisdom-stats" @click="f_clickAnyWhereInThePage()" @contextmenu.prevent="f_rightClickAnyWhereInThePage" :style="'color:' + d_polestarSettings.style.color + '; background-color: ' + d_polestarSettings.style.backgroundColor">
    <b-card no-body header-tag="header" key="wisdom-stats-screen" :class="[d_fullScreenGeneral ?'full-screen-mode' :'normal-mode']">
      <b-card-header class="p-1" :style="'color:' + d_polestarSettings.style.project_header.color + '; background-color: ' + d_polestarSettings.style.project_header.backgroundColor">
        <b-row>
          <!-- <b-col cols="1"> </b-col> -->
          <b-col cols="8">
            <img src="@/icon/2933568.png" style="width: 2em; margin-right: 5px; padding: 0px;" />
            <template v-if="d_selectedProjectData && d_selectedProjectData.data">
              <strong :title="d_selectedProjectData.data.general.name.val" style="margin-right: 10px;">{{ d_selectedProjectData.data.general.name.val }} ,</strong>
              <i>{{ d_selectedProjectData.created_by }} - </i>
              <i>{{ DateFormat(d_selectedProjectData.created_at) }}</i>
            </template>
          </b-col>
          <b-col cols="1" style="text-align: center;">
            <template v-if="d_timerControlProjectUpdate.status === 'active'">
              <b-button class="pull-right" size="md" variant="white" style="margin-right: 5px; padding: 0px;" @click="d_activityData.show = true;">
                <img src="@/icon/sandwatch.gif" :title="f_calculateContinuingTitle()" style="width: 2em;" />
              </b-button>
            </template>
          </b-col>
          <b-col cols="2" style="text-align: center;">
            <strong style="color: #50afc9; text-align: right"> Wanalyzer <img src="@/icon/890016.png" style="width: 2em;" /> </strong>
          </b-col>
          <b-col cols="1">
            <b-button class="pull-right" size="md" :variant="d_fullScreenGeneral ? 'warning' : 'secondary'" :style="d_fullScreenGeneral ? 'margin-right: 5px; padding: 0px; border: solid 2px green;' : 'margin-right: 5px; padding: 0px;'" @click="d_fullScreenGeneral ? d_fullScreenGeneral = false : d_fullScreenGeneral = true">
              <img src="@/icon/629153.png" title="Tam ekran" style="width: 2em;" />
            </b-button>
          </b-col>
        </b-row>
      </b-card-header>
      <wisdom-stats-menu :d_dataset="d_dataset" :column_list="column_list" :option_data="option_data" :p_selectedProjectData="d_selectedProjectData" :mother_children="mother_children" :p_f_showArticleList="f_showArticleList" :p_f_analyzeThisPaginationRowsByThisDecisionMakingPackage="f_analyzeThisPaginationRowsByThisDecisionMakingPackage" :p_f_analyzeAllDatasetByDmpSmsOperation="f_analyzeAllDatasetByDmpSmsOperation" :p_f_analyzeAlgorithmAndSendResultWithApi="f_analyzeAlgorithmAndSendResultWithApi" :p_f_fastAnalyzeMode="f_fastAnalyzeMode" :f_goToThisProject="f_goToThisProject" :f_editProject="f_editProject" :f_prepareProjectCases="f_prepareProjectCases" :f_cancelProjectUpdate="f_cancelProjectUpdate" :f_getCases="f_getCases" :d_eligibleForColumnOptionMixedWdmrRuleMatch="d_eligibleForColumnOptionMixedWdmrRuleMatch" :d_eligibleForColumnOptionRuleMatch="d_eligibleForColumnOptionRuleMatch" :d_eligibleForWdmrRuleMatch="d_eligibleForWdmrRuleMatch" :d_eligibleForWmdrRuleSearch="d_eligibleForWmdrRuleSearch" :d_selected_cells="d_selected_cells" :p_polestarSettings="d_polestarSettings" :f_savePoleStarProject="f_savePoleStarProject" :f_createNewMachineLearning="f_createNewMachineLearning" :p_userPolestarPermissions="d_userPolestarPermissions" :f_editMainRuleAlgorithm="f_editMainRuleAlgorithm" :f_addNewChildWisdomData="f_addNewChildWisdomData" :f_prepareWdm73="f_prepareWdm73" :f_printArticlePdf="f_printArticlePdf" :f_getSqlOfMainRule="f_getSqlOfMainRule" :f_addNewPhraseModal="f_addNewPhraseModal" :f_controlAndCreateTimer="f_controlAndCreateTimer" :f_deleteProjectDatasetList="f_deleteProjectDatasetList" :f_mergeProjects="f_mergeProjects"></wisdom-stats-menu>
      <b-card key="down_side" no-body header-tag="header" :style="'margin-bottom: 0px; background-color: ' + d_polestarSettings.style.backgroundColor + '; color: ' + d_polestarSettings.style.color" :class="[d_fullScreen ?'full-screen-mode' :'normal-mode']">
        <div key="table_upper_button_list">
          <b-row>
            <b-col cols="6">
              <b-button id="data_table" :style="'margin-right: 2px; background-color: ' + d_polestarSettings.style.backgroundColor + '; color: ' + d_polestarSettings.style.color" size="sm" :variant="d_showMode ==='data_table' ?'warning' :'secondary'" @click="f_selectViewMode('data_table')" title="Dataset Görünümü">
                <i class="fa fa-2x fa-database" style="color: #0c25e5;"></i>
                <!-- dataset -->
              </b-button>
              <b-button id="data_table" :style="'margin-right: 2px; background-color: ' + d_polestarSettings.style.backgroundColor + '; color: ' + d_polestarSettings.style.color" size="sm" :variant="d_showMode ==='data_table' ?'warning' :'secondary'" @click="f_selectViewMode('wtable')" title="Wtable">
                <i class="fa fa-2x fa-table" style="color: #0ce573;"></i>
                <!-- dataset -->
              </b-button>
              <b-button id="analyze_results" :style="'margin-right: 2px; background-color: ' + d_polestarSettings.style.backgroundColor + '; color: ' + d_polestarSettings.style.color" size="sm" :variant="d_showMode ==='analyze_results' ?'warning' :'secondary'" @click="f_selectViewMode('analyze_results')" title="İstatistiksel Analizler">
                <i class="fa fa-2x fa-bar-chart" style="color: #0cc3e5;"></i>
                <!-- <img src="@/icon/1584256.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> -->
                <!-- statistial analyzes -->
              </b-button>
              <b-button id="machine_learning" :style="'margin-right: 2px; background-color: ' + d_polestarSettings.style.backgroundColor + '; color: ' + d_polestarSettings.style.color" size="sm" :variant="d_showMode ==='machine_learning' ?'warning' :'secondary'" @click="f_selectViewMode('machine_learning')" title="Makine Öğrenme Algoritmaları">
                <i class="fa fa-2x fa-diamond" style="color: #e50c83;"></i>
                <!-- <img src="@/icon/2956643.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> -->
                <!-- machine_learning -->
              </b-button>
              <b-button id="article_list" :style="'margin-right: 2px; background-color: ' + d_polestarSettings.style.backgroundColor + '; color: ' + d_polestarSettings.style.color" size="sm" :variant="d_showMode ==='article_list' ?'warning' :'secondary'" @click="f_selectViewMode('article_list')" title="Makale Listesi">
                <i class="fa fa-2x fa-book" style="color: #e5ba0c;"></i>
                <!-- <img src="@/icon/70094.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> -->
                <!-- article_list -->
              </b-button>
              <!-- 
                <b-button id="column_filter_show" :style="'margin-right: 2px; background-color: ' + d_polestarSettings.style.backgroundColor + '; color: ' + d_polestarSettings.style.color" size="sm" :variant="d_showMode ==='column_filter_show' ?'warning' :'secondary'" @click="f_columnRuleShow()" title="Vaka Filtresi Yapma Alanı">
                  <img src="@/icon/1517831.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                </b-button>
               -->
              <b-button id="column_list_and_features" :style="'margin-right: 2px; background-color: ' + d_polestarSettings.style.backgroundColor + '; color: ' + d_polestarSettings.style.color" size="sm" :variant="d_showMode ==='column_list_and_features' ?'warning' :'secondary'" @click="f_selectViewMode('column_list_and_features')" title="kolonlar">
                <!-- <img src="@/icon/969174.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> -->
                <i class="fa fa-2x fa-columns" style="color: #0cb3e5;"></i>
                <!-- columns -->
              </b-button>
              <b-button id="project_details" :style="'margin-right: 2px; background-color: ' + d_polestarSettings.style.backgroundColor + '; color: ' + d_polestarSettings.style.color" size="sm" :variant="d_showMode ==='project_details' ?'warning' :'secondary'" @click="f_selectViewMode('project_details')" title="Proje Detayları">
                <i class="fa fa-2x fa-pencil" style="color: #a00ce5;"></i>
                <!-- <img src="@/icon/2930471.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> -->
                <!-- project_details -->
              </b-button>
              <b-button id="dmp_list" :style="'margin-right: 2px; background-color: ' + d_polestarSettings.style.backgroundColor + '; color: ' + d_polestarSettings.style.color" size="sm" :variant="d_showMode ==='dmp_list' ?'warning' :'secondary'" @click="f_selectViewMode('dmp_list')" title="Karar Destek Paketleri">
                <!-- <img src="@/icon/1115721.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> -->
                <i class="fa fa-2x fa-gears" style="color: #e50c4e;"></i>
              </b-button>
              <b-button id="dmp_list" :style="'margin-right: 2px; background-color: ' + d_polestarSettings.style.backgroundColor + '; color: ' + d_polestarSettings.style.color" size="sm" :variant="d_showMode ==='phrase_list' ?'warning' :'secondary'" @click="f_selectViewMode('phrase_list')" title="İfade çalışmaları">
                <!-- <img src="@/icon/139088.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> -->
                <i class="fa fa-2x fa-file-text" style="color: #0c93e5;"></i>
              </b-button>
              <b-button id="dmp_list" :style="'margin-right: 2px; background-color: ' + d_polestarSettings.style.backgroundColor + '; color: ' + d_polestarSettings.style.color" size="sm" :variant="d_showMode ==='project_report' ?'warning' :'secondary'" @click="f_selectViewMode('project_report')" title="Proje analiz raporları">
                <!-- <img src="@/icon/2063326.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> -->
                <i class="fa fa-2x fa-area-chart" style="color: #42e50c;"></i>
              </b-button>
              <b-button id="dmp_list" :style="'margin-right: 2px; background-color: ' + d_polestarSettings.style.backgroundColor + '; color: ' + d_polestarSettings.style.color" size="sm" :variant="d_showMode ==='location_count_results' ?'warning' :'secondary'" @click="f_selectViewMode('location_count_results')" title="Lokasyon veri adedi">
                <i class="fa fa-2x fa-balance-scale" style="color: #0c99e5;"></i>
                <!-- <img src="@/icon/1115721.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> -->
              </b-button>
            </b-col>
            <b-col cols="6">
              <b-button v-if="d_dataset.menu && d_dataset.menu.data && d_dataset.menu.data.column_filter_mode && d_dataset.menu.data.column_filter_mode.status" size="sm" @click="f_getCases('first_prepared_dataset')" style="margin: 3px;">
                <img src="@/icon/1517831.png" style="width: 20px;" /> filtreyi uygula
              </b-button>
              <b-button class="pull-right" size="md" :variant="d_fullScreen ?'warning' :'secondary'" :style="d_fullScreen ?'margin-right: 5px; padding: 0px; border: solid 2px green;' :'margin-right: 5px; padding: 0px;'" @click="d_fullScreen ? d_fullScreen = false : d_fullScreen = true">
                <img src="@/icon/629153.png" :title="$t('wdm16.6589')" style="width: 2em;" />
              </b-button>
              <template v-if="d_showMode ==='data_table'">
                <b-pagination size="sm" class="pull-right" :style="'margin-bottom: 0px; margin-right: 5px; background-color: ' + d_polestarSettings.style.backgroundColor + '; color: ' + d_polestarSettings.style.color" aria-controls="column_pagination" v-model="d_tableModeSettings.current" :total-rows="column_list.length" :per-page="d_tableModeSettings.perpage"></b-pagination>
                <input class="pull-right" type="text" :style="'width: 200px; margin-right: 5px; padding: 3px; font-size: 10px; background-color: ' + d_polestarSettings.style.backgroundColor + '; color: ' + d_polestarSettings.style.color" v-model="d_searchColumnTextTimer.text" placeholder="kolon filtrelemek için yazınız" />
                <b-button class="pull-right" @click="d_showColumnSelectionModal = true" variant="secondary" size="md" style="margin-right: 5px; padding: 0px;">
                  <img src="@/icon/3162318.png" title="Seçilen Kolonları Gösterme Modu" style="width: 2em;" />
                </b-button>
                <b-button class="pull-right" @click="f_fastAnalyzeMode()" variant="secondary" size="md" style="margin-right: 5px; padding: 0px;">
                  <img src="@/icon/665778.png" title="Hızlı Analiz Modu" style="width: 2em;" />
                </b-button>
              </template>
            </b-col>
          </b-row>
        </div>
        <div id="case_table" :style="f_dataSetStyle(310)" class="general_scroll_class" tabindex="0" @keydown="f_tableKeyDown">
          <template v-if="d_showMode ==='data_table'">
            <wisdom-stats-data-table key="data_table" :d_dataset="d_dataset" :column_list="column_list" :option_data="option_data" :p_selectedProjectData="d_selectedProjectData" :mother_children="mother_children" :p_f_fastAnalyzeMode="f_fastAnalyzeMode" :d_rowDmpAnalyzeResults="d_rowDmpAnalyzeResults" :f_selectCell="f_selectCell" :f_calculateRowNoStyle="f_calculateRowNoStyle" :f_calculateStyleDatasetTable="f_calculateStyleDatasetTable" :d_searchColumnTextTimer="d_searchColumnTextTimer" :f_editThisCell="f_editThisCell" :d_editCell="d_editCell" :d_cellTextTimer="d_cellTextTimer" :d_selected_cells="d_selected_cells" :d_datasetPagination="d_datasetPagination" :d_tableModeSettings="d_tableModeSettings" :f_showRightClickMenu="f_showRightClickMenu" :p_polestarSettings="d_polestarSettings" :f_getAndShowThisWdmr="f_getAndShowThisWdmr" :p_userPolestarPermissions="d_userPolestarPermissions" :f_thisRuleHasTrueResult="f_thisRuleHasTrueResult" :f_controlAndCreateTimer="f_controlAndCreateTimer"></wisdom-stats-data-table>
          </template>
          <template v-if="d_showMode ==='wtable'">
            <template v-if="d_datasetViewLoading">
                <b-spinner variant="primary" label="Spinning"></b-spinner>
            </template>
            <template v-else>
              <w-table2 v-if="d_datasetWTable" style="transition: 2s; transform: none;" :key="'wtable2'" :p_tableDetails="d_datasetWTable"></w-table2>
            </template>
          </template>
          <b-row v-if="d_showMode ==='analyze_results'" key="analyze_results">
            <b-col cols="4">
              <b-navbar style="padding: 0px;">
                <b-nav pills>
                  <b-nav-item @click="d_navAnalyzeList ='analyze_model_list'" :active="d_navAnalyzeList ==='analyze_model_list'"> Analiz Modelleri Listesi </b-nav-item>
                </b-nav>
              </b-navbar>
              <b-card v-if="d_navAnalyzeList ==='analyze_model_list'" no-body header-tag="header" :style="'margin-bottom: 0px; background-color: ' + d_polestarSettings.style.backgroundColor + '; color: ' + d_polestarSettings.style.color">
                <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
                  Analiz Modelleri
                  <b-dropdown variant="success" size="md" style="margin: 5px;" class="pull-right">
                    <b-dropdown-item @click="f_createNewWdm72()"> yeni analiz modeli </b-dropdown-item>
                    <b-dropdown-item @click="f_articleReportByStatResults('1', ['tr'])"> Analiz Raporu Oluştur (Mod 1) [tr] (Excel içerisinde p küçük 0.05 sonuçlar ve tanımlayıcı istatistikler) </b-dropdown-item>
                    <b-dropdown-item @click="f_articleReportByStatResults('1', ['en'])"> Analiz Raporu Oluştur (Mod 1) [en] (Excel içerisinde p küçük 0.05 sonuçlar ve tanımlayıcı istatistikler) </b-dropdown-item>
                    <b-dropdown-item @click="f_articleReportByStatResults('1', ['tr', 'en'])"> Analiz Raporu Oluştur (Mod 1) [tr, en] (Excel içerisinde p küçük 0.05 sonuçlar ve tanımlayıcı istatistikler) </b-dropdown-item>
                    <b-dropdown-item @click="f_articleReportByStatResults('2', ['tr'])"> Analiz Raporu Oluştur (Mod 2, tüm veriler) [tr] (Excel içerisinde tüm sonuçlar ve tanımlayıcı istatistikler) </b-dropdown-item>
                    <b-dropdown-item @click="f_articleReportByStatResults('2', ['en'])"> Analiz Raporu Oluştur (Mod 2, tüm veriler) [en] (Excel içerisinde tüm sonuçlar ve tanımlayıcı istatistikler) </b-dropdown-item>
                    <b-dropdown-item @click="f_articleReportByStatResults('2', ['tr', 'en'])"> Analiz Raporu Oluştur (Mod 2, tüm veriler) [tr, en] (Excel içerisinde tüm sonuçlar ve tanımlayıcı istatistikler) </b-dropdown-item>
                  </b-dropdown>
                </b-card-header>
                <template v-if="d_selectedProjectData.data.statistical_analyze && d_selectedProjectData.data.statistical_analyze.statistics">
                  <div :style="f_calculateAnalyzeModelListStyle()">
                    <template v-for="(stat, stat_ind) in d_selectedProjectData.data.statistical_analyze.statistics.list">
                      <b-row :style="d_selectedWdm72Key === stat.wdm72_key.val ?'margin: 0px; cursor: pointer; border: 1px solid rgba(158, 193, 179, 0.3); background-color: rgba(158, 193, 179, 0.3); min-height: 50px;' :'margin: 0px; cursor: pointer; border: 1px solid rgba(158, 193, 179, 0.3); min-height: 50px;'">
                        <b-col cols="10" @click="f_selectWdm72(stat.wdm72_key.val)">
                          <div :title="stat.wdm72_name.val">{{ stat.wdm72_name.val.substring(0, 100) }}</div>
                          <!-- 
                            <template v-if="stat.analyzed_at && stat.analyzed_at.val">
                              <div>
                                <small>Analiz Tarihi: {{ DateFormatWithHourMin(stat.analyzed_at.val) }} </small>
                              </div>
                            </template>
                            <template v-if="stat.last_update_time_of_project_cases && stat.last_update_time_of_project_cases.val && d_dataset.created_at">
                              <div>
                                <small :style="stat.last_update_time_of_project_cases.val === d_dataset.created_at ? 'color: green;' : 'color: red;'"> - Analizde kullanılan veri seti tarihi: {{ DateFormatWithHourMin(stat.last_update_time_of_project_cases.val) }} </small>
                                <br>
                                <small :style="stat.last_update_time_of_project_cases.val === d_dataset.created_at ? 'color: green;' : 'color: red;'"> - Son veri seti güncelleme tarihi: {{ DateFormatWithHourMin(d_dataset.created_at) }} </small>
                              </div>
                            </template>
                           -->
                        </b-col>
                        <b-col cols="2">
                          <b-button v-if="stat.wdm72_key.val === d_selectedWdm72Key" variant="white" @click="f_showStatisticFunctions(stat.wdm72_key.val)" size="md">
                            <img src="@/icon/2504347.png" style="width: 2em;" />
                          </b-button>
                        </b-col>
                      </b-row>
                    </template>
                  </div>
                </template>
              </b-card>
            </b-col>
            <b-col cols="8">
              <b-navbar style="padding: 0px;">
                <b-nav pills>
                  <b-nav-item @click="d_navAnalyzeResult ='analyze_model'" :active="d_navAnalyzeResult ==='analyze_model'"> Analiz Modeli </b-nav-item>
                  <b-nav-item @click="d_navAnalyzeResult = 'results'" :active="d_navAnalyzeResult === 'results'"> Sonuçlar </b-nav-item>
                </b-nav>
              </b-navbar>
              <template v-if="d_navAnalyzeResult ==='analyze_model'">
                <b-row>
                  <b-col sm="12">
                    <b-card no-body header-tag="header">
                      <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
                        <small>Analiz Detayları [ID: {{ d_selectedWdm72Data.id }}]</small>
                      </b-card-header>
                      <template v-if="d_selectedWdm72Data && d_selectedWdm72Data.id">
                        <div :style="f_calculateAnalyzeModelDetailsStyle()">
                          <wisdom-data-show :change="change" :view_mode="d_selectedWdm72DataViewMode" :data="d_selectedWdm72Data" :data_type="'wdm72'" :option_data="option_data['wdm72']"></wisdom-data-show>
                        </div>
                      </template>
                    </b-card>
                  </b-col>
                </b-row>
              </template>
              <template v-else-if="d_navAnalyzeResult ==='results'">
                <b-card v-if="d_navAnalyzeResult ==='results'" no-body header-tag="header" :style="'margin-bottom: 0px; background-color: ' + d_polestarSettings.style.backgroundColor + '; color: ' + d_polestarSettings.style.color" :class="[d_fullScreenResults ?'full-screen-mode' :'normal-mode']">
                  <div :style="f_calculateAnalyzeResultsStyle()">
                    <polestar-result :d_selectedWdm72Key="d_selectedWdm72Key" :d_selectedWdm73Key="d_selectedWdm73Key" :d_selectedWdm73Data="d_selectedWdm73Data" :p_selectedWdm72Results="d_selectedWdm72Results" :d_dataset="d_dataset" :column_list="column_list" :option_data="option_data" :p_selectedProjectData="d_selectedProjectData" :p_showAnalyzeCharts="d_showAnalyzeCharts"></polestar-result>
                  </div>
                </b-card>
              </template>
            </b-col>
          </b-row>
          <b-row v-if="d_showMode ==='machine_learning'" key="machine_learning">
            <b-col cols="4">
              <b-navbar style="padding: 0px;">
                <b-nav pills>
                  <b-nav-item @click="d_navMachineLearningList ='machine_learning_list'" :active="d_navMachineLearningList ==='machine_learning_list'"> Makine Öğrenmesi Listesi </b-nav-item>
                </b-nav>
              </b-navbar>
              <b-card v-if="d_navMachineLearningList ==='machine_learning_list'" no-body header-tag="header" :style="'margin-bottom: 0px; background-color: ' + d_polestarSettings.style.backgroundColor + '; color: ' + d_polestarSettings.style.color">
                <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
                  Makine Öğrenmesi Modelleri
                  <b-dropdown variant="success" size="md" style="margin: 5px;" class="pull-right">
                    <b-dropdown-item @click="f_createNewMachineLearning()"> yeni makine öğr </b-dropdown-item>
                  </b-dropdown>
                </b-card-header>
                <template v-if="d_selectedProjectData.data.machine_learning && d_selectedProjectData.data.machine_learning.machine_learning">
                  <div :style="f_calculateAnalyzeModelListStyle()">
                    <template v-for="(ml, ml_ind) in d_selectedProjectData.data.machine_learning.machine_learning.list">
                      <b-row :style="d_selectedWdm163_key === ml.machine_learning_key.val ?'margin: 0px; cursor: pointer; border: 1px solid rgba(158, 193, 179, 0.3); background-color: rgba(158, 193, 179, 0.3); min-height: 50px;' :'margin: 0px; cursor: pointer; border: 1px solid rgba(158, 193, 179, 0.3); min-height: 50px;'">
                        <b-col cols="10" @click="f_selectWdm163(ml.machine_learning_key.val)">
                          {{ ml.machine_learning_name.val ? ml.machine_learning_name.val : 'makine öğrenmesi modeli' }}
                          <template v-if="ml.model_date && ml.model_date.val">
                            <div>
                              <small>Analiz Tarihi: {{ DateFormatWithHourMin(ml.model_date.val) }} </small>
                            </div>
                          </template>
                          <template v-if="ml.machine_learning_last_update_time_of_project_cases && ml.machine_learning_last_update_time_of_project_cases.val && d_dataset.created_at">
                            <div>
                              <small :style="ml.machine_learning_last_update_time_of_project_cases.val === d_dataset.created_at ? 'color: green;' : 'color: red;'"> - Analizde kullanılan veri seti tarihi: {{ DateFormatWithHourMin(ml.machine_learning_last_update_time_of_project_cases.val) }} </small>
                              <br>
                              <small :style="ml.machine_learning_last_update_time_of_project_cases.val === d_dataset.created_at ? 'color: green;' : 'color: red;'"> - Son veri seti güncelleme tarihi: {{ DateFormatWithHourMin(d_dataset.created_at) }} </small>
                            </div>
                          </template>
                        </b-col>
                        <b-col cols="2">
                          <b-dropdown v-if="ml.machine_learning_key.val === d_selectedWdm163_key" variant="success" size="md" style="margin: 5px;" class="pull-right">
                            <b-dropdown-item @click="f_editMachineLearning(ml.machine_learning_key.val)"> {{ $t('wdm16.3897') }} </b-dropdown-item>
                            <b-dropdown-item @click="f_deleteWdm163Data(ml.machine_learning_key.val)"> {{ $t('wdm16.4398') }} </b-dropdown-item>
                            <b-dropdown-item @click="f_trainMachineLearning()"> model eğitimi yap </b-dropdown-item>
                            <b-dropdown-item @click="f_caseFilter('wdm163')"> satır filtre algoritması </b-dropdown-item>
                            <b-dropdown-item v-if="d_selectedWdm163_data.rule_algoritm" @click="f_deleteCaseFilterAlgorithm('wdm163')"> satır filtre algoritmasını sil </b-dropdown-item>
                          </b-dropdown>
                        </b-col>
                      </b-row>
                    </template>
                  </div>
                </template>
              </b-card>
            </b-col>
            <b-col cols="8">
              <b-navbar style="padding: 0px;">
                <b-nav pills>
                  <b-nav-item @click="d_navMachineLearningDetails = 'ml_model_details'" :active="d_navMachineLearningDetails ==='ml_model_details'"> Model Detayları </b-nav-item>
                  <b-nav-item v-if="d_selectedWdm163_data.analyze_result && d_selectedWdm163_data.analyze_result.length > 0" @click="d_navMachineLearningDetails = 'results'" :active="d_navMachineLearningDetails ==='results'"> Model Sonuçları </b-nav-item>
                </b-nav>
              </b-navbar>
              <b-row v-if="d_navMachineLearningDetails ==='ml_model_details'">
                <b-col sm="12">
                  <b-card no-body header-tag="header">
                    <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
                      <small>Makine Öğrenmesi Detayları</small>
                    </b-card-header>
                    <template v-if="d_selectedWdm163_data && d_selectedWdm163_data.id">
                      <div :style="f_calculateAnalyzeModelDetailsStyle()">
                        <wisdom-data-show :change="change" :view_mode="'table'" :data="d_selectedWdm163_data" :data_type="'wdm163'" :option_data="option_data['wdm163']"></wisdom-data-show>
                      </div>
                    </template>
                  </b-card>
                </b-col>
              </b-row>
              <b-card v-if="d_navMachineLearningDetails ==='results'" no-body header-tag="header" style="margin-bottom: 0px;" :class="[d_fullScreenResults ?'full-screen-mode' :'normal-mode']">
                <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
                  <small> Sonuçlar </small>
                  <b-button class="pull-right" size="md" :variant="d_fullScreenResults ?'warning' :'secondary'" :style="d_fullScreenResults ?'margin-right: 5px; padding: 0px; border: solid 2px green;' :'margin-right: 5px; padding: 0px;'" @click="d_fullScreenResults ? d_fullScreenResults = false : d_fullScreenResults = true">
                    <img src="@/icon/629153.png" :title="$t('wdm16.6589')" style="width: 2em;" />
                  </b-button>
                </b-card-header>
                <div :style="f_calculateAnalyzeResultsStyle()">
                  <polestar-result :p_selectedWdm72Results="d_selectedWdm163_data.analyze_result" :d_dataset="d_dataset" :column_list="column_list" :option_data="option_data" :p_selectedProjectData="d_selectedProjectData" :p_showAnalyzeCharts="d_showAnalyzeCharts"></polestar-result>
                </div>
              </b-card>
            </b-col>
          </b-row>
          <template v-if="d_showMode ==='column_filter_show'">
            <b-row key="column_selection_wdm_rule_a">
              <b-col sm="12">
                <b-card no-body header-tag="header" style="margin-bottom: 0px;">
                  <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
                    <small>Kolon kural filtresi</small>
                    <b-button v-if="d_columnFilterShowModal" class="pull-right" variant="success" @click="f_saveColumnRuleFilter()"><i class="fa fa-save"></i> {{ $t('wdm16.4061') }}</b-button>
                    <b-button v-if="d_dataset.total_cases !== d_dataset.filtered_cases || (d_dataset.filter && d_dataset.filter[user.username])" class="pull-right" variant="danger" @click="f_deleteColumnFilter()"><i class="fa fa-trash"></i> Filtreyi Kaldır</b-button>
                    <!-- <b-dropdown class="pull-right" variant="secondary" size="sm">
                      <template v-slot:button-content>
                        <img src="@/icon/1115721.png" title="işlemler" style="width: 2em;" />
                      </template>
                      <b-dropdown-item @click="f_openColumnRuleFilterModal()"> <img src="@/icon/2960590.png" style="width: 2em;"> kolon filtreleme ekranı </b-dropdown-item>
                      <b-dropdown-item v-if="d_dataset.total_cases !== d_dataset.filtered_cases || (d_dataset.filter && d_dataset.filter[user.username])" @click="f_deleteColumnFilter()"> <img src="@/icon/2960590.png" style="width: 2em;"> kolon filtrelemelerini iptal et </b-dropdown-item>
                    </b-dropdown> -->
                  </b-card-header>
                </b-card>
                <template v-if="d_columnFilterShowModal">
                  <wdm-rule-a :data_type="d_columnRule.data_type" :option_data="d_columnRule.option_data" :prepare_data="d_columnRule.prepare_data" :query_list="d_columnRule.query_list" :compile_str_array="d_columnRule.compile_str_array" :compile_str_date="d_columnRule.compile_str_date" :wdmr_change="d_columnRule.wdmr_change" :p_wdmList="d_columnRule.wdm_list" :mother_children="mother_children" :p_wdmParameterSelectData="d_columnRule.wps_data" :p_wdmRuleAsettings="d_columnRule.wra_settings" :polestar="true" :compile_str_text="d_columnRule.compile_str_text"></wdm-rule-a>
                  <!-- <b-button class="pull-right" variant="success" @click="f_saveColumnRuleFilter()"><i class="fa fa-save"></i> {{ $t('wdm16.4061') }}</b-button> -->
                </template>
                <template v-if="d_dataset.filter && d_dataset.filter[user.username]">
                  <b-row style="margin-top: 10px;">
                    <b-col sm="12" lg="12">
                      <template v-if="d_dataset.filter[user.username].query_list && d_dataset.filter[user.username].query_list.length > 0">
                        <rule-query :query_list="d_dataset.filter[user.username].query_list" :compile_str="d_dataset.filter[user.username].compile_str_array" :compile_str_date="d_dataset.filter[user.username].compile_str_date" :mode="'view'" :compile_str_text="d_dataset.filter[user.username].compile_str_text"></rule-query>
                      </template>
                    </b-col>
                  </b-row>
                </template>
              </b-col>
            </b-row>
          </template>
          <b-row v-if="d_showMode ==='column_list_and_features'" key="column_list_and_features">
            <b-col sm="12">
              <b-card no-body header-tag="header" :style="'margin-bottom: 0px; background-color: ' + d_polestarSettings.style.backgroundColor + '; color: ' + d_polestarSettings.style.color">
                <b-card-header header-bg-variant="white" class="p-1" header-text-variant="dark">
                  <b-row style="">
                    <b-col cols="8">
                    </b-col>
                    <b-col cols="4">
                    </b-col>
                  </b-row>
                </b-card-header>
                <pole-star-column-feature :column_list="column_list" :option_data="option_data" :mother_children="mother_children" :p_selectedProjectData="d_selectedProjectData" :d_tableModeSettings="d_tableModeSettings" :p_polestarSettings="d_polestarSettings" :p_userPolestarPermissions="d_userPolestarPermissions" :f_controlAndCreateTimer="f_controlAndCreateTimer"></pole-star-column-feature>
              </b-card>
            </b-col>
          </b-row>
          <b-row v-if="d_showMode ==='project_details' && d_showProjectDetails" key="project_details">
            <b-col sm="12">
              <b-card no-body header-tag="header" style="margin-bottom: 0px;">
                <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
                  <small>proje detayları</small>
                </b-card-header>
                <wisdom-data-show :change="change" :view_mode="d_projectDetailsViewMode" :data="d_selectedProjectData" :data_type="'wdm3'" :option_data="option_data['wdm3']"></wisdom-data-show>
                <template v-if="d_selectedProjectData.layer_main_rules">
                  <template v-for="(layer_main_rule, layer_key) in d_selectedProjectData.layer_main_rules">
                    <b-card-header header-bg-variant="info" class="p-1" header-text-variant="dark">
                      <strong>ana algoritma [ {{ layer_key.split('_')[1] }} nolu katman ]</strong>
                    </b-card-header>
                    <b-row style="margin-top: 10px;">
                      <b-col sm="12" lg="12">
                        <template v-if="layer_main_rule.query_list && layer_main_rule.query_list.length > 0">
                          <rule-query :query_list="layer_main_rule.query_list" :compile_str="layer_main_rule.compile_str_array" :compile_str_date="layer_main_rule.compile_str_date" :mode="'view'" :compile_str_text="layer_main_rule.compile_str_text"></rule-query>
                        </template>
                      </b-col>
                    </b-row>
                  </template>
                </template>
              </b-card>
            </b-col>
          </b-row>
          <b-row v-if="d_showMode ==='article_list'" key="article_list">
            <b-col cols="4">
              <b-navbar style="padding: 0px;">
                <b-nav pills>
                  <b-nav-item @click="d_navArticleLeft ='nav_article_list'" :active="d_navArticleLeft ==='nav_article_list'">Makale Listesi</b-nav-item>
                </b-nav>
              </b-navbar>
              <b-card v-if="d_navArticleLeft ==='nav_article_list'" no-body header-tag="header" style="margin-bottom: 0px;">
                <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
                  Makale Listesi
                  <b-dropdown variant="success" size="sm" class="pull-right" style="margin: 5px;">
                    <b-dropdown-item @click="f_prepareWdm73()"> yeni makale </b-dropdown-item>
                  </b-dropdown>
                </b-card-header>
                <template v-if="d_selectedProjectData.data.articles && d_selectedProjectData.data.articles.article">
                  <div :style="f_calculateAnalyzeModelListStyle()">
                    <template v-for="(article, article_ind) in d_selectedProjectData.data.articles.article.list">
                      <b-row :style="d_selectedWdm73Key === article.wdm73_key.val ?'margin: 0px; cursor: pointer; border: 1px solid rgba(158, 193, 179, 0.3); background-color: rgba(158, 193, 179, 0.3); min-height: 50px;' :'margin: 0px; cursor: pointer; border: 1px solid rgba(158, 193, 179, 0.3); min-height: 50px;'">
                        <b-col cols="10" @click="f_selectWdm73(article.wdm73_key.val)">
                          {{ article.wdm73_name.val }}
                        </b-col>
                        <b-col cols="2">
                          <b-dropdown v-if="article.wdm73_key.val === d_selectedWdm73Key" variant="success" size="md" class="pull-right" style="margin: 5px;">
                            <b-dropdown-item @click="f_editWdm73Data(article.wdm73_key.val)"> düzenle </b-dropdown-item>
                            <b-dropdown-item @click="f_deleteWdm73Data(article.wdm73_key.val)"> makaleyi sil </b-dropdown-item>
                            <b-dropdown-item @click="f_printArticlePdf(article.wdm73_key.val, 'tr')"> makale pdfini yazdır (tr) </b-dropdown-item>
                            <b-dropdown-item @click="f_printArticlePdf(article.wdm73_key.val, 'en')"> makale pdfini yazdır (en) </b-dropdown-item>
                            <b-dropdown-item @click="f_saveWdm73Data()"> makaleyi güncellle </b-dropdown-item>
                            <b-dropdown-item @click="f_addColumnInfoTableToArticle()"> kolon bilgilerini tablo olarak makaleye aktar </b-dropdown-item>
                          </b-dropdown>
                        </b-col>
                      </b-row>
                    </template>
                  </div>
                </template>
              </b-card>
            </b-col>
            <b-col cols="8">
              <b-navbar style="padding: 0px;">
                <b-nav pills>
                  <b-nav-item @click="d_navArticleRight ='article_details'" :active="d_navArticleRight ==='article_details'"> Makale Detayları </b-nav-item>
                  <b-nav-item @click="d_navArticleRight ='article_edit'" :active="d_navArticleRight ==='article_edit'"> Makale Düzenle </b-nav-item>
                </b-nav>
              </b-navbar>
              <template v-if="d_navArticleRight ==='article_details'">
                <b-row>
                  <b-col sm="12">
                    <b-card no-body header-tag="header">
                      <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
                        <small>Makale Detayları</small>
                      </b-card-header>
                      <template v-if="d_selectedWdm73Data && d_selectedWdm73Data.id">
                        <div :style="f_calculateAnalyzeModelDetailsStyle()">
                          <wisdom-data-show :change="change" :view_mode="d_selectedWdm73DataViewMode" :data="d_selectedWdm73Data" :data_type="'wdm73'" :option_data="option_data['wdm73']"></wisdom-data-show>
                        </div>
                      </template>
                    </b-card>
                  </b-col>
                </b-row>
              </template>
              <template v-if="d_navArticleRight ==='article_edit'">
                <b-row>
                  <b-col sm="12">
                    <article-edit :p_selectedWdm73Data="d_selectedWdm73Data" :p_selectedProjectData="d_selectedProjectData" :mother_children="mother_children"></article-edit>
                  </b-col>
                </b-row>
              </template>
            </b-col>
          </b-row>
          <b-row v-if="d_showMode ==='dmp_list'" key="dmp_list">
            <b-col cols="4">
              <b-navbar style="padding: 0px;">
                <b-nav pills>
                  <b-nav-item @click="d_navDmpLeft ='nav_dmp_list'" :active="d_navDmpLeft ==='nav_dmp_list'">Karar Destek Paketleri Listesi</b-nav-item>
                </b-nav>
              </b-navbar>
              <b-card v-if="d_navDmpLeft ==='nav_dmp_list'" no-body header-tag="header" style="margin-bottom: 0px;">
                <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
                  Karar Destek Paketleri
                  <b-dropdown variant="success" size="sm" class="pull-right" style="margin: 5px;">
                    <b-dropdown-item @click="f_addNewChildWisdomData('wdm24')"> yeni karar destek paketi </b-dropdown-item>
                  </b-dropdown>
                </b-card-header>
                <template v-if="d_selectedProjectData.data.dmp && d_selectedProjectData.data.dmp.dmp_list">
                  <template v-for="(wdmr24, wdmr24_ind) in d_selectedProjectData.data.dmp.dmp_list.list">
                    <b-row :style="d_selectedWdmr24Id === wdmr24.dmp_id.val ? 'margin: 0px; cursor: pointer; border: 1px solid rgba(158, 193, 179, 0.3); background-color: rgba(158, 193, 179, 0.3); min-height: 50px;' : 'margin: 0px; cursor: pointer; border: 1px solid rgba(158, 193, 179, 0.3); min-height: 50px;'">
                      <b-col cols="10" @click="f_selectWdmr24(wdmr24.dmp_id.val)">
                        {{ wdmr24.dmp_name.val }} [{{ wdmr24.dmp_id.val }}]
                      </b-col>
                      <b-col cols="2">
                        <b-dropdown v-if="d_selectedWdmr24Id === wdmr24.dmp_id.val" variant="success" size="md" class="pull-right" style="margin: 5px;">
                          <b-dropdown-item @click="f_editChildWdmr('wdm24')"> düzenle </b-dropdown-item>
                        </b-dropdown>
                      </b-col>
                    </b-row>
                  </template>
                </template>
              </b-card>
            </b-col>
            <b-col cols="8">
              <b-navbar style="padding: 0px;">
                <b-nav pills>
                  <b-nav-item @click="d_navDmpRight ='dmp_details'" :active="d_navDmpRight ==='dmp_details'"> Detayları </b-nav-item>
                </b-nav>
              </b-navbar>
              <template v-if="d_navDmpRight ==='dmp_details'">
                <b-row>
                  <b-col sm="12">
                    <b-card no-body header-tag="header">
                      <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
                        <small>Karar Destek Paketi Detayları</small>
                      </b-card-header>
                      <template v-if="d_selectedWdmr24Data && d_selectedWdmr24Data.id">
                        <div :style="f_calculateAnalyzeModelDetailsStyle()">
                          <wisdom-data-show :change="d_wisdomData.change" :view_mode="d_wisdomData.view_mode" :data="d_selectedWdmr24Data" :data_type="'wdm224'" :option_data="d_wisdomData.option_data['wdm24']"></wisdom-data-show>
                        </div>
                      </template>
                    </b-card>
                  </b-col>
                </b-row>
              </template>
              <template v-if="d_navDmpRight ==='article_edit'">
                <b-row>
                  <b-col sm="12">
                    <article-edit :p_selectedWdm73Data="d_selectedWdm73Data" :p_selectedProjectData="d_selectedProjectData" :mother_children="mother_children"></article-edit>
                  </b-col>
                </b-row>
              </template>
            </b-col>
          </b-row>
          <b-row v-if="d_showMode ==='phrase_list'" key="phrase_list">
            <b-col cols="4">
              <b-navbar style="padding: 0px;">
                <b-nav pills>
                  <b-nav-item @click="d_navPhraseLeft ='nav_phrase_list'" :active="d_navPhraseLeft ==='nav_phrase_list'">İfade Listesi</b-nav-item>
                </b-nav>
              </b-navbar>
              <b-card v-if="d_navPhraseLeft ==='nav_phrase_list'" no-body header-tag="header" style="margin-bottom: 0px;">
                <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
                  İfade listesi
                  <b-dropdown variant="success" size="sm" class="pull-right" style="margin: 5px;">
                    <b-dropdown-item @click="f_addNewPhraseModal()"> Yeni ifade </b-dropdown-item>
                  </b-dropdown>
                </b-card-header>
                <template v-if="d_phraseData.list.length > 0">
                  <div :style="f_calculateAnalyzeModelListStyle()">
                    <template v-for="(phrase, phrase_ind) in d_phraseData.list">
                      <b-row :style="d_selectedWdm332Key === phrase.key ?'margin: 0px; cursor: pointer; border: 1px solid rgba(158, 193, 179, 0.3); background-color: rgba(158, 193, 179, 0.3); min-height: 50px;' :'margin: 0px; cursor: pointer; border: 1px solid rgba(158, 193, 179, 0.3); min-height: 50px;'">
                        <b-col cols="10" @click="f_selectWdm332(phrase.key)">
                          {{ phrase.label }}
                        </b-col>
                        <b-col cols="2">
                          <b-dropdown v-if="phrase.key === d_selectedWdm332Key" variant="success" size="md" class="pull-right" style="margin: 5px;">
                            <b-dropdown-item @click="f_editWdm332Data(phrase.key)"> düzenle </b-dropdown-item>
                            <b-dropdown-item @click="f_phraseOperation(phrase.key)"> ifade çalışması yap (güncelleyerek, eşleştirmeleri korur) </b-dropdown-item>
                            <b-dropdown-item @click="f_phraseOperation(phrase.key, 'delete_all')"> ifade çalışması yap (bulunan ifadeleri ve eşleştirmeleri sil) </b-dropdown-item>
                          </b-dropdown>
                        </b-col>
                      </b-row>
                    </template>
                  </div>
                </template>
              </b-card>
            </b-col>
            <b-col cols="8">
              <b-navbar style="padding: 0px;">
                <b-nav pills>
                  <b-nav-item @click="d_navPhraseRight = 'phrase_details'" :active="d_navPhraseRight ==='phrase_details'"> İfade Detayları </b-nav-item>
                </b-nav>
              </b-navbar>
              <template v-if="d_navPhraseRight ==='phrase_details'">
                <b-row>
                  <b-col sm="12">
                    <b-card no-body header-tag="header">
                      <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
                        <small>İfade Detayları</small>
                      </b-card-header>
                      <template v-if="d_selectedWdm332Data && d_selectedWdm332Data.id">
                        <div :style="f_calculateAnalyzeModelDetailsStyle()">
                          <wisdom-data-show :change="change" :view_mode="d_selectedWdm332DataViewMode" :data="d_selectedWdm332Data" :data_type="'wdm332'" :option_data="option_data['wdm332']"></wisdom-data-show>
                        </div>
                      </template>
                    </b-card>
                  </b-col>
                </b-row>
              </template>
            </b-col>
          </b-row>
          <b-row v-if="d_showMode ==='project_report'" key="project_report">
            <b-col cols="12">
              <div style="min-height: 300px;">
                <analyze-report-view v-if="d_projectReports.list.length > 0" :p_analyzeReports="d_projectReports.list"></analyze-report-view>
              </div>
            </b-col>
          </b-row>
          <b-row v-if="d_showMode ==='location_count_results'" key="location_count_results">
            <b-col sm="12" lg="6">
              <div style="min-height: 300px;">
                <b-card-header v-if="d_dataset.calculated_case_count !== undefined" header-bg-variant="primary" class="p-1" header-text-variant="dark">
                  <strong>Toplam Satır Adedi:</strong>
                </b-card-header>
                <b-row v-if="d_dataset.calculated_case_count !== undefined"> <b-col cols="12"> {{ d_dataset.calculated_case_count }} </b-col> </b-row>
                <hr>
                <template v-if="d_dataset.locations && d_dataset.locations.location_order && d_dataset.locations.location_order.length > 0">
                  <b-card-header header-bg-variant="primary" class="p-1" header-text-variant="dark">
                    <strong>lokasyonlar</strong>
                  </b-card-header>
                  <template v-for="(location_order, location_order_index) in d_dataset.locations.location_order">
                    <b-row>
                      <b-col cols="6">
                        <strong>{{ d_dataset.locations.location_results[location_order].info.label }}</strong>
                      </b-col>
                      <b-col cols="6">
                        {{ d_dataset.locations.location_results[location_order].total_count }}
                      </b-col>
                    </b-row>
                    <template v-for="(server_order, server_order_index) in d_dataset.locations.location_results[location_order].server_order">
                      <b-row>
                        <b-col cols="6">
                          <span style="margin-left: 20px;">
                            {{ d_dataset.locations.location_results[location_order].server_results[server_order].info.label }}
                          </span>
                        </b-col>
                        <b-col cols="6">
                          {{ d_dataset.locations.location_results[location_order].server_results[server_order].total_count }}
                        </b-col>
                      </b-row>
                    </template>
                  </template>
                </template>
              </div>
            </b-col>
          </b-row>
        </div>
        <b-row style="min-height: 50px;">
          <b-col cols="4" style="font-weight: bold; text-align: right;">
            <!-- Son veri güncelleme şekli hesaplanan satır adedi ise gösteriyoruz. Daha sonra tüm durumlarda gösterme gibi kurgular tamamlandıktan sonra yapılabilir. -->
            <small v-if="d_selectedProjectData.data && d_selectedProjectData.data.general.last_data_update_type && d_selectedProjectData.data.general.last_data_update_type.val && d_selectedProjectData.data.general.last_data_update_type.val.value && ['calculate_case_count'].indexOf(d_selectedProjectData.data.general.last_data_update_type.val.value) !== -1">Hesaplanan Satır Adedi:
              <i> {{ d_dataset.calculated_case_count }} </i>
            </small>
          </b-col>
          <b-col cols="6" style="font-weight: bold; text-align: right;">
            <small style="margin-left: 10px; margin-right: 10px;">Kolon: <i>{{ column_list.length }} , </i> </small>
            <small style="margin-left: 10px; margin-right: 10px;">Son Güncelleme: <i>{{ DateFormatWithHourMin(d_dataset.created_at) }} , </i> </small>
            <small style="margin-left: 10px; margin-right: 10px;" v-if="d_dataset.total_cases !== d_dataset.filtered_cases">
              <span style="color: blue;">Filtreli Toplam: <i>{{ d_dataset.filtered_cases }} , </i> </span>
            </small>
            <small style="margin-left: 10px; margin-right: 10px;" v-if="d_dataset.total_cases">Listelenen: <i>{{ d_datasetPagination.start + 1 }} - {{ d_datasetPagination.end + 1 }} </i> </small>
            <small style="margin-left: 10px; margin-right: 10px;">Toplam: <i>{{ d_dataset.total_cases }} </i> </small>
          </b-col>
          <b-col cols="2" style="font-weight: bold;">
            <b-pagination v-if="d_showMode ==='data_table'" size="sm" style="margin-bottom: 0px;" aria-controls="datasetpagination" v-model="d_datasetPagination.current" :total-rows="d_dataset.filtered_cases" :per-page="d_datasetPagination.perpage"></b-pagination>
          </b-col>
        </b-row>
      </b-card>
    </b-card>
    <template v-if="d_tableFullScreenData.show">
      <!-- ***************************** TABLE FULL SCREEN MODE ***********************************-->
      <b-card no-body header-tag="header" class="full-screen-mode">
        <b-card-header header-bg-variant="secondary" class="p-1" header-text-variant="dark">
          <b-row>
            <b-col cols="6">
              {{ d_tableFullScreenData.label }}
            </b-col>
            <b-col cols="6" style="text-align: right">
              <b-button size="md" variant="secondary" style="margin-right: 5px; padding: 0px;" @click="d_tableFullScreenData.show = false;">
                <img src="@/icon/1890926.png" title="kapat" style="width: 2em;" />
              </b-button>
            </b-col>
          </b-row>
        </b-card-header>
        <div style="overflow-x: auto; height: 100%;">
          <table style="margin: 10px;">
            <tbody>
              <template v-for="(r, r_index) in d_tableFullScreenData.table">
                <tr :style="r_index % 2 === 0 ?'border: solid 1px black; background-color: #f6fcf5; line-height: 30px;' :'border: solid 1px black; line-height: 30px'">
                  <template v-for="(c, c_index) in r">
                    <td :style="f_calculateTableTdStyle(r_index, c_index)">
                      <template v-if="c.toString().length > 50">
                        {{ c.toString().substring(0, 50) }} <i style="color: red; cursor: pointer;" @click="f_showTableCellFullText(c)" :title="c.toString()">......</i>
                      </template>
                      <template v-else>
                        {{ c }}
                      </template>
                    </td>
                  </template>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </b-card>
    </template>
    <modal v-if="d_showStatsSelect" @close="d_showStatsSelect = false" :width="'1000'">
      <h3 slot="header">Analiz Parametre Seçim Ekranı <small style="color: green;">[{{ d_selectedAnalyzeModel.analyze_model +' /' + d_selectedAnalyzeModel.type }}]</small></h3>
      <div slot="body">
        <wisdom-stats-select :column_list="column_list" :analyze_column_list="analyze_column_list" :analyze_model="d_selectedAnalyzeModel" :row_parameter_list="row_parameter_list" :column_parameter_list="column_parameter_list" :dataset="d_dataset"></wisdom-stats-select>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-secondary" @click="f_analyze()">Analiz Yap</button>
        <button type="button" class="btn btn-danger" @click="d_showStatsSelect = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_showGraphicView" @close="d_showGraphicView = false" :width="'1500'">
      <h3 slot="header">Grafik ekranı</h3>
      <div slot="body">
        X: {{ chart_data.labels.length }},
        <select v-model="d_chartSize.chart_type" style="height: 30px; width: 100px;margin-right: 3px;">
          <option value="line">çizgi</option>
          <option value="bar">bar</option>
          <option value="scatter">nokta</option>
        </select>
        <select v-if="d_chartSize.y_mode ==='standart'" v-model="d_chartSize.x_mode" style="height: 30px; width: 100px;margin-right: 3px;">
          <option value="standart">x standart</option>
          <option value="x_ascending">x artan</option>
          <option value="x_descending">x azalan</option>
        </select>
        <select v-if="d_chartSize.x_mode ==='standart'" v-model="d_chartSize.y_mode" style="height: 30px; width: 100px;margin-right: 3px;">
          <option value="standart">y standart</option>
          <option value="y_ascending">y artan</option>
          <option value="y_descending">y azalan</option>
        </select>
        <select v-model="d_chartSize.x_interval_type" style="height: 30px; width: 100px;margin-right: 3px;">
          <option value="all">'x' tüm değerler</option>
          <option value="once_every_x_value">'x' her ... değerde bir</option>
        </select>
        <input v-if="d_chartSize.x_interval_type ==='once_every_x_value'" type="number" v-model="d_chartSize.x_interval" min="1" style="height: 30px; width: 100px;margin-right: 3px;" placeholder="x kaç değerde bir görülecek">
        <!--         <div class="chart-wrapper" style="height: 100%;">
          <line-view :chart_data="chart_data" style="height:300px;" :height="300" />
        </div>
 -->
        <div v-if="d_showChartView" class="chart-wrapper" style="height: 100%;">
          <template v-if="d_chartSize.chart_type ==='line'">
            <line-view :chart_data="chart_data" :style="'height:' + d_chartSize.height.toString() +'px;'" :height="d_chartSize.height" :width="d_chartSize.width" />
          </template>
          <template v-if="d_chartSize.chart_type ==='bar'">
            <bar-view :chart_data="chart_data" :style="'height:' + d_chartSize.height.toString() +'px;'" :height="d_chartSize.height" :width="d_chartSize.width" />
          </template>
          <template v-if="d_chartSize.chart_type ==='scatter'">
            <scatter-view :chart_data="chart_data" :style="'height:' + d_chartSize.height.toString() +'px;'" :height="d_chartSize.height" :width="d_chartSize.width" />
          </template>
        </div>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_showGraphicView = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <!--     <modal v-if="d_showMessenger" @close="d_showMessenger = false" large="True">
      <h3 slot="header"> <img src="@/icon/1519119.png" style="width: 2em;" /> WisdomEra Messenger </h3>
      <div slot="body">
        <messenger :polestar="d_selectedProjectData"></messenger>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_showMessenger = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
 -->
    <b-modal id="d_showNewRowModal" v-model="d_showNewRowModal" title="Yeni Satır Oluşturma" centered header-bg-variant="info" header-text-variant="dark">
      <div>
        <b-row>
          <b-col cols="3">Yeni Satır Sayısı</b-col>
          <b-col cols="9">
            <b-form-input v-model="d_newRowData.row_count" min="0" max="10000" type="number"></b-form-input>
          </b-col>
        </b-row>
      </div>
      <template slot="modal-footer">
        <b-button @click="f_addNewRows(d_newRowData.row_count)" variant="success">Oluştur</b-button>
      </template>
    </b-modal>
    <b-modal id="d_showTableCellFullText" v-model="d_showTableCellFullText" scrollable hide-footer title="Hücre Tam Görünümü" centered header-bg-variant="info" header-text-variant="dark">
      <p class="my-4" style="word-wrap: break-word;">
        {{ d_selectedTableCellFullText }}
      </p>
    </b-modal>
    <modal v-if="d_showStatisticsModal" @close="d_showStatisticsModal = false" :width="'750'">
      <h3 slot="header">İstatistik Ekranı</h3>
      <div slot="body">
        <wisdom-data-modal ref="wisdom_data_modal" :data="d_statisticWisdomData" :data_type="'wdm72'" :option_data="option_data" :rule_engine="false" :query_list="[]" :p_selectedAnatomySchemaList="d_selectedAnatomySchemaList"></wisdom-data-modal>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" v-on:click="f_saveWdm72()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="d_showStatisticsModal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_machineLearningData.show" @close="d_machineLearningData.show = false" :width="'1000'">
      <h3 slot="header">Makine Öğrenmesi Ekranı</h3>
      <div slot="body">
        <wisdom-data-modal ref="wisdom_data_modal" :data="d_machineLearningData.wisdom_data" :data_type="'wdm163'" :option_data="option_data" :rule_engine="false" :query_list="[]"></wisdom-data-modal>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" v-on:click="f_saveWdm163()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="d_machineLearningData.show = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_showWdm73Modal" @close="d_showWdm73Modal = false" :width="'750'">
      <h3 slot="header">Makale Ekranı</h3>
      <div slot="body">
        <wisdom-data-modal ref="wisdom_data_modal" :data="d_wdm73WisdomData" :data_type="'wdm73'" :option_data="option_data" :rule_engine="false" :query_list="[]" :p_selectedAnatomySchemaList="d_selectedAnatomySchemaList"></wisdom-data-modal>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" v-on:click="f_saveWdm73()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="d_showWdm73Modal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_showRuleAlgorithm.show" @close="d_showRuleAlgorithm.show = false" :width="'1500'">
      <h3 slot="header">Analiz Vaka Filtresi</h3>
      <div slot="body">
        <b-row>
          <b-col sm="12">
            <wdm-rule-a :data_type="d_columnRule.data_type" :option_data="d_columnRule.option_data" :prepare_data="d_columnRule.prepare_data" :query_list="d_columnRule.query_list" :compile_str_array="d_columnRule.compile_str_array" :compile_str_date="d_columnRule.compile_str_date" :wdmr_change="d_columnRule.wdmr_change" :p_wdmList="d_columnRule.wdm_list" :mother_children="mother_children" :p_wdmParameterSelectData="d_columnRule.wps_data" :p_wdmRuleAsettings="d_columnRule.wra_settings" :compile_str_text="d_columnRule.compile_str_text"></wdm-rule-a>
          </b-col>
        </b-row>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" @click="f_saveCaseFilter()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="d_showRuleAlgorithm.show = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_showPatientWdmr" @close="d_showPatientWdmr = false" :width="'1200'">
      <div slot="header">
        Hasta Takip Bilgileri
        <img v-if="patient_data.sex === '0'" src="../../../static/img/avatars/man.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.3em; padding: 2px;" />
        <img v-else src="../../../static/img/avatars/woman.png" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.3em; padding: 2px;" />
        <span v-if="patient_data && patient_data.firstname">
          {{ w_presentation ? 'Hasta Ad Soyad' : patient_data.firstname.toLocaleUpperCase('tr') + ' ' + patient_data.lastname.toLocaleUpperCase('tr') }},
        </span>
      </div>
      <div slot="body">
        <iframe width="100%" height="500px" :src="d_iframePatientWdmr.url" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <!-- <patient-wdmr :p_isModal="true" :patient="patient_data" :wdmr_add_edit="wdmr_add_edit" :screen_mode_index="d_screenModeIndex"></patient-wdmr> -->
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_showPatientWdmr = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <b-modal id="d_articleList_show" v-model="d_articleList.show" scrollable title="Makale Seçimi" centered header-bg-variant="info" header-text-variant="dark" size="xl">
      <b-row>
        <b-col sm="12" md="12">
          <b-card no-body>
            <b-card-header header-bg-variant="primary" header-text-variant="white" class="p-1">
              Yazılar
            </b-card-header>
            <b-row style="margin: 0px;">
              <b-col cols="12">
                <b-button variant="secondary" size="sm" @click="f_selectionControlOfTextListForArticle()">tümü seçim işlemi</b-button>
              </b-col>
            </b-row>
            <b-row :style="d_articleList.wdm72_result.length + d_articleList.other.length > 3 ? 'overflow-x: auto; overflow-y: auto; height: 250px; margin: 0px;' : 'margin: 0px;'">
              <b-col cols="12">
                <template v-for="(wdm72_res, wdm72_res_ind) in d_articleList.wdm72_result">
                  <template v-for="(text, text_ind) in wdm72_res.text_list">
                    <b-row :key="'wdm72_res_text_' + wdm72_res_ind.toString()" style="margin: 0px;">
                      <b-col sm="1">
                        <b-form-checkbox v-model="text.selected" :value="1" :unchecked-value="0"></b-form-checkbox>
                      </b-col>
                      <b-col sm="11">
                        <b-textarea v-model="text.text" style="width: 100%;"></b-textarea>
                      </b-col>
                    </b-row>
                  </template>
                </template>
                <template v-for="(other, other_ind) in d_articleList.other">
                  <template v-for="(text, text_ind) in other.text_list">
                    <b-row :key="'other_text_' + other_ind.toString() + '_' + text_ind.toString()" style="margin: 0px;">
                      <b-col sm="1">
                        <b-form-checkbox v-model="text.selected" :value="1" :unchecked-value="0"></b-form-checkbox>
                      </b-col>
                      <b-col sm="11">
                        <b-textarea v-model="text.text" style="width: 100%;"></b-textarea>
                      </b-col>
                    </b-row>
                  </template>
                </template>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col sm="12" md="4">
          <b-card no-body>
            <b-card-header header-bg-variant="primary" header-text-variant="white" class="p-1">
              Makale Seçimi Yapınız
            </b-card-header>
            <b-row style="overflow-y: auto; height: 300px; margin: 0px;">
              <b-col sm="12">
                <b-form-checkbox-group stacked v-model="d_articleList.selected_articles">
                  <b-form-checkbox v-for="(article, article_ind) in d_articleList.list" :key="'d_articleList.list_' + article_ind" :value="article.wdm73_key.val">
                    {{ article.wdm73_name.val }}
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col sm="12" md="8">
          <b-card no-body>
            <b-card-header header-bg-variant="primary" header-text-variant="white" class="p-1">
              Makale Bölümü Seçiniz <small>seçilen tüm alanlara aynı bilgileri ekleyecektir.</small>
            </b-card-header>
            <b-row style="overflow-y: auto; height: 300px; margin: 0px;">
              <b-col sm="12">
                <wdm-parameters :data_type="d_wdmParameters.data_type" :search_in_first_parameter_list="d_wdmParameters.search_in_first_parameter_list" :option_data="d_wdmParameters.option_data" :selected_index_data="d_wdmParameters.selected_index_data" :show_parameter_features="d_wdmParameters.show_parameter_features" :d_isThisModelEditable="false" :param_change="d_wdmParameters.param_change" :selection="d_wdmParameters.selection"></wdm-parameters>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <template slot="modal-footer">
        <b-button @click="f_sendToArticle()">makaleye aktar</b-button>
      </template>
    </b-modal>
    <b-modal id="d_tableToArticle_show" v-model="d_tableToArticle.show" scrollable title="Makaleye Tablo Ekleme Ekranı" centered header-bg-variant="info" header-text-variant="dark" size="sm">
      <b-row>
        <b-col sm="12" md="12">
          <b-card no-body>
            <b-card-header header-bg-variant="primary" header-text-variant="white" class="p-1">
              Makale Seçimi Yapınız
            </b-card-header>
            <b-row style="overflow-y: auto; height: 300px; margin: 0px;">
              <b-col sm="12">
                <b-form-checkbox-group stacked v-model="d_tableToArticle.wdmr_key_list">
                  <b-form-checkbox v-for="(article, article_ind) in d_tableToArticle.article_list" :key="'d_tableToArticle.article_list_' + article_ind" :value="article.wdm73_key.val">
                    {{ article.wdm73_name.val }}
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <template slot="modal-footer">
        <b-button @click="f_addTableToWdmr()">makaleye aktar</b-button>
      </template>
    </b-modal>
    <b-modal id="d_tableToArticle_show" v-model="d_chartToArticleAsFigure.show" scrollable title="Makaleye Figür Ekleme Ekranı" centered header-bg-variant="info" header-text-variant="dark" size="sm">
      <b-row>
        <b-col sm="12" md="12">
          <b-card no-body>
            <b-card-header header-bg-variant="primary" header-text-variant="white" class="p-1">
              Makale Seçimi Yapınız
            </b-card-header>
            <b-row style="overflow-y: auto; height: 300px; margin: 0px;">
              <b-col sm="12">
                <b-form-checkbox-group stacked v-model="d_chartToArticleAsFigure.wdmr_key_list">
                  <b-form-checkbox v-for="(article, article_ind) in d_chartToArticleAsFigure.article_list" :key="'d_chartToArticleAsFigure.article_list_' + article_ind" :value="article.wdm73_key.val">
                    {{ article.wdm73_name.val }}
                  </b-form-checkbox>
                </b-form-checkbox-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <template slot="modal-footer">
        <b-button @click="f_addChartToWdmrAsFigure()">makaleye aktar</b-button>
      </template>
    </b-modal>
    <b-modal id="d_rowData_show" v-model="d_rowData.show" scrollable title="Satır İşlem Ekranı" centered header-bg-variant="info" header-text-variant="dark" size="md">
      <b-row>
        <b-col sm="12" md="2"> Satır No: {{ d_rowData.row_data.no }} </b-col>
        <b-col v-if="d_rowData.row_data && d_rowData.row_data.id" sm="12" md="10"> ID: {{ d_rowData.row_data.id }} </b-col>
      </b-row>
      <b-row v-if="d_rowData.row_data && d_rowData.row_data.firstname">
        <b-col sm="12" md="12">
          Ad: {{ d_rowData.row_data.firstname + ' ' + d_rowData.row_data.lastname }}
        </b-col>
      </b-row>
      <b-row v-if="d_rowData.row_data && d_rowData.row_data.id">
        <b-col sm="12" md="12">
          <b-button @click="f_getPatientData(d_rowData.row_data.id)">Hasta Takip Ekranı</b-button>
        </b-col>
      </b-row>
      <template slot="modal-footer"></template>
    </b-modal>
    <b-modal v-if="d_showColumnSelectionModal" id="d_showColumnSelectionModal_show" v-model="d_showColumnSelectionModal" scrollable title="Seçilen Kolonları Gösteri Modu" hide-footer centered header-bg-variant="info" header-text-variant="dark" size="xl">
      <pole-star-column-feature :column_list="column_list" :option_data="option_data" :p_selectedProjectData="d_selectedProjectData" :d_tableModeSettings="d_tableModeSettings" :column_index="d_columnData.col_index" :p_columnSelectionMode="true" :mother_children="mother_children" :p_polestarSettings="d_polestarSettings" :p_userPolestarPermissions="d_userPolestarPermissions" :f_controlAndCreateTimer="f_controlAndCreateTimer"></pole-star-column-feature>
      <template slot="modal-footer"></template>
    </b-modal>
    <modal v-if="d_fastAnalyzeModeShowModal" @close="d_fastAnalyzeModeShowModal = false" :width="'1000'">
      <h3 slot="header">Hızlı Analiz Modu</h3>
      <div slot="body">
        <polestar-fast-analyze :d_selectedWdm73Key="d_selectedWdm73Key" :d_selectedWdm73Data="d_selectedWdm73Data" :column_list="column_list" :option_data="option_data" :p_selectedProjectData="d_selectedProjectData" :mother_children="mother_children"></polestar-fast-analyze>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_fastAnalyzeModeShowModal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <b-modal v-if="d_combinePatientData.show" id="d_columnData_show" v-model="d_combinePatientData.show" scrollable centered header-bg-variant="info" header-text-variant="dark" size="md">
      <b-row>
        <b-col cols="3">
          Hastayı Bul
        </b-col>
        <b-col cols="9">
          <b-form-input v-model="d_combinePatientData.hims_patient_no" placeholder="Lütfen hastaya ait hastane nosunu yazınız"></b-form-input>
        </b-col>
      </b-row>
      <b-row key="patientname" v-if="d_combinePatientData.patient && d_combinePatientData.patient.firstname">
        <b-col cols="3">
          Hasta Adı
        </b-col>
        <b-col cols="9">
          {{ d_combinePatientData.patient.firstname + ' ' + d_combinePatientData.patient.lastname }}
        </b-col>
      </b-row>
      <b-row key="personel_number" v-if="d_combinePatientData.patient && d_combinePatientData.patient.firstname">
        <b-col cols="3">
          Tc/Passport
        </b-col>
        <b-col cols="9">
          {{ d_combinePatientData.patient.personel_number ? d_combinePatientData.patient.personel_number : d_combinePatientData.patient.passport_number ? d_combinePatientData.patient.passport_number : 'tc no ya da passport tanımlı değil' }}
        </b-col>
      </b-row>
      <template slot="modal-footer">
        <button type="button" class="btn btn-danger" @click="d_combinePatientData.show = false">{{ $t('wdm16.4053') }}</button>
        <button type="button" class="btn btn-secondary" @click="f_patientFindFromHospital()">bul</button>
        <button v-if="d_combinePatientData.patient && d_combinePatientData.patient.firstname" type="button" class="btn btn-secondary" @click="f_combineWithThisPatient()">bu hasta ile ilişkilendir</button>
      </template>
    </b-modal>
    <modal v-if="show_wisdom_data_modal" @close="show_wisdom_data_modal = false" :width="'750'">
      <h3 slot="header">Proje Düzenleme Ekranı</h3>
      <div slot="body">
        <wisdom-data-modal ref="wisdom_data_modal" :data="wisdom_data" :data_type="'wdm3'" :option_data="option_data" :rule_engine="false" :query_list="[]" :p_selectedAnatomySchemaList="d_selectedAnatomySchemaList"></wisdom-data-modal>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" v-on:click="f_saveWisdomData()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="show_wisdom_data_modal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_showHimsProtocols" @close="d_showHimsProtocols = false" :width="'1500'">
      <div slot="header">
        <strong>Hasta HBYS Protokolleri Hakkında</strong>
        <span v-if="patient_data && patient_data.firstname">
          {{ w_presentation ? 'Hasta Ad Soyad' : patient_data.firstname.toLocaleUpperCase('tr') + ' ' + patient_data.lastname.toLocaleUpperCase('tr') }},
        </span>
      </div>
      <div slot="body">
        <iframe width="100%" height="500px" :src="d_iframeHimsProtocolDetails.url" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <!-- <hims-protocol-details :protocolOpenOrClosedState="'closed'" :patient="patient_data" :p_ruleData="d_inspectionRuleData"></hims-protocol-details> -->
      </div>
      <div slot="footer">
        <b-button variant="success" v-on:click="f_saveHimsData()">{{ $t('wdm16.4061') }}</b-button>
        <button type="button" class="btn btn-danger" @click="d_showHimsProtocols = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_showWdm332Modal" @close="d_showWdm332Modal = false" :width="'750'">
      <h3 slot="header">İfade Ekranı</h3>
      <div slot="body">
        <wisdom-data-modal ref="wisdom_data_modal" change="change" :data="d_wdm332WisdomData" :data_type="'wdm332'" :option_data="option_data"></wisdom-data-modal>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" v-on:click="f_saveWdm332()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="d_showWdm332Modal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_phraseData.show" @close="d_phraseData.show = false" :width="'1000'">
      <h3 slot="header">İfade ekranı</h3>
      <div slot="body">
        <wisdom-data-modal ref="wisdom_data_modal" :data="d_phraseData.wisdom_data" :data_type="'wdm332'" :option_data="option_data" :rule_engine="false" :query_list="[]"></wisdom-data-modal>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" v-on:click="f_saveWdm332()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="d_phraseData.show = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_wisdomData.show" @close="d_wisdomData.show = false" :width="'750'">
      <h3 slot="header">{{ d_wisdomData.option_data[d_wisdomData.data_type].name.label }}</h3>
      <div slot="body">
        <wisdom-data-modal ref="wisdom_data_modal" :data="d_wisdomData.wisdom_data" :data_type="d_wisdomData.data_type" :option_data="d_wisdomData.option_data"></wisdom-data-modal>
      </div>
      <div slot="footer">
        <button v-if="d_wisdomData.data_type === 'wdm24'" type="button" class="btn btn-success" v-on:click="f_savePolestarDmpToWai()">{{ $t('wdm16.4061') }}</button>
        <button v-else type="button" class="btn btn-success" v-on:click="f_saveChildWdm()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="d_wisdomData.show = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_wisdomData.show_data" @close="d_wisdomData.show_data= false" :width="'750'">
      <h3 slot="header"> <span :style="'color:' + option_data[d_wisdomData.data_type].color ? option_data[d_wisdomData.data_type].color : 'black;'">{{ option_data[d_wisdomData.data_type].name.label }}</span> <small> ( {{ d_wisdomData.wisdom_data.label ? d_wisdomData.wisdom_data.label : 'başlık belirtilmemiş' }} ) </small> </h3>
      <div slot="body">
        <template v-if="d_wisdomData.wisdom_data && d_wisdomData.wisdom_data.id">
          <wisdom-data-show :change="d_wisdomData.change" :view_mode="d_wisdomData.view_mode" :data="d_wisdomData.wisdom_data" :data_type="d_wisdomData.data_type" :option_data="option_data[d_wisdomData.data_type]"></wisdom-data-show>
        </template>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-danger" @click="d_wisdomData.show_data= false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_smsOperationData.show" @close="d_smsOperationData.show = false" :width="'1000'">
      <h3 slot="header">Sms İşlemi</h3>
      <div slot="body">
        <user-sms :p_wdmr22="d_wdmr22" :p_selectedSmsProvider="d_smsOperationData.selected_sms_provider"></user-sms>
        <b-card no-body style="margin-bottom: 300px;">
          <b-card-header header-bg-variant="sedondary" header-text-variant="dark" class="p-1">
            <img src="@/icon/453632.png" style="width: 2em;"> Sms gönderim işlemleri
          </b-card-header>
          <b-row style="margin: 5px; min-height: 30px;">
            <b-col sm="12" lg="5">
              <strong>Seçilen Sms Sağlayıcı:</strong>
            </b-col>
            <b-col sm="12" lg="7">
              <span v-if="d_smsOperationData.selected_sms_provider.label">{{ d_smsOperationData.selected_sms_provider.label }}</span>
              <span v-else style="color: red;">Lütfen sms sağlayıcınızı seçiniz.</span>
            </b-col>
          </b-row>
          <b-row v-if="d_smsOperationData.list && d_smsOperationData.list.length > 1" style="margin: 5px; min-height: 30px;">
            <b-col sm="12" lg="5">
              <strong>liste</strong>
            </b-col>
            <b-col sm="12" lg="7">
            </b-col>
          </b-row>
          <template v-if="d_smsOperationData.list && d_smsOperationData.list.length > 0 && d_smsOperationData.selected !== ''">
            <b-row style="margin: 5px; min-height: 30px;">
              <b-col sm="12" lg="5">
                <strong>Sms Mesajı</strong>
              </b-col>
              <b-col sm="12" lg="7">
                <b-textarea v-model="d_smsOperationData.list[d_smsOperationData.selected].message"></b-textarea>
              </b-col>
            </b-row>
            <b-row style="margin: 5px; min-height: 30px;">
              <b-col sm="12" lg="5">
                <strong>Operasyon No</strong>
              </b-col>
              <b-col sm="12" lg="7">
                {{ d_smsOperationData.list[d_smsOperationData.selected].operation_no }}
                <!-- <b-form-input type="text" v-model="d_smsOperationData.list[d_smsOperationData.selected].operation_no"></b-form-input> -->
              </b-col>
            </b-row>
            <b-row style="margin: 5px; min-height: 30px;">
              <b-col sm="12" lg="5">
                <strong>İsim kullanımı</strong>
              </b-col>
              <b-col sm="12" lg="7">
                <b-form-select v-model="d_smsOperationData.list[d_smsOperationData.selected].name_usage">
                  <option value="none">isim kullanma</option>
                  <option value="name">Hem ilk adı hem de soyadı kullanarak (Sayın ....)</option>
                  <option value="firstname">İlk adı kullanarak (Sayın ....)</option>
                  <option value="lastname"> Soyadı kullanarak (Sayın ....)</option>
                </b-form-select>
              </b-col>
            </b-row>
            <b-row style="margin: 5px; min-height: 30px;">
              <b-col sm="12" lg="12">
                <strong>Sms telefon listesi</strong>
              </b-col>
            </b-row>
            <b-row style="margin: 5px; min-height: 30px;">
              <b-col sm="12" lg="12">
                <div style="overflow-y: auto; max-height: 325px; overflow-x: hidden;">
                  <b-table :filter="d_smsOperationData.list[d_smsOperationData.selected].filter_person" ref="d_smsOperationData" bordered borderless small hover :items="d_smsOperationData.list[d_smsOperationData.selected].sms_phone_list" selectable select-mode="multi" selectedVariant="success">
                    <template v-slot:cell(first_name)="row">
                      {{ row.index + 1 }} - {{ row.item.first_name + ' ' + row.item.last_name }}
                    </template>
                  </b-table>
                </div>
              </b-col>
            </b-row>
          </template>
        </b-card>
      </div>
      <div slot="footer">
        <template v-if="d_smsOperationData.list.length === 1">
          <button type="button" class="btn btn-success" v-on:click="f_sendRowSmsList('selected')" style="margin-right: 5px;">Sms gönderme işlemini gerçekleştir</button>
        </template>
        <template v-else>
          <button type="button" class="btn btn-success" v-on:click="f_sendRowSmsList('selected')" style="margin-right: 5px;">seçili sms gönderme işlemini gerçekleştir</button>
          <button type="button" class="btn btn-success" v-on:click="f_sendRowSmsList('all')" style="margin-right: 5px;">tüm sms gönderme işlemlerini gerçekleştir</button>
        </template>
        <button type="button" class="btn btn-danger" @click="d_smsOperationData.show = false" style="margin-right: 5px;">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <modal v-if="d_mainRuleShowModal" @close="d_mainRuleShowModal = false" :width="'1000'">
      <h3 slot="header"></h3>
      <div slot="body">
        <wdm-rule-a :data_type="d_mainRule.data_type" :option_data="d_mainRule.option_data" :prepare_data="d_mainRule.prepare_data" :query_list="d_mainRule.query_list" :compile_str_array="d_mainRule.compile_str_array" :compile_str_date="d_mainRule.compile_str_date" :wdmr_change="d_mainRule.wdmr_change" :p_wdmList="d_mainRule.wdm_list" :mother_children="mother_children" :p_wdmParameterSelectData="d_mainRule.wps_data" :p_wdmRuleAsettings="d_mainRule.wra_settings" :compile_str_text="d_mainRule.compile_str_text"></wdm-rule-a>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" @click="f_saveMainRule()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="d_mainRuleShowModal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal>
    <b-modal id="activicyData" v-model="d_activityData.show" size="lg" title="Aktif İşlemler Hakkında" centered header-bg-variant="info" header-text-variant="dark">
      <div>
        <b-row style="margin: 0px; border-bottom: solid 1px lightskyblue;">
          <b-col cols="12">
            <img src="@/icon/sandwatch.gif" style="width: 2em;" /> {{ f_calculateContinuingTitle() }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; padding: 3px; border-bottom: solid 1px lightskyblue;" v-if="d_selectedProjectData && d_selectedProjectData.data && d_selectedProjectData.data.general && d_selectedProjectData.data.general.process_application && d_selectedProjectData.data.general.process_application.val">
          <b-col cols="5">
            <img src="@/icon/2080590.png" style="width: 1.5em;" />
            <strong>İşlem Hangi Uygulamada</strong>
          </b-col>
          <b-col cols="7">
            {{ d_selectedProjectData.data.general.process_application.val }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; padding: 3px; border-bottom: solid 1px lightskyblue;" v-if="d_selectedProjectData && d_selectedProjectData.data && d_selectedProjectData.data.general && d_selectedProjectData.data.general.process_ip && d_selectedProjectData.data.general.process_ip.val">
          <b-col cols="5">
            <img src="@/icon/2080590.png" style="width: 1.5em;" />
            <strong>İşlem Uygulama ip</strong>
          </b-col>
          <b-col cols="7">
            {{ d_selectedProjectData.data.general.process_ip.val }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; padding: 3px; border-bottom: solid 1px lightskyblue;" v-if="d_selectedProjectData && d_selectedProjectData.data && d_selectedProjectData.data.general && d_selectedProjectData.data.general.positive_algorithm_count && d_selectedProjectData.data.general.positive_algorithm_count.val">
          <b-col cols="5">
            <img src="@/icon/2080590.png" style="width: 1.5em;" />
            <strong>Aktif Analiz Pozitif Algoritma Adedi</strong>
          </b-col>
          <b-col cols="7">
            {{ d_selectedProjectData.data.general.positive_algorithm_count.val }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; padding: 3px; border-bottom: solid 1px lightskyblue;" v-if="d_selectedProjectData && d_selectedProjectData.data && d_selectedProjectData.data.general && d_selectedProjectData.data.general.process_start_date && d_selectedProjectData.data.general.process_start_date.val">
          <b-col cols="5">
            <img src="@/icon/2080590.png" style="width: 1.5em;" />
            <strong>İşlem Başlama Tarihi</strong>
          </b-col>
          <b-col cols="7">
            {{ DateFormatWithHourMin(d_selectedProjectData.data.general.process_start_date.val) }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; padding: 3px; border-bottom: solid 1px lightskyblue;" v-if="d_selectedProjectData && d_selectedProjectData.data && d_selectedProjectData.data.general && d_selectedProjectData.data.general.process_total_minutes && d_selectedProjectData.data.general.process_total_minutes.val">
          <b-col cols="5">
            <img src="@/icon/2080590.png" style="width: 1.5em;" />
            <strong>İşlem Toplam Süre (dakika)</strong>
          </b-col>
          <b-col cols="7">
            {{ d_selectedProjectData.data.general.process_total_minutes.val }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; padding: 3px; border-bottom: solid 1px lightskyblue;" v-if="d_selectedProjectData && d_selectedProjectData.data && d_selectedProjectData.data.general && d_selectedProjectData.data.general.process_total_seconds && d_selectedProjectData.data.general.process_total_seconds.val">
          <b-col cols="5">
            <img src="@/icon/2080590.png" style="width: 1.5em;" />
            <strong>İşlem Toplam Süre (saniye)</strong>
          </b-col>
          <b-col cols="7">
            {{ d_selectedProjectData.data.general.process_total_seconds.val }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; padding: 3px; border-bottom: solid 1px lightskyblue;" v-if="d_selectedProjectData && d_selectedProjectData.data && d_selectedProjectData.data.general && d_selectedProjectData.data.general.last_info_date && d_selectedProjectData.data.general.last_info_date.val">
          <b-col cols="5">
            <img src="@/icon/2080590.png" style="width: 1.5em;" />
            <strong> Son Bilgilendirme Tarihi </strong>
          </b-col>
          <b-col cols="7">
            {{ DateFormatWithHourMin(d_selectedProjectData.data.general.last_info_date.val) }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; padding: 3px; border-bottom: solid 1px lightskyblue;" v-if="d_selectedProjectData && d_selectedProjectData.data && d_selectedProjectData.data.general && d_selectedProjectData.data.general.active_process_dataset_key && d_selectedProjectData.data.general.active_process_dataset_key.val">
          <b-col cols="5">
            <img src="@/icon/2556829.png" style="width: 1.5em;" />
            <strong>Aktif İşlem Dataset Key</strong>
          </b-col>
          <b-col cols="7">
            {{ d_selectedProjectData.data.general.active_process_dataset_key.val }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; padding: 3px; border-bottom: solid 1px lightskyblue;" v-if="d_selectedProjectData && d_selectedProjectData.data && d_selectedProjectData.data.general && d_selectedProjectData.data.general.active_process_dataset_total_count && d_selectedProjectData.data.general.active_process_dataset_total_count.val">
          <b-col cols="5">
            <img src="@/icon/2556829.png" style="width: 1.5em;" />
            <strong>Toplam Dataset Adedi</strong>
          </b-col>
          <b-col cols="7">
            {{ d_selectedProjectData.data.general.active_process_dataset_total_count.val }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; padding: 3px; border-bottom: solid 1px lightskyblue;" v-if="d_selectedProjectData && d_selectedProjectData.data && d_selectedProjectData.data.general && d_selectedProjectData.data.general.active_process_dataset_completed_count && d_selectedProjectData.data.general.active_process_dataset_completed_count.val">
          <b-col cols="5">
            <img src="@/icon/2556829.png" style="width: 1.5em;" />
            <strong>Tamamlanan Dataset Adedi</strong>
          </b-col>
          <b-col cols="7">
            {{ d_selectedProjectData.data.general.active_process_dataset_completed_count.val }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; padding: 3px; border-bottom: solid 1px lightskyblue;" v-if="d_selectedProjectData && d_selectedProjectData.data && d_selectedProjectData.data.general && d_selectedProjectData.data.general.total_added_case_count && d_selectedProjectData.data.general.total_added_case_count.val">
          <b-col cols="5">
            <img src="@/icon/2556829.png" style="width: 1.5em;" />
            <strong>Son Veri Ekleme İşlemi Toplam Adedi</strong>
          </b-col>
          <b-col cols="7">
            {{ d_selectedProjectData.data.general.total_added_case_count.val }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; padding: 3px; border-bottom: solid 1px lightskyblue;" v-if="d_selectedProjectData && d_selectedProjectData.data && d_selectedProjectData.data.general && d_selectedProjectData.data.general.layer_offset && d_selectedProjectData.data.general.layer_offset.val">
          <b-col cols="5">
            <img src="@/icon/2556829.png" style="width: 1.5em;" />
            <strong>Katman Kalınan Satır No</strong>
          </b-col>
          <b-col cols="7">
            {{ d_selectedProjectData.data.general.layer_offset.val }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; padding: 3px; border-bottom: solid 1px lightskyblue;" v-if="d_selectedProjectData && d_selectedProjectData.data && d_selectedProjectData.data.general && d_selectedProjectData.data.general.layer_wdmr_count && d_selectedProjectData.data.general.layer_wdmr_count.val">
          <b-col cols="5">
            <img src="@/icon/2556829.png" style="width: 1.5em;" />
            <strong>Katman Toplam Veri Modeli Adedi</strong>
          </b-col>
          <b-col cols="7">
            {{ d_selectedProjectData.data.general.layer_wdmr_count.val }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; padding: 3px; border-bottom: solid 1px lightskyblue;" v-if="d_selectedProjectData && d_selectedProjectData.data && d_selectedProjectData.data.general && d_selectedProjectData.data.general.last_data_update_type && d_selectedProjectData.data.general.last_data_update_type.val && d_selectedProjectData.data.general.last_data_update_type.val.label">
          <b-col cols="5">
            <img src="@/icon/2976082.png" style="width: 1.5em;" />
            <strong> Veri Güncelleme Tipi</strong>
          </b-col>
          <b-col cols="7">
            {{ d_selectedProjectData.data.general.last_data_update_type.val.label }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; padding: 3px; border-bottom: solid 1px lightskyblue;" v-if="d_selectedProjectData && d_selectedProjectData.data && d_selectedProjectData.data.general && d_selectedProjectData.data.general.total_root_layer_point && d_selectedProjectData.data.general.total_root_layer_point.val">
          <b-col cols="5">
            <img src="@/icon/2475229.png" style="width: 1.5em;" />
            <strong>Toplam Kök Katman Noktası</strong>
          </b-col>
          <b-col cols="7">
            {{ d_selectedProjectData.data.general.total_root_layer_point.val }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; padding: 3px; border-bottom: solid 1px lightskyblue;" v-if="d_selectedProjectData && d_selectedProjectData.data && d_selectedProjectData.data.general && d_selectedProjectData.data.general.last_root_layer_point && d_selectedProjectData.data.general.last_root_layer_point.val">
          <b-col cols="5">
            <img src="@/icon/2475229.png" style="width: 1.5em;" />
            <strong>Son Kök Katman Noktası</strong>
          </b-col>
          <b-col cols="7">
            {{ d_selectedProjectData.data.general.last_root_layer_point.val }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; padding: 3px; border-bottom: solid 1px lightskyblue;" v-if="d_selectedProjectData && d_selectedProjectData.data && d_selectedProjectData.data.general && d_selectedProjectData.data.general.last_case_point && d_selectedProjectData.data.general.last_case_point.val">
          <b-col cols="5">
            <img src="@/icon/2475229.png" style="width: 1.5em;" />
            <strong>Son Vaka Katman Noktası</strong>
          </b-col>
          <b-col cols="7">
            {{ d_selectedProjectData.data.general.last_case_point.val }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; padding: 3px; border-bottom: solid 1px lightskyblue;" v-if="d_selectedProjectData && d_selectedProjectData.data && d_selectedProjectData.data.general && d_selectedProjectData.data.general.last_wmanager_operation_total_seconds && d_selectedProjectData.data.general.last_wmanager_operation_total_seconds.val">
          <b-col cols="5">
            <img src="@/icon/2475229.png" style="width: 1.5em;" />
            <strong>Son Wmanager İşlemi Toplam Süresi</strong>
          </b-col>
          <b-col cols="7">
            {{ d_selectedProjectData.data.general.last_wmanager_operation_total_seconds.val }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; padding: 3px; border-bottom: solid 1px lightskyblue;" v-if="d_selectedProjectData && d_selectedProjectData.data && d_selectedProjectData.data.general && d_selectedProjectData.data.general.last_wmanager_case_list_count && d_selectedProjectData.data.general.last_wmanager_case_list_count.val">
          <b-col cols="5">
            <img src="@/icon/2475229.png" style="width: 1.5em;" />
            <strong>Son Wmanager Satır Adedi</strong>
          </b-col>
          <b-col cols="7">
            {{ d_selectedProjectData.data.general.last_wmanager_case_list_count.val }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; padding: 3px; border-bottom: solid 1px lightskyblue;" v-if="d_selectedProjectData && d_selectedProjectData.data && d_selectedProjectData.data.general && d_selectedProjectData.data.general.last_wmanager_status_code && d_selectedProjectData.data.general.last_wmanager_status_code.val">
          <b-col cols="5">
            <img src="@/icon/2475229.png" style="width: 1.5em;" />
            <strong>Son Wmanager Durum Kodu</strong>
          </b-col>
          <b-col cols="7">
            {{ d_selectedProjectData.data.general.last_wmanager_status_code.val }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; padding: 3px; border-bottom: solid 1px lightskyblue;" v-if="d_selectedProjectData && d_selectedProjectData.data && d_selectedProjectData.data.general && d_selectedProjectData.data.general.last_wmanager_status_message && d_selectedProjectData.data.general.last_wmanager_status_message.val">
          <b-col cols="5">
            <img src="@/icon/2475229.png" style="width: 1.5em;" />
            <strong>Son Wmanager Durum Mesajı</strong>
          </b-col>
          <b-col cols="7">
            {{ d_selectedProjectData.data.general.last_wmanager_status_message.val }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; padding: 3px; border-bottom: solid 1px lightskyblue;" v-if="d_selectedProjectData && d_selectedProjectData.data && d_selectedProjectData.data.general && d_selectedProjectData.data.general.last_data_update_total_time && d_selectedProjectData.data.general.last_data_update_total_time.val">
          <b-col cols="5">
            <img src="@/icon/2475229.png" style="width: 1.5em;" />
            <strong>Son Veri Güncelleme İşlemi Toplam Süre (saniye)</strong>
          </b-col>
          <b-col cols="7">
            {{ d_selectedProjectData.data.general.last_data_update_total_time.val }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; padding: 3px; border-bottom: solid 1px lightskyblue;" v-if="d_selectedProjectData && d_selectedProjectData.data && d_selectedProjectData.data.general && d_selectedProjectData.data.general.last_completed_add_new_cases_date && d_selectedProjectData.data.general.last_completed_add_new_cases_date.val">
          <b-col cols="5">
            <img src="@/icon/2475229.png" style="width: 1.5em;" />
            <strong>Son Yeni Veri Ekleme İşlemi Tarihi</strong>
          </b-col>
          <b-col cols="7">
            {{ DateFormatWithHourMin(d_selectedProjectData.data.general.last_completed_add_new_cases_date.val) }}
          </b-col>
        </b-row>
        <b-row style="margin: 0px; padding: 3px; border-bottom: solid 1px lightskyblue;" v-if="d_selectedProjectData && d_selectedProjectData.data && d_selectedProjectData.data.general && d_selectedProjectData.data.general.last_wmanager_sql_text && d_selectedProjectData.data.general.last_wmanager_sql_text.val">
          <b-col cols="5">
            <img src="@/icon/2475229.png" style="width: 1.5em;" />
            <strong>Son Wmanager Durum Mesajı</strong>
          </b-col>
          <b-col cols="7" :title="d_selectedProjectData.data.general.last_wmanager_sql_text.val">
            {{ d_selectedProjectData.data.general.last_wmanager_sql_text.val.substring(0, 150) + '...' }}
          </b-col>
        </b-row>
      </div>
      <template slot="modal-footer">
      </template>
    </b-modal>
    <b-modal v-if="d_statisticFunctions.show" title="İstatistik fonksiyonları" centered class="modal-success" v-model="d_statisticFunctions.show" @ok="d_statisticFunctions.show = false" ok-variant="success" hide-footer size="lg">
      <b-row>
        <b-col cols="12">
          <b-card style="margin-bottom: 5px;" no-body>
            <b-button variant="secondary" @click="d_statisticFunctions.show = false; f_editStatisticalAnalyze(d_statisticFunctions.wdm72_key)"> düzenle </b-button>
          </b-card>
          <b-card style="margin-bottom: 5px;" no-body>
            <b-button variant="secondary" @click="d_statisticFunctions.show = false; f_createNewStatsByCopying(d_statisticFunctions.wdm72_key)"> istatistiği kopyalaya </b-button>
          </b-card>
          <b-card style="margin-bottom: 5px;" no-body>
            <b-button variant="secondary" @click="d_statisticFunctions.show = false; f_analyzeStatisticalModel(d_statisticFunctions.wdm72_key)"> analiz yap </b-button>
          </b-card>
          <b-card style="margin-bottom: 5px;" no-body>
            <b-button variant="secondary" @click="d_statisticFunctions.show = false; f_deleteWdm72Data(d_statisticFunctions.wdm72_key)"> analizi sil </b-button>
          </b-card>
          <b-card style="margin-bottom: 5px;" no-body>
            <b-button variant="secondary" @click="d_statisticFunctions.show = false; f_caseFilter('wdm72')"> satır filtre algoritması </b-button>
          </b-card>
          <b-card style="margin-bottom: 5px;" no-body>
            <b-button variant="secondary" @click="d_statisticFunctions.show = false; f_createPDFofWdm72Results(d_statisticFunctions.wdm72_key, 'all', ['tr'])">PDF çıktısı oluştur (tr)</b-button>
          </b-card>
          <b-card style="margin-bottom: 5px;" no-body>
            <b-button variant="secondary" @click="d_statisticFunctions.show = false; f_createPDFofWdm72Results(d_statisticFunctions.wdm72_key, 'all', ['en'])">PDF çıktısı oluştur (en)</b-button>
          </b-card>
          <b-card style="margin-bottom: 5px;" no-body>
            <b-button variant="secondary" @click="d_statisticFunctions.show = false; f_createPDFofWdm72Results(d_statisticFunctions.wdm72_key, 'all', ['en', 'tr'])">PDF çıktısı oluştur (en, tr)</b-button>
          </b-card>
          <b-card style="margin-bottom: 5px;" no-body>
            <b-button variant="secondary" @click="d_statisticFunctions.show = false; f_goToProjectResultsPage(d_statisticFunctions.wdm72_key)"> sonuçlar sayfasına git </b-button>
          </b-card>
          <b-card style="margin-bottom: 5px;" no-body>
            <b-button variant="secondary" @click="d_statisticFunctions.show = false; f_createAllTextOfWdm72Results(d_statisticFunctions.wdm72_key, 'analyze_methods')"> makale yazısı (Analiz metodları) </b-button>
          </b-card>
          <template v-if="d_selectedWdm72Data && d_selectedWdm72Data.rule_algoritm && d_selectedWdm72Data.rule_algoritm.query_list.length > 0">
            <b-card style="margin-bottom: 5px;" no-body>
              <b-button variant="secondary" @click="d_statisticFunctions.show = false; f_createAllTextOfWdm72Results(d_statisticFunctions.wdm72_key, 'rule_algoritm')"> makale yazısı (Filtre Algoritması) </b-button>
            </b-card>
            <b-card style="margin-bottom: 5px;" no-body>
              <b-button variant="secondary" @click="d_statisticFunctions.show = false; f_createAllTextOfWdm72Results(d_statisticFunctions.wdm72_key, 'analyze_methods_and_rule_algoritm')"> makale yazısı (Metodlar + Filtre algoritması) </b-button>
            </b-card>
            <b-card style="margin-bottom: 5px;" no-body>
              <b-button variant="secondary" @click="d_statisticFunctions.show = false; f_createAllTextOfWdm72Results(d_statisticFunctions.wdm72_key, 'all')"> makale yazısı (Metodlar + Filtre algoritması + Sonuçlar) </b-button>
            </b-card>
          </template>
          <b-card style="margin-bottom: 5px;" no-body>
            <b-button variant="secondary" @click="d_statisticFunctions.show = false; f_createAllTextOfWdm72Results(d_statisticFunctions.wdm72_key, 'analyze_methods_and_results')"> makale yazısı (Metodlar + Sonuçlar) </b-button>
          </b-card>
          <b-card style="margin-bottom: 5px;" no-body>
            <b-button variant="secondary" @click="d_statisticFunctions.show = false; f_createAllTextOfWdm72Results(d_statisticFunctions.wdm72_key, 'results')"> makale yazısı (Analiz sonuçları) </b-button>
          </b-card>
          <b-card style="margin-bottom: 5px;" no-body>
            <b-button variant="secondary" v-if="d_selectedWdm72Data.rule_algoritm" @click="d_statisticFunctions.show = false; f_deleteCaseFilterAlgorithm('wdm72')"> satır filtre algoritmasını sil </b-button>
          </b-card>
          <b-card style="margin-bottom: 5px;" no-body>
            <b-button variant="secondary" @click="d_statisticFunctions.show = false; f_articleReportByStatResults('1', ['tr'], [d_statisticFunctions.wdm72_key])"> Analiz Raporu Oluştur (Mod 1) [tr] (Excel içerisinde p küçük 0.05 sonuçlar ve tanımlayıcı istatistikler) </b-button>
          </b-card>
          <b-card style="margin-bottom: 5px;" no-body>
            <b-button variant="secondary" @click="d_statisticFunctions.show = false; f_articleReportByStatResults('1', ['en'], [d_statisticFunctions.wdm72_key])"> Analiz Raporu Oluştur (Mod 1) [en] (Excel içerisinde p küçük 0.05 sonuçlar ve tanımlayıcı istatistikler) </b-button>
          </b-card>
          <b-card style="margin-bottom: 5px;" no-body>
            <b-button variant="secondary" @click="d_statisticFunctions.show = false; f_articleReportByStatResults('1', ['tr', 'en'], [d_statisticFunctions.wdm72_key])"> Analiz Raporu Oluştur (Mod 1) [tr, en] (Excel içerisinde p küçük 0.05 sonuçlar ve tanımlayıcı istatistikler) </b-button>
          </b-card>
          <b-card style="margin-bottom: 5px;" no-body>
            <b-button variant="secondary" @click="d_statisticFunctions.show = false; f_articleReportByStatResults('2', ['tr'], [d_statisticFunctions.wdm72_key])"> Analiz Raporu Oluştur (Mod 2, tüm veriler) [tr] (Excel içerisinde tüm sonuçlar ve tanımlayıcı istatistikler) </b-button>
          </b-card>
          <b-card style="margin-bottom: 5px;" no-body>
            <b-button variant="secondary" @click="d_statisticFunctions.show = false; f_articleReportByStatResults('2', ['en'], [d_statisticFunctions.wdm72_key])"> Analiz Raporu Oluştur (Mod 2, tüm veriler) [en] (Excel içerisinde tüm sonuçlar ve tanımlayıcı istatistikler) </b-button>
          </b-card>
          <b-card style="margin-bottom: 5px;" no-body>
            <b-button variant="secondary" @click="d_statisticFunctions.show = false; f_articleReportByStatResults('2', ['tr', 'en'], [d_statisticFunctions.wdm72_key])"> Analiz Raporu Oluştur (Mod 2, tüm veriler) [tr, en] (Excel içerisinde tüm sonuçlar ve tanımlayıcı istatistikler) </b-button>
          </b-card>
          <b-card style="margin-bottom: 5px;" no-body>
            <b-button variant="secondary" @click="f_statsReportAndModuleChartsUpdate(d_statisticFunctions.wdm72_key)"> Makale ve Rapor Modülü Güncellemesini Manuel Tetikle </b-button>
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
    <!-- <modal v-if="d_columnFilterShowModal" @close="d_columnFilterShowModal = false" :width="'750'">
      <h3 slot="header"></h3>
      <div slot="body">
        <wdm-rule-a :data_type="d_columnRule.data_type" :option_data="d_columnRule.option_data" :prepare_data="d_columnRule.prepare_data" :query_list="d_columnRule.query_list" :compile_str_array="d_columnRule.compile_str_array" :compile_str_date="d_columnRule.compile_str_date" :wdmr_change="d_columnRule.wdmr_change" :p_wdmList="d_columnRule.wdm_list" :mother_children="mother_children" :p_wdmParameterSelectData="d_columnRule.wps_data" :p_wdmRuleAsettings="d_columnRule.wra_settings" :polestar="true" :compile_str_text="d_columnRule.compile_str_text"></wdm-rule-a>
      </div>
      <div slot="footer">
        <button type="button" class="btn btn-success" @click="f_saveColumnRuleFilter()">{{ $t('wdm16.4061') }}</button>
        <button type="button" class="btn btn-danger" @click="d_columnFilterShowModal = false">{{ $t('wdm16.4053') }}</button>
      </div>
    </modal> -->
  </div>
</template>

<script>
// import { PATIENT_APP_FRONTEND_URL, API_FRONTEND_URL } from '@/config';
import { PATIENT_APP_FRONTEND_URL, API_FRONTEND_URL } from '@/config';
import { ClsRule } from '@/services/public/rule';
import { ClsMenu } from '@/services/public/menu';
import { ClsPolestar } from "@/services/public/polestar";
import {
  default as WTable2
} from '@/components/widgets/WTable2';
import {
  default as WisdomStatsDataTable
} from "@/components/widgets/WisdomStatsDataTable";
import {
  default as AnalyzeReportView
} from "@/components/widgets/AnalyzeReportView";
import {
  default as WisdomTable
} from "@/components/widgets/WisdomTable";
import {
  default as WisdomStatsMenu
} from "@/components/widgets/WisdomStatsMenu";
import {
  default as UserSms
} from "@/components/widgets/UserSms";
/*import {
  default as HimsProtocolDetails
} from "@/components/widgets/HimsProtocolDetails";
*/
import DatasetService from '@/services/dataset';
import {
  default as ArticleEdit
} from "@/components/widgets/ArticleEdit";
import {
  default as PolestarFastAnalyze
} from "@/components/widgets/PolestarFastAnalyze";
import {
  default as PolestarResult
} from "@/components/widgets/PolestarResult";
import {
  default as WdmParameters
} from "@/components/widgets/WdmParameters";
import {
  default as PoleStarColumnFeature
} from '@/components/widgets/PoleStarColumnFeature';
import MessengerService from "@/services/messenger";
/*import {
  default as Messenger
} from '@/components/widgets/Messenger';
*/
import LineView from '@/components/chart/LineView';
import ScatterView from '@/components/chart/ScatterView';
import BarView from '@/components/chart/BarView';
import {
  default as RuleCompileStr
} from '@/components/widgets/RuleCompileStr';
import {
  default as RuleQuery
} from '@/components/widgets/RuleQuery';
import {
  default as WdmRuleA
} from '@/components/widgets/WdmRuleA';
import {
  default as WisdomStatsSelect
} from '@/components/widgets/WisdomStatsSelect';
import {
  default as WisdomDataShow
} from '@/components/widgets/WisdomDataShow';
/*import {
  default as PatientWdmr
} from '@/components/widgets/PatientWdmr';
*/
import {
  default as Modal
} from "@/components/widgets/Modal";
import {
  default as WisdomDataModal
} from '@/components/widgets/WisdomDataModal';
import {
  default as WdmParameterLocation
} from '@/components/widgets/WdmParameterLocation';
import { mapGetters } from 'vuex';
import PhraseService from '@/services/phrase';
import ProjectService from '@/services/project';
import OptionService from '@/services/option';
import LayerService from '@/services/layer';
import DecisionMakingService from '@/services/decision_making';
import RuleEngineService from '@/services/rule_engine';
import WdmService from '@/services/wdm';
import GlobalService from '@/services/global';
import PatientService from '@/services/patient';
import { ClsWdmrForever, ClsWdmrSave } from "@/services/public/wdm";
import vSelect from 'vue-select';
import WebService from '@/services/webservice';
import PoleStarService from '@/services/polestar';
import MachineLearningService from '@/services/machine_learning';
import { ClsWdmrManage } from "@/services/public/wdmr_manage";
//
import FiletransferService from '@/services/file_transfer';
import SmsService from '@/services/sms';
import HospitalService from '@/services/hospital';
import { DateFunctions } from '@/services/public/date_functions';
import moment from 'moment';
import FileSaver from 'file-saver';
export default {
  name: 'PoleStarWisdomStats',
  computed: mapGetters({
    StoreModal: 'modal',
    lang: 'lang'
  }),
  components: {
    AnalyzeReportView,
    PolestarFastAnalyze,
    PoleStarColumnFeature,
    vSelect,
    Modal,
    WisdomDataModal,
    WisdomDataShow,
    RuleQuery,
    RuleCompileStr,
    WisdomStatsSelect,
    LineView,
    BarView,
    ScatterView,
    WdmParameterLocation,
    // Messenger,
    WdmRuleA,
    // PatientWdmr,
    WdmParameters,
    WisdomTable,
    WisdomStatsDataTable,
    WisdomStatsMenu,
    UserSms,
    PolestarResult,
    ArticleEdit,
    WTable2
    // HimsProtocolDetails
  },
  props: {},
  data () {
    return {
      d_datasetViewLoading: false,
      d_datasetWTable: '',
      d_statisticFunctions: { 'show': false },
      d_projectReports: { 'list': [] },
      d_activityData: { 'show': false },
      d_phraseData: { 'show': false, 'wisdom_data': {}, 'list': [] },
      d_project_id: '',
      d_wdm332WisdomData: '',
      d_showWdm332Modal: false,
      d_selectedWdm332Key: '',
      d_selectedWdm332Data: '',
      d_navPhraseRight: 'phrase_details',
      d_navPhraseLeft: 'nav_phrase_list',
      d_selectedWdm332DataViewMode: 'table',
      d_columnFilterShowModal: false,
      d_userPolestarPermissions: [],
      d_fullScreenGeneral: true,
      d_polestarSettings: {},
      d_eligibleForColumnOptionRuleMatch: false,
      d_smsOperationData: {
        'selected': '',
        'row_ind': '',
        'list': [],
        'show': false,
        'selected_sms_provider': { 'label': '', 'key': '' }
      },
      d_navDmpLeft: 'nav_dmp_list',
      d_navDmpRight: 'dmp_details',
      d_navArticleLeft: 'dmp_list',
      d_selectedWdmr24Id: '',
      d_selectedWdmr24Data: '',
      d_iframeHimsProtocolDetails: { 'url': '' },
      d_iframePatientWdmr: { 'url': '' },
      PATIENT_APP_FRONTEND_URL: PATIENT_APP_FRONTEND_URL,
      d_eligibleForHimsInspectionMatch: false,
      d_eligibleForWdmrRuleMatch: false,
      d_eligibleForColumnOptionMixedWdmrRuleMatch: false,
      d_eligibleForHimsInspectionSearch: false,
      d_eligibleForWmdrRuleSearch: false,
      d_inspectionRuleData: {
        'query_list': [],
        'compile_str_array': [],
        'compile_str_date': [],
        'compile_str_text': []
      },
      d_showHimsProtocols: false,
      d_navMachineLearningDetails: 'ml_model_details',
      d_selectedWdm163_data: '',
      d_selectedWdm163_key: '',
      d_selectedMachineLearningData: '',
      d_machineLearningData: { 'show': false, 'wisdom_data': {} },
      d_selectedAnatomySchemaList: [],
      w_presentation: false,
      d_showProjectDetails: true,
      show_wisdom_data_modal: false,
      d_combinePatientData: { 'hims_patient_no': '', 'show': false },
      d_screenModeIndex: '',
      d_relateHospitalPatient: { 'show': false },
      d_fastAnalyzeModeShowModal: false,
      d_columnValueLabelList: [],
      d_fastAnalyzeModeSettings: {
        'show': false,
        'table': [],
        'mode': 'one_variable' // one_variable, cross_columns
      },
      d_showColumnSelectionModal: false,
      d_navColumn: 'general',
      d_wdmRuleAsettings: {
        'mode': 'param',
        'options': [
          { 'label': 'WDM & QUERY', 'value': 'wdm_query' },
          { 'label': 'PARAM', 'value': 'param' },
          { 'label': 'PARAM & QUERY', 'value': 'param_query' }
        ]
      },
      d_wdmParameterSelectData: [{
        'operation': '',
        'selected_value': '',
        'loc': [],
        'val_list': [],
        'selected_wdm': '',
        'd_level': 0,
        'd_level_count': 0,
        'significant': 'true'
      }],
      d_rowData: { 'show': false, 'row_ind': '', 'row_data': {} },
      d_columnData: { 'show': false, 'col_index': '' },
      mother_children: { 'forceUpdate': 0, 'send_table_to_article': 0, 'article_naming': 0, 'get_project_cases': 0, 'update_project_data': 0, 'get_project_data': 0, 'save_columns': 0 },
      d_wdmParameters: {
        'selection': {
          'times': 'multi', // one, multi
          'type': 'this_index', // all, this_index, this_types
          'this_index': [
            ['article_summary', 'article_summary_purpose', 'article_summary_purpose_info'],
            ['article_summary', 'article_summary_methods', 'article_summary_methods_info'],
            ['article_summary', 'article_summary_results', 'article_summary_results_info'],
            ['article_summary', 'article_summary_conclusion', 'article_summary_conclusion_info'],
            ['article', 'article_general_information', 'article_general_information_info'],
            ['article', 'article_methods', 'article_methods_info'],
            ['article', 'article_statistics', 'article_statistics_info'],
            ['article', 'article_results', 'article_results_info'],
            ['article', 'article_conclusion', 'article_conclusion_info']
          ]
        },
        'brief_list': {},
        'selected_wdm': '',
        'user_selected_brief_wdm_list': [],
        'option_data': {},
        'data_type': '',
        'search_in_first_parameter_list': '',
        'selected_index_data': { 'index': '', 'index_list': [] },
        'show_parameter_features': '',
        'param_change': { 'update': 0, 'new_parameter_added': 0 }
      },
      wdmr_add_edit: { 'change': 0 },
      patient_data: '',
      d_showPatientWdmr: false,
      d_navArticleRight: 'article_details',
      d_wdm73WisdomData: {},
      d_showWdm73Modal: false,
      d_navArticleLeft: 'nav_article_list',
      d_selectedWdm73Key: '',
      d_selectedWdm73Data: {},
      d_selectedWdm73DataViewMode: 'table',
      d_wdm72RuleDesign: { 'query_list': [], 'compile_str_array': [], 'compile_str_date': [], 'compile_str_text': [] },
      d_tableModeSettings: { 'start': 0, 'end': 20, 'current': 1, 'perpage': 20, 'show_selected_columns': [] },
      d_showWdmRuleA: true,
      d_selectedWdm72Columns: [],
      d_showAnalyzeCharts: false,
      d_filterAnalyzeData: {
        'user_score_list': '',
        'selected_columns': [],
        'filter_selected_columns': [],
        'filter_analyze_types': [],
        'filter_stats': [],
        'filter_p_value': ['', '0.001', '0.05', '0.06', '0.07', '0.08', '0.09', '0.1'],
        'selected_analyze_types': [],
        'selected_stats': [],
        'show': false,
        'selected_p_value': ''
      },
      d_fullScreenResults: false,
      d_tableFullScreenData: { 'show': false, 'table': [], 'label': '' },
      d_selectedTableCellFullText: '',
      d_showTableCellFullText: false,
      d_showChartView: true,
      d_normalizationGraphShowType: 'real',
      d_navAnalyzeList: 'analyze_model_list',
      d_navMachineLearningList: 'machine_learning_list',
      d_fullScreen: false,
      d_navAnalyzeResult: 'analyze_model',
      d_selectedWdm72Data: {},
      d_selectedWdm72Results: [],
      d_selectedWdm72Key: '',
      d_statisticWisdomData: {},
      d_showStatisticsModal: false,
      d_searchAnalyzeTable: '',
      d_columnForRuleFilterList: [],
      change: '0',
      d_selectedWdm72DataViewMode: 'table',
      d_projectDetailsViewMode: 'table',
      d_showNewRowModal: false,
      d_deleteColumnList: [],
      d_showLabelsWdmLocationFormat: false,
      d_showMessenger: false,
      d_chartSize: {
        'height': 0,
        'width': 0,
        'x_mode': 'standart',
        'y_mode': 'standart',
        'x_interval_type': 'all',
        'x_interval': 1,
        'chart_type': ''
      },
      d_showGraphicView: false,
      chart_data: {},
      d_resetChartData: {},
      d_filterColumnVal: '',
      d_datasetPagination: {
        'start': 0,
        'end': 20,
        'current': 1,
        'perpage': 20
      },
      d_selected_cells: [],
      d_dataset_case_rows: [],
      d_tableBuffer: { 'start': 0, 'end': 10000 },
      d_selectedAnalyzeResultInd: '',
      analyze_column_list: [],
      row_parameter_list: [],
      column_parameter_list: [],
      d_selectedMenu: 'file',
      d_showNewColumnRuleModal: false,
      d_selectedColumnInd: '',
      column_list: [],
      parameter_design_full_screen: false,
      // seçeneklerde olabilecekler veritabanından analiz edilerek çekiliyor.
      user: {},
      option_data: {},
      d_selectedProjectData: '',
      d_dataset: { 'excel_file': '', 'case_rows': [], 'case_columns': [], 'cell_features': [], 'column_val_list': [] },
      d_searchColumn: '',
      d_showMode: 'data_table',
      d_showStatsSelect: false,
      d_selectedAnalyzeModel: { 'analyze_model': '', 'type': '', 'row_parameter_group_type': '1' },
      d_columnRule: {},
      d_mainRuleShowModal: false,
      d_mainRule: {},
      d_prepareRuleNew: {
        'date_ability': '',
        'wdmr_change': { 'rule': 0 },
        'data_type': '',
        'option_data': {},
        'prepare_data': {},
        'query_list': [],
        'compile_str_date': [],
        'compile_str_text': [],
        'compile_str_array': [],
        'wra_settings': {
          'mode': 'param',
          'options': [
            { 'label': 'WDM & QUERY', 'value': 'wdm_query' },
            { 'label': 'PARAM', 'value': 'param' },
            { 'label': 'PARAM & QUERY', 'value': 'param_query' }
          ]
        },
        'wps_data': [{
          'operation': '',
          'selected_value': '',
          'loc': [],
          'val_list': [],
          'selected_wdm': '',
          'd_level': 0,
          'd_level_count': 0,
          'significant': 'true'
        }],
        'wdm_list': []
      },
      d_newRowData: { 'row_count': 0, 'project_id': '' },
      d_projectAnalyzeList: [],
      d_wdm72Pagination: {
        'start': 0,
        'end': 10,
        'current': 1,
        'perpage': 10,
        'all_count': 0
      },
      d_wdm72RefreshedFromSocket: '',
      d_showRuleAlgorithm: { 'show': false, 'type': '' },
      d_articleList: {
        'show': false,
        'list': [],
        'selected_articles': [],
        'other': [],
        'wdm72_result': []
      },
      d_tableToArticle: {
        'show': false,
        'wdmr_key_list': [],
        'buck': '', // wisdom, polestar, ...
        'table': '', // table data contains multidimensional array. [[0,1,2,3], [4,5,6,7], ...]
        'wdm72_key': '',
        'selected_wdm73_key': '',
        'analyze_no': '',
        'table_name': '',
        'article_list': []
      },
      d_chartToArticleAsFigure: {
        'show': false,
        'wdmr_key_list': [],
        'buck': 'polestar', // wisdom, polestar, ...
        'chart': '',
        'wdm72_key': '',
        'chart_name': '',
        'analyze_no': '',
        'article_list': []
      },
      d_projectWdmList: [],
      d_editCell: '',
      d_cellTextTimer: { 'timeout': '', 'text': '' },
      d_searchColumnTextTimer: { 'timeout': '', 'text': '' },
      d_datasetKeyList: [],
      d_rowRightClickElementID: '',
      d_rowRightClickElementIDList: [],
      d_icons: {
        '969174': require('../../icon/969174.png'),
        '453632': require('../../icon/453632.png'),
        '2162287': require('../../icon/2162287.png'),
        '42729': require('../../icon/42729.png'),
        '2940638': require('../../icon/2940638.png'),
        '2324511': require('../../icon/2324511.png'),
        '1013986': require('../../icon/1013986.png'),
        '1115721': require('../../icon/1115721.png'),
        '2960590': require('../../icon/2960590.png')
      },
      d_userScreenModes: [],
      wisdom_data: {},
      d_eligibleForCaseSearch: false,
      d_wdmr24List: [],
      d_wisdomData: {
        'view_mode': 'table',
        'change': '0',
        'show': false,
        'wisdom_data': {},
        'data_type': '',
        'wisdom_op': '',
        'option_data': {},
        'show_data': false
      },
      d_wdmr22: {},
      d_timerControlProjectUpdate: { 'timer': 0, 'status': 'passive', 'max': 30000, 'interval': 5000, 'check_count_after_not_continue': 0 },
      d_rowDmpAnalyzeResults: { 'rows': {}, 'columns': [] }
    };
  },
  created: function () {
    this.w_presentation = JSON.parse(localStorage.getItem('presentation'));
    this.f_getWdmList();
    if (this.$route.query.project_id) {
      this.f_goToThisProject(this.$route.query.project_id);
    }
    this.user = JSON.parse(localStorage.getItem('user'));
    this.d_wdmr22 = JSON.parse(localStorage.getItem('wdm22'));
    this.d_columnRule = JSON.parse(JSON.stringify(this.d_prepareRuleNew));
  },
  beforeMount () {},
  destroyed () {
    // window.clearInterval(this.d_timerControlProjectUpdate.timer);
    // clearInterval(this.d_nextDrawAlgorithm);
  },
  mounted: function () {
    // console.log(this.$options.sockets);
    this.$options.sockets.onmessage = function (message) {
      let socket_data = JSON.parse(message.data);
      // console.log(socket_data);
      if (socket_data.type === 'wdm72_results') {
        let det = false;
        for (let wdm3_id in socket_data.data.analyze_results) {
          for (let wdm72_key in socket_data.data.analyze_results[wdm3_id]) {
            if (this.d_selectedWdm72Key === wdm72_key) {
              det = true;
              if (this.d_wdm72RefreshedFromSocket !== wdm72_key) {
                this.f_selectWdm72(this.d_selectedWdm72Key);
                this.d_wdm72RefreshedFromSocket = wdm72_key;
                break;
              }
            }
          }
          if (det) {
            break;
          }
        }
      } else if (socket_data.type === 'wdm72_result_pdf_file') {
        FileSaver.saveAs(socket_data.data.file, socket_data.data.file_name);
      } else if (socket_data.type === 'wdm3_preparation_completed') {
        if (this.d_selectedProjectData.id === socket_data.data.wdm3_id) {
          this.f_getCases();
        }
      } else if (socket_data.type === 'wdm3_get_project_cases') {
        if (this.d_selectedProjectData.id === socket_data.data.wdm3_id) {
          this.f_getCases();
        }
      } else if (socket_data.type === 'dataset_add_new_row') {
        let row_ind = '';
        for (let i in this.d_dataset.case_id_list) {
          if (this.d_dataset.case_id_list[i].no === socket_data.data.data.no) {
            row_ind = parseInt(i);
            break;
          }
        }
        this.f_newRowApply(socket_data.data.data.type, socket_data.data.data, row_ind);
      } else if (socket_data.type === 'delete_dataset_row') {
        let row_ind = '';
        for (let i in this.d_dataset.case_id_list) {
          if (this.d_dataset.case_id_list[i].no === socket_data.data.row_no) {
            row_ind = parseInt(i);
            break;
          }
        }
        this.f_deleteRowApply(row_ind, socket_data.data.total_cases);
      } else if (socket_data.type === 'combine_with_this_patient') {
        let row_ind = '';
        for (let i in this.d_dataset.case_id_list) {
          if (this.d_dataset.case_id_list[i].no === socket_data.data.row_no) {
            row_ind = parseInt(i);
            break;
          }
        }
        this.d_dataset.case_id_list[row_ind].id = socket_data.data.patient_id;
        this.d_dataset.case_id_list[row_ind].firstname = socket_data.data.firstname;
        this.d_dataset.case_id_list[row_ind].lastname = socket_data.data.lastname;
        this.$forceUpdate();
      } else if (socket_data.type === 'wdm3_preparation_error') {
        let modal_data = { 'type': 'alert', 'text': socket_data.data.message, 'centered': true, 'title': 'Proje verileri oluşturma işlem hatası hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      } else if (socket_data.type === 'polestar_cell_edit') {
        for (let i in this.d_dataset['case_rows']) {
          if (this.d_dataset['case_id_list'][i]['no'] === socket_data.data.row_no) {
            try {
              let col_ind = parseInt(socket_data.data.col_ind);
              let row_ind = parseInt(socket_data.data.row_ind);
              let cell_td_id = 'td_cell_' + row_ind.toString() + '_' + col_ind.toString();
              this.d_dataset['case_rows'][i][col_ind] = socket_data.data.val.case_rows;
              this.d_dataset['cell_dates'][i][col_ind] = socket_data.data.val.cell_dates;
              let cell_td_id_popup = cell_td_id + '_popup';
              if (!document.getElementById(cell_td_id_popup)) {
                let cell_td_element = document.getElementById(cell_td_id);
                let off = cell_td_element.getBoundingClientRect();
                let top = off.top - 40;
                let left = off.left;
                let popup_element = document.createElement('span');
                popup_element.id = cell_td_id_popup;
                popup_element.classList.add("popup");
                popup_element.style.top = top.toString() + 'px';
                popup_element.style.left = left.toString() + 'px';
                popup_element.innerHTML = socket_data.data.user.first_name + ' ' + socket_data.data.user.last_name;
                // console.log(popup_element);
                cell_td_element.appendChild(popup_element);
                if (cell_td_element) {
                  cell_td_element.style.backgroundColor = 'rgb(251, 181, 173)';
                  setTimeout(function () {
                    cell_td_element.style.backgroundColor = '';
                    popup_element.remove();
                    this.$forceUpdate();
                  }.bind(this), 1000);
                }
              }
            } catch (err) {
              console.log(err);
            }
            this.$forceUpdate();
            break;
          }
        }
      }
    };
  },
  methods: {
    f_projectDatasetGet: function (project_id, table_type='dataset_table') {
      if (!this.d_datasetViewLoading) {
        this.d_datasetViewLoading = true;
        let query = 'project_id=' + project_id;
        let data = {
          'pagination': '',
          'filter_rule': {},
          'wai_columns': 'no'
        };
        if (table_type === 'dataset_filter_table') {
          this.d_datasetWTableFilter = '';
          data.filter_rule = this.f_createRuleAlgorithm(this.d_project);
        } else if (table_type === 'dataset_table') {
          this.d_datasetWTable = '';
          data.filter_rule = {};
        }
        PoleStarService.project_dataset_get(query, data)
          .then(resp => {
            this.d_datasetViewLoading = false;
            if (resp.data.status_code.substring(resp.data.status_code.length - 3, resp.data.status_code.length) == '000') {
              if (table_type === 'dataset_filter_table') {
                this.d_datasetWTableFilter = resp.data.wtable;
              } else if (table_type === 'dataset_table') {
                this.d_datasetWTable = resp.data.wtable;
              }
            } else {
              let txt = 'status_code = ' + resp.data.status_code + '\n';
              txt += 'status_message = ' + resp.data.status_message;
              alert(txt);
            }
          });
      }
    },
    f_statsReportAndModuleChartsUpdate: function (wdm72_key) {
      if (this.d_userPolestarPermissions.indexOf('owner') !== -1 || this.d_userPolestarPermissions.indexOf('admin') !== -1 || this.d_userPolestarPermissions.indexOf('project_add_edit_statistical_analyze') !== -1) {
        let query = 'project_id=' + this.d_selectedProjectData.id + '&stats_key=' + wdm72_key;
        let data = {};
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'İşlem gerçekleştiriliyor. Lütfen bekleyiniz.' } });
        PoleStarService.project_stats_report_and_module_update(query, data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.status_code === '2000') {
              let modal_data = { 'type': 'alert', 'text': 'İşlem tamamlandı', 'centered': true, 'title': this.$t('wdm16.13274') };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
            } else {
              let modal_data = { 'type': 'alert', 'text': resp.data.status_message, 'centered': true, 'title': this.$t('wdm16.13274') };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
            }
          });
      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_mergeProjects: function () {
      if (this.d_userPolestarPermissions.indexOf('owner') !== -1 || this.d_userPolestarPermissions.indexOf('admin') !== -1 || this.d_userPolestarPermissions.indexOf('project_general_info_edit') !== -1) {
        let query = 'project_id=' + this.d_selectedProjectData.id;
        let data = {};
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Proje verileri birleştiriliyor. Lütfen bekleyiniz.' } });
        PoleStarService.project_merge_dataset(query, data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.status_code === '2000') {
              this.f_getCases();
            } else {
              let modal_data = { 'type': 'alert', 'text': resp.data.status_message, 'centered': true, 'title': this.$t('wdm16.13274') };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
            }
          });
      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_showStatisticFunctions: function (wdm72_key) {
      this.d_statisticFunctions.wdm72_key = wdm72_key;
      this.d_statisticFunctions.show = true;
    },
    f_deleteProjectDatasetList: function (delete_columns='no') {
      if (this.d_userPolestarPermissions.indexOf('owner') !== -1 || this.d_userPolestarPermissions.indexOf('admin') !== -1 || this.d_userPolestarPermissions.indexOf('project_dataset_delete') !== -1) {
        if (confirm('Proje datasetlerini temizlemek istediğinize emin misiniz ?')) {
          let query = 'project_id=' + this.d_selectedProjectData.id;
          if (delete_columns) {
            query += '&delete_columns=' + delete_columns;
          }
          this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Dataset silme işleminiz başladı. Lütfen bekleyiniz.' } });
          DatasetService.project_dataset_delete(query)
            .then(resp => {
              this.$store.commit('Loading', { 'status': false, 'data': {} });
              let msg = '';
              if (resp.data.status_code === '2000') {
                msg = 'İşlem tamamlandı.';
              } else {
                msg = 'Hata: status_code = ' + resp.data.status_code + ', status_message = ' + resp.data.status_message;
              }
              let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
              this.f_getCases();
            });
        }
      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_calculateContinuingTitle: function () {
      let title = '';
      let data_updating_status = '';
      let data_option_algorithm_status = '';
      let algorithm_analyzing_status = '';
      let data_wdmr_algorithm_status = '';
      let data_wdmr_option_algorithm_status = '';
      try {
        if (this.d_selectedProjectData.data.general.data_updating_status.val.value === 'continuing') {
          data_updating_status = this.d_selectedProjectData.data.general.data_updating_status.val.label;
          title += 'Veri güncelleme işlemi : ' + data_updating_status;
        }
      } catch (err) {}
      try {
        if (this.d_selectedProjectData.data.general.data_option_algorithm_status.val.value === 'continuing') {
          data_option_algorithm_status = this.d_selectedProjectData.data.general.data_option_algorithm_status.val.label;
          title += 'Seçenek algoritmaları işlemi : ' + data_option_algorithm_status;
        }
      } catch (err) {}
      try {
        if (this.d_selectedProjectData.data.general.algorithm_analyzing_status.val.value === 'continuing') {
          algorithm_analyzing_status = this.d_selectedProjectData.data.general.algorithm_analyzing_status.val.label;
          title += 'Algoritma analiz işlemi : ' + algorithm_analyzing_status;
        }
      } catch (err) {}
      try {
        if (this.d_selectedProjectData.data.general.data_wdmr_algorithm_status.val.value === 'continuing') {
          data_wdmr_algorithm_status = this.d_selectedProjectData.data.general.data_wdmr_algorithm_status.val.label;
          title += 'Wdmr algoritma işlemi : ' + data_wdmr_algorithm_status;
        }
      } catch (err) {}
      try {
        if (this.d_selectedProjectData.data.general.data_wdmr_option_algorithm_status.val.value === 'continuing') {
          data_wdmr_option_algorithm_status = this.d_selectedProjectData.data.general.data_wdmr_option_algorithm_status.val.label;
          title += 'Wdmr seçenek algoritma işlemi : ' + data_wdmr_option_algorithm_status;
        }
      } catch (err) {}

      return title;
    },
    f_addColumnInfoTableToArticle: function () {
      let table_data = {
        'footnotes': [
          { 'text': 'POM: Phrase Option Match: Seçenek ifade eşleştirmesi. İfade etiketleme sonrası seçeneklere eşleştirme yapılmaktadır.' },
          { 'text': 'CRA: Column Rule Algorithm: Kolonlar arası algoritma kuralları oluşturarak bir kolondaki hücrelere ait verilerin doldurma işlemi yapılmaktadır.' },
          { 'text': 'OCRA: Option Column Rule Algorithm: Kolonlar arası algoritma kuralları oluşturarak bir kolondaki oluşturulmuş seçeneği bu algoritm kurallarına bağlanmaktadır.' },
          { 'text': 'MF: Mathematics Functions: Matematiksel fonksiyonlarla bir kolondaki verilerin üretilmesinin sağlanmasıdır.' }
        ],
        'table_name': 'Kolon Bilgileri',
        'table': [
          ['Kolon Adı', 'Veri Tipi', 'Veri Oluşturma Şekli', 'Açıklama']
        ]
      }
      for (let i in this.column_list) {
        let new_column_row = [];
        new_column_row.push(this.column_list[i].label);
        if (['select', 'object_list_options', 'radiogroup', 'checkbox'].indexOf(this.column_list[i].param_type) !== -1) {
          new_column_row.push('K');
        } else if (['integer', 'float'].indexOf(this.column_list[i].param_type) !== -1) {
          new_column_row.push('N');
        } else {
          new_column_row.push('');
        }
        let data_fill_type = '';
        if (this.column_list[i].options && this.column_list[i].options.length > 0) {
          for (let op in this.column_list[i].options) {
            if (this.column_list[i].options[op].phrase_match && Object.keys(this.column_list[i].options[op].phrase_match).length > 0) {
              if (data_fill_type.indexOf('POM') === -1) {
                if (data_fill_type) {
                  data_fill_type += ',';
                }
                data_fill_type += 'POM';
              }
            }
            if (this.column_list[i].options[op].rule && Object.keys(this.column_list[i].options[op].rule).length > 0) {
              if (data_fill_type.indexOf('OCRA') === -1) {
                if (data_fill_type) {
                  data_fill_type += ',';
                }
                data_fill_type += 'OCRA';
              }
            }
          }
        }
        if (this.column_list[i].data_creation_rule && this.column_list[i].data_creation_rule.length > 0) {
          if (data_fill_type) {
            data_fill_type += ',';
          }
          data_fill_type += 'MF,CRA';
        }
        new_column_row.push(data_fill_type);
        new_column_row.push('');
        table_data.table.push(new_column_row);
      }
      if (!this.d_selectedWdm73Data.tables) {
        this.d_selectedWdm73Data.tables = [];
      }
      this.d_selectedWdm73Data.tables.push(table_data);
      this.f_wisdomDataShowChange();
    },
    f_getSqlOfMainRule: function (type = '') {
      let data = {
        'project_id': this.d_selectedProjectData.id
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Lütfen bekleyiniz.' } });
      LayerService.get_sql_of_main_rule(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          let txt = '';
          if (resp.data.status === "success") {
            txt = resp.data.result.sql;
          } else {
            txt = resp.data.message;
          }
          let modal_data = { 'type': 'alert', 'text': txt, 'centered': true, 'title': this.$t('wdm16.13274') };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
          this.$forceUpdate();
        });
    },
    f_addNewPhraseModal: function (type = '') {
      if (this.d_userPolestarPermissions.indexOf('owner') !== -1 || this.d_userPolestarPermissions.indexOf('admin') !== -1 || this.d_userPolestarPermissions.indexOf('project_general_info_edit') !== -1) {
          this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'İfade ekranı hazırlanıyor. Lütfen bekleyiniz.' } });
          setTimeout(function () {
            this.f_prepareWdm332(type);
          }.bind(this), 500)
      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_prepareWdm332: function (type) {
      let pre_data = {
        'id': 'new',
        'data': {},
        'type': 'wdm332',
        'reference_wdm': {},
        'wdm3_id': this.d_selectedProjectData.id
      };
      pre_data['reference_wdm']['version'] = this.option_data['wdm332']['version'];
      pre_data['reference_wdm']['owner_type'] = this.option_data['wdm332']['owner_type'];
      if (this.option_data['wdm332']['owner_type'] === 'wisdom') {
        pre_data['reference_wdm']['key'] = 'wdm_wisdom_wdm332_v' + this.option_data['wdm332'].version;
      }
      for (let i in this.option_data['wdm332'].param_group.param_order) {
        pre_data['data'][this.option_data['wdm332'].param_group.param_order[i]] = {};
      }
      let col_list = [];
      for (let i in this.column_list) {
        let lbl = this.column_list[i].label + '( no:' + i + ' ) -' + this.column_list[i].param_type + ' -';
        // We took the column index in value. So when changing we can use value field.
        let x = {
          'label': lbl,
          'value': i.toString(),
          'param_type': this.column_list[i].param_type
        }
        col_list.push(x);
      }
      this.option_data['wdm332'].general.parameters.column.options = col_list;
      this.option_data['wdm332'].general.parameters.phrase_search_column.parameters.phrase_search_column_name.options = col_list;
      this.d_phraseData.wisdom_data = pre_data;
      this.d_phraseData.show = true;
      this.$store.commit('Loading', { 'status': false, 'data': {} });
    },
    f_getPhraseList: function () {
      this.d_phraseData.list = [];
      let query = "project_id=" + this.d_selectedProjectData.id;
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Lütfen bekleyiniz.' } });
      PhraseService.get_phrase_list(query)
        .then(resp => {
          if (resp.data.status === "success") {
            this.d_phraseData.list = resp.data.result.list;
            this.d_phraseData.show = false;
            this.$store.commit('Loading', { 'status': false, 'data': {} });
          } else {
            let modal_data = { 'type': 'alert', 'text': resp.data.message, 'centered': true, 'title': this.$t('wdm16.13274') };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          }
          this.$forceUpdate();
        });
    },
    f_selectWdm332: function (wdm332_key) {
      this.d_selectedWdm332Key = wdm332_key;
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Lütfen bekleyiniz.' } });
      GlobalService.get_one_cb_document({ 'key': wdm332_key, 'bucket': 'polestar' })
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_selectedWdm332Data = resp.data.result;
            this.f_wisdomDataShowChange();
            this.$forceUpdate();
          } else {
            alert('error', resp.data.message);
          }
          this.$store.commit('Loading', { 'status': false, 'data': {} });
        });
    },
    f_editWdm332Data: function (wdm332_key) {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Lütfen bekleyiniz.' } });
      GlobalService.get_one_cb_document({ 'key': wdm332_key, 'bucket': 'polestar' })
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            let col_list = [];
            for (let i in this.column_list) {
              let lbl = this.column_list[i].label + '( no:' + i + ' ) -' + this.column_list[i].param_type + ' -';
              // We took the column index in value. So when changing we can use value field.
              let x = {
                'label': lbl,
                'value': i.toString(),
                'param_type': this.column_list[i].param_type
              }
              col_list.push(x);
            }
            this.option_data['wdm332'].general.parameters.column.options = col_list;
            this.option_data['wdm332'].general.parameters.phrase_search_column.parameters.phrase_search_column_name.options = col_list;
            this.d_phraseData.wisdom_data = resp.data.result;
            this.d_phraseData.show = true;
          } else {
            alert('error', resp.data.message);
          }
        });
    },
    f_phraseOperation: function (wdm332_key, type = '') {
      let data = {};
      data = {
        'wdm332_key_list': [wdm332_key],
        'project_id': this.d_selectedProjectData.id
      };
      if (type) {
        data.type = type;
      }
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'İfade analizi yapılıyor. Lütfen bekleyiniz.' } });
      PhraseService.phrase_operation(data)
        .then(resp => {
          if (resp.data.status === "success") {
            if (resp.data.result && resp.data.result.data && resp.data.result.data[wdm332_key]) {
              this.d_selectedWdm332Data = resp.data.result.data[wdm332_key];
              this.f_wisdomDataShowChange();
            }
            this.$store.commit('Loading', { 'status': false, 'data': {} });
          } else {
            let modal_data = { 'type': 'alert', 'text': resp.data.message, 'centered': true, 'title': this.$t('wdm16.13274') };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          }
          this.$forceUpdate();
        });
    },
    f_articleReportByStatResults: function (report_type, lang_list, selected_wdm72_list) {
      let query = 'project_id=' + this.d_selectedProjectData.id + '&report_type=' + report_type;
      let data = { 'lang_list': lang_list, 'selected_wdm72_list': selected_wdm72_list };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Rapor hazırlanıyor. Lütfen bekleyiniz.' } });
      PoleStarService.article_report_by_stat_results(query, data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status_code === '2000') {
            FileSaver.saveAs(resp.data.analyze_text_excel, "AnalyzeReport.xlsx");
          } else {
            let modal_data = { 'type': 'alert', 'text': resp.data.status_message, 'centered': true, 'title': this.$t('wdm16.13274') };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          }
        });
    },
    f_saveWdm332: function () {
      let return_data = ClsWdmrForever.turn(this.d_phraseData.wisdom_data, this.option_data['wdm332'], ['name', 'delete']);
      let wdmr_name = return_data.name;
      if (wdmr_name) {
        this.d_phraseData.wisdom_data.label = wdmr_name;
      }
      let wdmr_require_alerts = return_data.wdmr_require_alerts;
      let only_one_param_is_filled = return_data.only_one_param_is_filled;
      if (!only_one_param_is_filled) {
        let modal_data = {
          'type': 'alert',
          'text': 'Veri modelini kaydedebilmek için en az bir veriniz dolu olmalıdır.',
          'centered': true,
          'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.'
        };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      if (wdmr_require_alerts && wdmr_require_alerts.length > 0) {
        let require_txt = '';
        for (let r in wdmr_require_alerts) {
          require_txt += ' *' + wdmr_require_alerts[r].label;
        }
        let modal_data = {
          'type': 'alert',
          'text': require_txt,
          'centered': true,
          'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.'
        };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      let data = {};
      data = {
        'data': this.d_phraseData.wisdom_data,
        'project_id': this.d_selectedProjectData.id
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kayıt yapılıyor. Lütfen bekleyiniz.' } });
      PhraseService.save_phrase_data(data)
        .then(resp => {
          if (resp.data.status === "success") {
            // this.f_motherChildren('update_project_data', resp.data.result.project_data);
            this.d_phraseData.show = false;
            this.d_selectedWdm332Data = resp.data.result.data;
            this.f_getPhraseList();
            this.f_wisdomDataShowChange();
            this.$store.commit('Loading', { 'status': false, 'data': {} });
          } else {
            let modal_data = { 'type': 'alert', 'text': resp.data.message, 'centered': true, 'title': this.$t('wdm16.13274') };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          }
          this.$forceUpdate();
        });
    },
    f_goToProjectResultsPage: function (wdm72_key) {
      let url = API_FRONTEND_URL + '#/project-statistics-results?project_id=' + this.d_selectedProjectData.id + '&result_id=' + this.d_selectedWdm72Data.id;
      window.open(url, '_blank');
    },
    f_createNewWdm72: function (type, easy_stats_type = '', column_selection_type = '') {
      if (this.d_userPolestarPermissions.indexOf('owner') !== -1 || this.d_userPolestarPermissions.indexOf('admin') !== -1 || this.d_userPolestarPermissions.indexOf('project_add_edit_statistical_analyze') !== -1) {
        // this.analyze_column_list = [];
        // this.column_parameter_list = [];
        // this.row_parameter_list = [];
        let pre_data = {
          'id': 'new',
          'data': {},
          'type': 'wdm72',
          'reference_wdm': {},
          'wdm3_id': this.d_selectedProjectData.id
        };
        pre_data['reference_wdm']['version'] = this.option_data['wdm72']['version'];
        pre_data['reference_wdm']['owner_type'] = this.option_data['wdm72']['owner_type'];
        if (this.option_data['wdm72']['owner_type'] === 'wisdom') {
          pre_data['reference_wdm']['key'] = 'wdm_wisdom_wdm72_v' + this.option_data['wdm72'].version;
        }
        for (let i in this.option_data['wdm72'].param_group.param_order) {
          pre_data['data'][this.option_data['wdm72'].param_group.param_order[i]] = {};
        }
        // this.d_selectedAnalyzeModel.type = type;
        // let analyze_type = '';
        // for (let i in this.option_data.wdm72.general.parameters.analyze_type.options) {
        //   if (this.option_data.wdm72.general.parameters.analyze_type.options[i].value === type) {
        //     analyze_type = this.option_data.wdm72.general.parameters.analyze_type.options[i];
        //     break;
        //   }
        // }
        pre_data['data']['general']['wdm3_name'] = { 'val': this.d_selectedProjectData.data.general.name.val };
        pre_data['data']['general']['wdm3_key'] = { 'val': 'wdm3_' + this.d_selectedProjectData.id };
        // pre_data['data']['general']['analyze_type'] = { 'val': analyze_type };
        pre_data['data']['general']['analyze_time_type'] = { 'val': { 'value': 'manuel', 'label': 'manuel' } };
        pre_data['data']['general']['auto_combination_cross_analysis'] = { 'val': { 'value': 'no', 'label': 'hayır' } };

        this.f_createWdm72ColList();
        this.d_statisticWisdomData = pre_data;
        this.d_showStatisticsModal = true;
        // this.d_showStatsSelect = true;
      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }

    },
    f_createWdm72ColList: function () {
      let col_list = [];
      for (let i in this.column_list) {
        let lbl = this.column_list[i].label + '( no:' + i + ' ) -' + this.column_list[i].param_type + ' -';
        // We took the column index in value. So when changing we can use value field.
        let x = { 'label': lbl, 'value': i.toString(), 'param_type': this.column_list[i].param_type }
        col_list.push(x);
      }
      this.option_data['wdm72'].general.parameters.analyze_column_list.options = col_list;
      this.option_data['wdm72'].general.parameters.row_parameter_list.options = col_list;
      this.option_data['wdm72'].general.parameters.column_parameter_list.options = col_list;
      this.option_data['wdm72'].general.parameters.before_after.parameters.first_column.options = col_list;
      this.option_data['wdm72'].general.parameters.before_after.parameters.second_column.options = col_list;
    },
    f_openColumnRuleFilterModal: function () {
      this.d_columnRule = JSON.parse(JSON.stringify(this.d_prepareRuleNew));
      if (this.d_selectedProjectData.filter && this.d_selectedProjectData.filter[this.user.username]) {
        if (this.d_selectedProjectData.filter[this.user.username].query_list) {
          this.d_columnRule.query_list = this.d_selectedProjectData.filter[this.user.username].query_list;
        }
        if (this.d_selectedProjectData.filter[this.user.username].compile_str_array) {
          this.d_columnRule.compile_str_array = this.d_selectedProjectData.filter[this.user.username].compile_str_array;
        }
        if (this.d_selectedProjectData.filter[this.user.username].compile_str_date) {
          this.d_columnRule.compile_str_date = this.d_selectedProjectData.filter[this.user.username].compile_str_date;
        }
        if (this.d_selectedProjectData.filter[this.user.username].compile_str_text) {
          this.d_columnRule.compile_str_text = this.d_selectedProjectData.filter[this.user.username].compile_str_text;
        }
      }
      ClsPolestar.f_prepareColumnRuleOptionData(this.d_columnRule, this.column_list);
      this.d_columnFilterShowModal = true;
    },
    f_saveColumnRuleFilter: function () {
      if (this.d_userPolestarPermissions.indexOf('owner') !== -1 || this.d_userPolestarPermissions.indexOf('admin') !== -1 || this.d_userPolestarPermissions.indexOf('project_general_info_edit') !== -1) {
          let parenthesis_control = ClsRule.f_controlParanthesisDesign(this.d_columnRule.compile_str_array);
          // console.log('parenthesis_control : ', parenthesis_control);
          if (parenthesis_control) {
            // When we write our code with mode param, we have to convert it to query_list format to make a filter.
            if (this.d_columnRule.wra_settings.mode === 'param') {
              ClsRule.f_paramToQuery(this.d_columnRule.query_list, this.d_columnRule.compile_str_array, this.d_columnRule.wps_data);
            }
            let data = {
              'column_rule_filter': {
                'query_list': this.d_columnRule.query_list,
                'compile_str_array': this.d_columnRule.compile_str_array,
                'compile_str_text': this.d_columnRule.compile_str_text,
                'compile_str_date': this.d_columnRule.compile_str_date
              },
              'wdm3_id': this.d_selectedProjectData.id
            };
            this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Size ait kolon kural filtresi kaydediliyor. Lütfen bekleyiniz.' } });
            PoleStarService.save_column_rule_filter(data)
              .then(resp => {
                this.$store.commit('Loading', { 'status': false, 'data': {} });
                let msg = '';
                if (resp.data.status === 'success') {
                  msg = 'Kayıt işlemi gerçekleştirildi';
                  this.d_columnFilterShowModal = false;
                  this.f_getCases();
                } else {
                  msg = resp.data.message;
                }
                let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Veri Seti Kayıt işlemi' };
                this.$store.commit('modal', modal_data);
                this.$store.commit('modal_show', true);
              });
          } else {
            let msg = 'Lütfen parentezlerinizi kontrol ediniz.';
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Algoritma kuralı oluşturma işlemi hakkında' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          }
      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_deleteColumnFilter: function () {
      let data = {
        'wdm3_id': this.d_selectedProjectData.id
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kolon kural filtresi temizleniyor. Lütfen bekleyiniz.' } });
      PoleStarService.delete_column_rule_filter(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          let msg = '';
          if (resp.data.status === 'success') {
            this.d_columnRule = JSON.parse(JSON.stringify(this.d_prepareRuleNew));
            this.f_getCases();
          } else {
            msg = resp.data.message;
          }
          if (msg) {
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Kolon kural filtresi silme işlemi' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          }
        });
    },
    f_saveMainRule: function () {
      if (this.d_userPolestarPermissions.indexOf('owner') !== -1 || this.d_userPolestarPermissions.indexOf('admin') !== -1 || this.d_userPolestarPermissions.indexOf('project_general_main_rule_edit') !== -1) {
        let parenthesis_control = ClsRule.f_controlParanthesisDesign(this.d_mainRule.compile_str_array);
        // console.log('parenthesis_control : ', parenthesis_control);
        if (parenthesis_control) {
          if (!this.d_selectedProjectData.layer_main_rules) {
            this.d_selectedProjectData.layer_main_rules = {};
          }
          let layer_key = '';
          if (this.d_mainRule.wdm246_key) {
            layer_key = this.d_mainRule.wdm246_key;
          } else if (this.d_mainRule.layer_key) {
            layer_key = this.d_mainRule.layer_key;
          }
          if (!this.d_selectedProjectData.layer_main_rules[layer_key]) {
            this.d_selectedProjectData.layer_main_rules[layer_key] = {}
          }
          if (this.d_mainRule.wra_settings.mode === 'param') {
            ClsRule.f_paramToQuery(this.d_mainRule.query_list, this.d_mainRule.compile_str_array, this.d_mainRule.wps_data);
          }
          this.d_selectedProjectData.layer_main_rules[layer_key] = {
            'query_list': this.d_mainRule.query_list,
            'compile_str_array': this.d_mainRule.compile_str_array,
            'compile_str_date': this.d_mainRule.compile_str_date,
            'compile_str_text': this.d_mainRule.compile_str_text
          };
          this.f_savePoleStarProject();
        } else {
          let msg = 'Lütfen parentezlerinizi kontrol ediniz.';
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Algoritma kuralı oluşturma işlemi hakkında' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        }
      } else {
        let msg = 'Ana algoritma kuralını düzenleme yetkiniz yoktur. Lütfen proje yetkilisi ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Proje ana algoritma kuralı oluşturma işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_editMainRuleAlgorithm: function (wdm246_key = '') {
      if (this.d_userPolestarPermissions.indexOf('owner') !== -1 || this.d_userPolestarPermissions.indexOf('admin') !== -1 || this.d_userPolestarPermissions.indexOf('project_general_main_rule_edit') !== -1) {
          let layer_key = '';
          try {
            layer_key = this.d_selectedProjectData.data.general.layer.val.value;
          } catch(err) {}
          if (wdm246_key) {
            layer_key = wdm246_key;
          }
          if (layer_key) {
            let layer_tree_name = '';
            let layer_region = 'authorized_layers';
            try {
              layer_region = this.d_selectedProjectData.data.general.layer_region.val.value;
            } catch(err) {}
            try {
              layer_tree_name = this.d_selectedProjectData.data.general.layer_tree_name.val.value;
            } catch(err) {}
            if (layer_region === 'authorized_layers') {
              let data = { 'wdm246_key': layer_key, 'wdm22_id': this.d_selectedProjectData.owner_id };
              LayerService.get_sub_layer_wdm_list(data)
                .then(resp => {
                  if (resp.data.status === 'success') {
                    if (resp.data.result.wdm246_sub_list && resp.data.result.wdm246_sub_list.length > 0) {
                      this.d_mainRule = JSON.parse(JSON.stringify(this.d_prepareRuleNew));
                      this.d_mainRule.layer_key = layer_key;
                      this.d_mainRule.wdm_list = resp.data.result.wdm246_sub_list;
                      if (this.d_selectedProjectData.layer_main_rules) {
                        if (this.d_selectedProjectData.layer_main_rules[layer_key]) {
                          this.d_mainRule.compile_str_array = this.d_selectedProjectData.layer_main_rules[layer_key]['compile_str_array'];
                          if (this.d_selectedProjectData.layer_main_rules[layer_key]['compile_str_date']) {
                            this.d_mainRule.compile_str_date = this.d_selectedProjectData.layer_main_rules[layer_key]['compile_str_date'];
                          }
                          if (this.d_selectedProjectData.layer_main_rules[layer_key]['compile_str_text']) {
                            this.d_mainRule.compile_str_text = this.d_selectedProjectData.layer_main_rules[layer_key]['compile_str_text'];
                          }
                          this.d_mainRule.query_list = this.d_selectedProjectData.layer_main_rules[layer_key]['query_list'];
                        }
                      }
                      this.d_mainRuleShowModal = true;
                    }
                  } else {
                    console.log('error: ', resp.data.message);
                    alert('Error: ' + resp.data.message);
                  }
                });
            } else if (layer_region === 'layer_tree') {
              let layer_key = this.d_selectedProjectData.data.general.layer.val.value;
              let data = {};
              PoleStarService.layer_tree_list_get(data)
                .then(resp => {
                  if (resp.data.status === "success") {
                    // console.log(resp.data);
                    // if (resp.data.layer_regions.length > 0) {}
                    // if (resp.data.layer_tree_list.length > 0) {}

                    // her bir standart katman ağacı için ayrı kontrol yapıyoruz. Zaten bu ağaçlar standart.
                    if (layer_tree_name === 'hospital_patient') {
                      if (resp.data.hospital_patient.length > 0) {
                        this.d_mainRule = JSON.parse(JSON.stringify(this.d_prepareRuleNew));
                        this.d_mainRule.layer_key = layer_key;
                        this.d_mainRule.wdm_list = [];
                        for (let i in resp.data.hospital_patient) {
                          let new_wdm_item = resp.data.hospital_patient[i];
                          // type değişkeni komponent içinde querynin type.valuesi için kullanılıyor.
                          new_wdm_item.type = new_wdm_item.value;
                          this.d_mainRule.wdm_list.push(new_wdm_item);
                        }

                        // daha önceden kayıtlı layer_main_rules varsa bunu kullanıyoruz.
                        if (this.d_selectedProjectData.layer_main_rules) {
                          if (this.d_selectedProjectData.layer_main_rules[layer_key]) {
                            this.d_mainRule.compile_str_array = this.d_selectedProjectData.layer_main_rules[layer_key]['compile_str_array'];
                            if (this.d_selectedProjectData.layer_main_rules[layer_key]['compile_str_date']) {
                              this.d_mainRule.compile_str_date = this.d_selectedProjectData.layer_main_rules[layer_key]['compile_str_date'];
                            }
                            if (this.d_selectedProjectData.layer_main_rules[layer_key]['compile_str_text']) {
                              this.d_mainRule.compile_str_text = this.d_selectedProjectData.layer_main_rules[layer_key]['compile_str_text'];
                            }
                            this.d_mainRule.query_list = this.d_selectedProjectData.layer_main_rules[layer_key]['query_list'];
                          }
                        }
                        this.d_mainRuleShowModal = true;
                      }
                    }



                  }
                });
            }
          } else {
            let msg = 'Ana algoritma kuralı sadece proje veri katmanı bağlantısı sağlandığında kullanılabilmektedir.';
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Proje ana algoritma kuralı oluşturma işlemi hakkında' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          }
      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_motherChildren: function (op, project_data = {}) {
      if (op === 'update_project_data') {
        this.mother_children.d_selectedProjectData = project_data;
      }
      if (this.mother_children[op]) {
        this.mother_children[op] = 0;
      } else {
        this.mother_children[op] = 1;
      }
    },
    f_selectCell: function (row_ind, col_ind, type) {
      let cell_row_col = row_ind.toString() + '_' + col_ind.toString();
      if (this.d_editCell && row_ind !== '' && col_ind !== '' && cell_row_col !== this.d_editCell) {
        this.f_cancelEditCell();
      }
      let real_row_ind = row_ind + this.d_datasetPagination.start;
      if (this.d_dataset.menu && this.d_dataset.menu.data.cell_selection_mode && this.d_dataset.menu.data.cell_selection_mode.status === 'one_cell_select_mode') {
        this.d_selected_cells = [];
      }
      if (type === 'cell') {
        let select = real_row_ind.toString() + '-' + col_ind.toString();
        if (this.d_selected_cells.indexOf(select) === -1) {
          this.d_selected_cells.push(select);
        } else {
          this.d_selected_cells.splice(this.d_selected_cells.indexOf(select), 1);
        }
      } else if (type === 'row') {
        for (let c in this.d_dataset.case_rows[0]) {
          let y = real_row_ind.toString() + '-' + c.toString();
          this.d_selected_cells.push(y);
        }
      } else if (type === 'col') {
        for (let r = 0; r < this.d_dataset.total_cases; r++) {
          let y = r.toString() + '-' + col_ind.toString();
          this.d_selected_cells.push(y);
        }
      }
      this.$forceUpdate();
    },
    f_getAndShowThisWdmr: function (id, wdm_type, buck) {
      let key = wdm_type + '_' + id;
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Lütfen bekleyiniz.' } });
      GlobalService.get_one_cb_document({ 'key': key, 'bucket': buck })
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_wisdomData.data_type = wdm_type;
            this.d_wisdomData.wisdom_data = resp.data.result;
            this.d_wisdomData.show_data = true;
            this.$forceUpdate();
          } else {
            alert('error', resp.data.message);
          }
          this.$store.commit('Loading', { 'status': false, 'data': {} });
        });
    },
    f_sendRowSmsList: function (type) {
      if (this.user && this.user.permissions_result && this.user.permissions_result.indexOf('sms_send-sms') !== -1) {
        let sms_op_ind = this.d_smsOperationData.selected;
        if (!this.d_smsOperationData.selected_sms_provider.key) {
          let modal_data = { 'type': 'alert', 'text': 'Lütfen sms sağlayıcınızı seçiniz.', 'centered': true, 'title': 'Sms gönderme işlemi hakkında' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
          return;
        }
        let data = {
          'list': [],
          'selected_sms_provider': this.d_smsOperationData.selected_sms_provider,
          'wdm3_id': this.d_selectedProjectData.id
        };
        if (type === 'selected') {
          if (!this.d_smsOperationData.list[sms_op_ind].message) {
            let modal_data = { 'type': 'alert', 'text': 'Lütfen mesajınızı yazınız', 'centered': true, 'title': 'Sms gönderme işlemi hakkında' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
            return;
          }
          data.list.push(this.d_smsOperationData.list[sms_op_ind]);
        } else { // all
          for (let i in this.d_smsOperationData.list) {
            if (!this.d_smsOperationData.list[i].message) {
              let modal_data = { 'type': 'alert', 'text': 'Lütfen mesajınızı yazınız', 'centered': true, 'title': 'Sms gönderme işlemi hakkında' };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
              return;
            }
            data.list.push(this.d_smsOperationData.list[i]);
          }
        }
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Sms gönderme işlemi gerçekleştiriliyor. Lütfen bekleyiniz.' } });
        SmsService.send_row_sms_list(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            let msg = '';
            if (resp.data.status === 'success') {
              this.f_getCases();
              // console.log(resp.data.result);
            } else {
              msg = resp.data.message;
              let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Sms gönderme işlemi hakkında' };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
            }
          });
      } else {
        let msg = 'Sms gönderim işlemi yapabilmeniz için sms gönderim yetkisi almanız gerekmektedir.';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Sms gönderim işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_sendSmsToThisRow: function (row_ind, col_ind = '', sms_type = 'manuel') {
      // console.log('row_ind: ', row_ind);
      // console.log('col_ind: ', col_ind);
      // console.log('sms_type: ', sms_type);
      if (this.user && this.user.permissions_result && this.user.permissions_result.indexOf('sms_send-sms') !== -1) {
        this.d_smsOperationData.selected_sms_provider = { 'label': '', 'key': '' };
        let sms_op = {
          'column_label': this.column_list[col_ind].label,
          'sms_operation_column_id': this.column_list[col_ind].id,
          'sms_operation_column_index': col_ind,
          'sms_type': sms_type,
          'name_usage': 'none',
          'sms_phone_list': []
        }
        sms_op.operation_no = '';
        if (col_ind !== '' && this.column_list[col_ind].operation_no !== undefined) {
          sms_op.operation_no = this.column_list[col_ind].operation_no;
        }
        sms_op.dmp_id = '';
        if (col_ind !== '' && this.column_list[col_ind].wdmr24_id !== undefined) {
          sms_op.dmp_id = this.column_list[col_ind].wdmr24_id;
        }
        sms_op.rule_id = '';
        if (col_ind !== '' && this.column_list[col_ind].rule_id !== undefined) {
          sms_op.rule_id = this.column_list[col_ind].rule_id;
        }
        sms_op.message = '';
        if (col_ind !== '' && this.column_list[col_ind].sms_message !== undefined) {
          sms_op.message = this.column_list[col_ind].sms_message;
        }
        let sms_phone_column_ind = '';
        let person_id_column_ind = '';
        let person_name_column_ind = '';
        let person_firstname_column_ind = '';
        let person_lastname_column_ind = '';
        for (let i in this.column_list) {
          if (this.column_list[i].sms_phone) {
            sms_phone_column_ind = i;
          }
          if (this.column_list[i].person_id) {
            person_id_column_ind = i;
          }
          if (this.column_list[i].person_name) {
            person_name_column_ind = i;
          }
          if (this.column_list[i].person_firstname) {
            person_firstname_column_ind = i;
          }
          if (this.column_list[i].person_lastname) {
            person_lastname_column_ind = i;
          }
        }
        if (sms_phone_column_ind === '') {
          alert('Lütfen sms telefonu kolonu tanımlayınız');
        } else {
          if (!this.d_dataset.case_rows[row_ind][sms_phone_column_ind]) {
            alert('Lütfen sms gönderim işlemi için bir telefon nosu giriniz');
          } else {
            let person_data = {
              'no': this.d_dataset.case_id_list[row_ind].no,
              'dataset_key': this.d_dataset.case_id_list[row_ind].dataset_key,
              'sms_phone': this.d_dataset.case_rows[row_ind][sms_phone_column_ind],
              'id': person_id_column_ind === '' ? '' : this.d_dataset.case_rows[row_ind][person_id_column_ind],
              'name': person_name_column_ind === '' ? '' : this.d_dataset.case_rows[row_ind][person_name_column_ind],
              'firstname': person_firstname_column_ind === '' ? '' : this.d_dataset.case_rows[row_ind][person_firstname_column_ind],
              'lastname': person_lastname_column_ind === '' ? '' : this.d_dataset.case_rows[row_ind][person_lastname_column_ind]
            };
            sms_op.sms_phone_list.push(person_data);
            this.d_smsOperationData.show = true;
            this.d_smsOperationData.list.push(JSON.parse(JSON.stringify(sms_op)));
            this.d_smsOperationData.selected = 0;
          }
        }
      } else {
        let msg = 'Sms gönderim işlemi yapabilmeniz için sms gönderim yetkisi almanız gerekmektedir.';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Sms gönderim işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_analyzeAlgorithmAndSendResultWithApi: function (col_ind, col_type, response_type) {
      if (this.d_userPolestarPermissions.indexOf('owner') !== -1 || this.d_userPolestarPermissions.indexOf('admin') !== -1 || this.d_userPolestarPermissions.indexOf('can_connect_project_to_dmp') !== -1) {
        let data = {
          'col_type': col_type, // selected or all
          'wdm3_id': this.d_selectedProjectData.id,
          'column_id_list': [],
          'response_type': response_type
        };
        if (col_type === 'selected') {
          data.column_id_list.push(this.column_list[col_ind].id);
        } else if (col_type === 'all') {
          for (let col_index in this.column_list) {
            if (this.column_list[col_index].rule_id !== undefined) {
              data.column_id_list.push(this.column_list[col_index].id);
            }
          }
        }
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Algoritma analiz ve api gönderim işlemi başlatılıyor. Lütfen bekleyiniz.' } });
        DecisionMakingService.analyze_algorithm_and_send_result_with_api(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            let msg = '';
            if (resp.data.status === 'success') {
              this.f_getCases();
            } else {
              msg = resp.data.message;
              let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Karar Destek Paketi analiz işlemi hakkında' };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
            }
          });
      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }

    },
    f_analyzeAllDatasetByDmpSmsOperation: function (col_ind, type, col_type) {
      if (this.user && this.user.permissions_result && this.user.permissions_result.indexOf('polestar_wisdomstats_analyze-decision-making-package') !== -1) {
        let data = {
          'col_type': col_type, // selected or all
          'type': type, // first_show_table, perform_sms_operation_when_rule_returns_true
          'wdm3_id': this.d_selectedProjectData.id,
          'dmp_sms_column_list': []
        };
        if (col_type === 'selected') {
          data.dmp_sms_column_list.push(this.column_list[col_ind].id);
        }
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Tüm sms karar destek paketi kolonları analiz ediliyor. Lütfen bekleyiniz.' } });
        DecisionMakingService.analyze_all_dataset_by_dmp_sms_operation(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            let msg = '';
            if (resp.data.status === 'success') {
              this.d_smsOperationData.list = [];
              for (let i in resp.data.result.sms_operation_data) {
                this.d_smsOperationData.list.push(resp.data.result.sms_operation_data[i]);
              }
              if (this.d_smsOperationData.list.length > 0) {
                this.d_smsOperationData.selected = 0;
                this.d_smsOperationData.show = true;
              } else {
                if (type === 'first_show_table') {
                  msg = 'Sms Gönderme Kolonu tespit edilemedi';
                  let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Sms gönderim işlemi hakkında' };
                  this.$store.commit('modal', modal_data);
                  this.$store.commit('modal_show', true);
                } else {
                  msg = 'İşlem tamamlandı';
                  let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Tüm projeyi analiz ve sms gönderim işlemi hakkında' };
                  this.$store.commit('modal', modal_data);
                  this.$store.commit('modal_show', true);
                }
              }
            } else {
              msg = resp.data.message;
              let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Karar Destek Paketi analiz işlemi hakkında' };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
            }
          });
      } else {
        let msg = 'Karar destek paketiyle proje analizi yapabilmeniz için yetki almanız gerekmektedir.';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Karar destek paketi proje işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_analyzeThisPaginationRowsByThisDecisionMakingPackage: function (dmp_id) {
      if (this.user && this.user.permissions_result && this.user.permissions_result.indexOf('polestar_wisdomstats_analyze-decision-making-package') !== -1) {
        let data = {
          'wdmr24_id': dmp_id,
          'wdm3_id': this.d_selectedProjectData.id,
          'row_list': []
        };
        for (let i in this.d_dataset.case_id_list) {
          let row_data = {
            'row_no': this.d_dataset.case_id_list[i].no,
            'dataset_key': this.d_dataset.case_id_list[i].dataset_key
          };
          data.row_list.push(row_data);
        }
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Karar Destek Paketiyle bu sayfadaki tüm satırlar analiz yapılıyor. Lütfen bekleyiniz.' } });
        DecisionMakingService.analyze_dataset_rows_with_dmp(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            let msg = '';
            if (resp.data.status === 'success') {
              this.d_rowDmpAnalyzeResults = resp.data.result;
              this.d_rowDmpAnalyzeResults.columns = [];
              for (let r in this.d_rowDmpAnalyzeResults.rows) {
                for (let dmp_id in this.d_rowDmpAnalyzeResults.rows[r].result.dmp) {
                  for (let t in this.d_rowDmpAnalyzeResults.rows[r].result.dmp[dmp_id].true_rules) {
                    let true_rule = this.d_rowDmpAnalyzeResults.rows[r].result.dmp[dmp_id].true_rules[t];
                    let rule_id = true_rule.id;
                    // op['operation_info']['val'] + '[' + self.arguments['wdmr24_id'] + '-' + self.arguments['rule_id'] + '-' + op['operation_no']['val'] + ']'
                    if (true_rule.operation_no && true_rule.operation_no.val && true_rule.operation_no.val !== '') {
                      let true_rule_result_msg = true_rule.message + '[' + dmp_id + '-' + rule_id + '-' + true_rule.operation_no.val + ']';
                      if (this.d_rowDmpAnalyzeResults.columns.indexOf(true_rule_result_msg) === -1) {
                        this.d_rowDmpAnalyzeResults.columns.push(true_rule_result_msg);
                      }
                    } else {
                      let true_rule_result_msg = true_rule.message + '[' + dmp_id + '-' + rule_id + ']';
                      if (this.d_rowDmpAnalyzeResults.columns.indexOf(true_rule_result_msg) === -1) {
                        this.d_rowDmpAnalyzeResults.columns.push(true_rule_result_msg);
                      }
                    }
                  }
                }
              }
              msg = 'Analiz işlemleri tamamlandı.';
              this.$forceUpdate();
            } else {
              msg = resp.data.message;
              let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Karar Destek Paketi analiz işlemi hakkında' };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
            }
          });
      } else {
        let msg = 'Karar destek paketiyle proje analizi yapabilmeniz için yetki almanız gerekmektedir.';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Karar destek paketi proje işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_thisRuleHasTrueResult: function (rule_column_name, row_index) {
      let case_id_data = this.d_dataset.case_id_list[row_index];
      let row_no = case_id_data['no'];
      if (this.d_rowDmpAnalyzeResults.rows[row_no]) {
        for (let dmp_id in this.d_rowDmpAnalyzeResults.rows[row_no].result.dmp) {
          for (let t in this.d_rowDmpAnalyzeResults.rows[row_no].result.dmp[dmp_id].true_rules) {
            let true_rule = this.d_rowDmpAnalyzeResults.rows[row_no].result.dmp[dmp_id].true_rules[t];
            let rule_id = true_rule.id;
            if (true_rule.operation_no && true_rule.operation_no.val && true_rule.operation_no.val !== '') {
              let true_rule_result_msg = true_rule.message + '[' + dmp_id + '-' + rule_id + '-' + true_rule.operation_no.val + ']';
              if (rule_column_name === true_rule_result_msg) {
                return true;
              }
            } else {
              let true_rule_result_msg = true_rule.message + '[' + dmp_id + '-' + rule_id + ']';
              if (rule_column_name === true_rule_result_msg) {
                return true;
              }

            }
          }
        }
      }
      return false;
    },
    f_savePolestarDmpToWai: function () {
      let data = {
        'wdmr24': this.d_wisdomData.wisdom_data,
        'wdm3_id': this.d_selectedProjectData.id
      };

      data.permission_control = { 'value': 'decision-making-system_create-dmp', 'i_used_this_permission': 1 };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kayıt İşlemi Yapılıyor. Lütfen bekleyiniz.' } });
      DecisionMakingService.save_polestar_dmp_to_wai(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          let msg = '';
          if (resp.data.status === 'success') {
            if (resp.data.result.status === 'success') {
              msg = 'Kayıt işlemi tamamlandı.';
              this.d_selectedProjectData.data.dmp = resp.data.result.project_dmp_list;
              this.d_wisdomData.show = false;
              this.$forceUpdate();
            } else {
              msg = resp.data.message;
              let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Karar Destek Paketi kayıt işlemi hakkında' };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
            }
          } else {
            msg = resp.data.message;
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Karar Destek Paketi kayıt işlemi hakkında' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          }
        });
    },
    f_saveChildWdm: function () {
      let parent_wdm = '';
      let child_wdm = '';
      let relation_type = 'keys';
      /* 
        We dont use this function for wdmr24 saving. If we find a new data type we will change below code.
      */
      if (this.d_wisdomData.data_type === 'wdm24') {
        parent_wdm = {
          'label': '',
          'key': 'wdm3_' + this.d_selectedProjectData.id,
          'bucket': 'polestar',
          'type': 'wdm3'
        };
        child_wdm = {
          'label': '',
          'key': 'wdm_wisdom_wdm24_v2.0',
          'bucket': 'polestar',
          'type': 'wdm24'
        };
        relation_type = 'keys';
      }
      ClsWdmrManage.save_child_wdmr(this.d_wisdomData, parent_wdm, child_wdm, relation_type)
        .then(resp => {
          // console.log(resp);
          if (this.d_wisdomData.data_type === 'wdm24') {
            // this.d_selectedWdmrData = resp.wdmr;
            // this.wisdom_show_update();
            this.f_getWdmr24List();
          }
          alert('Successfully recorded');
          this.d_wisdomData.show = false;
        }, resp => {
          alert('error ', resp);
        });
    },
    f_editChildWdmr: function (data_type) {
      if (this.d_userPolestarPermissions.indexOf('owner') !== -1 || this.d_userPolestarPermissions.indexOf('admin') !== -1 || this.d_userPolestarPermissions.indexOf('project_column_add_edit') !== -1) {
        this.d_wisdomData.wisdom_op = "edit";
        this.d_wisdomData.data_type = data_type;
        if (data_type === 'wdm24') {
          this.d_wisdomData.wisdom_data = JSON.parse(JSON.stringify(this.d_selectedWdmr24Data));
        }
        this.d_wisdomData.show = true;
      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_selectWdmr24: function (wdmr24_id) {
      if (this.d_userPolestarPermissions.indexOf('owner') !== -1 || this.d_userPolestarPermissions.indexOf('admin') !== -1 || this.d_userPolestarPermissions.indexOf('project_column_add_edit') !== -1) {
        this.d_selectedWdmr24Id = wdmr24_id;
        let data = {
          'wdmr24_id': wdmr24_id
        }
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Karar Destek Paketi getiriliyor. Lütfen bekleyiniz.' } });
        DecisionMakingService.get_polestar_dmp_from_wai(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            let msg = '';
            if (resp.data.status === 'success') {
              if (resp.data.result.status === 'success') {
                this.d_selectedWdmr24Data = resp.data.result.result.wdmr24;
                this.f_wisdomDataShowChange();
              } else {
                msg = resp.data.message;
                let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Karar Destek Paketi kayıt işlemi hakkında' };
                this.$store.commit('modal', modal_data);
                this.$store.commit('modal_show', true);
              }
            } else {
              msg = resp.data.message;
              let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Karar Destek Paketi kayıt işlemi hakkında' };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
            }
          });
      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_addNewChildWisdomData: function (data_type) {
      if (this.d_userPolestarPermissions.indexOf('owner') !== -1 || this.d_userPolestarPermissions.indexOf('admin') !== -1 || this.d_userPolestarPermissions.indexOf('can_connect_project_to_dmp') !== -1) {
        ClsWdmrManage.prepare_new_wisdom_data(this.d_wisdomData, data_type)
        if (data_type === 'wdm24') {
          this.d_wisdomData.wisdom_data.data.general.package_type = {
            'val': {
              'label': 'polestar projesi',
              'value': 'polestar_project'
            }
          };
          this.d_wisdomData.wisdom_data.data.general.polestar_project_id = {
            'val': this.d_selectedProjectData.id
          };
        }
        this.d_wisdomData.show = true;
      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_getWdmr24List: function () {
      this.d_wdmr24List = [];
      let parent_wdm = {
        'label': '',
        'key': 'wdm3_' + this.d_selectedProjectData.id,
        'bucket': 'polestar',
        'type': 'wdm3'
      };
      let child_wdm = {
        'label': '',
        'bucket': 'polestar',
        'type': 'wdm24'
      };
      let relation_type = 'keys';
      ClsWdmrManage.get_related_wdmr_list(parent_wdm, child_wdm, relation_type)
        .then(resp => {
          if (resp && resp.list) {
            this.d_wdmr24List = resp.list;
          }
        }, resp => {
          console.log('error ', resp);
        });
    },
    f_searchTextAnalyzeInspection: function () {
      let data = {
        'col_type': 'all',
        'col_ind': '',
        'search_text_list': [],
        'wdm3_id': this.d_selectedProjectData.id
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'İfade arama işlemleri gerçekleştiriliyor. Lütfen bekleyiniz.' } });
      PoleStarService.search_text_analyze_inspection(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          let msg = '';
          if (resp.data.status === 'success') {
            msg = 'Analiz işlemleri tamamlandı';
            this.f_getCases();
            // this.p_selectedProjectData.design.column_list = resp.data.result.column_list;
            // this.column_list.splice(0, this.column_list.length);
          } else {
            msg = resp.data.message;
          }
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İfade analiz işlemleri hakkında' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        });
    },
    f_updateCellsByHimsInspectionMatch: function (col_type, col_ind = '', cell_type) {
      let data = {
        'col_type': col_type,
        'col_ind': col_ind,
        'wdm3_id': this.d_selectedProjectData.id,
        'cell_type': cell_type
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'İfade seçenek otomatik eşleştirme işlemleri gerçekleştiriliyor. Lütfen bekleyiniz.' } });
      PoleStarService.make_auto_matching_for_hims_options(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          let msg = '';
          if (resp.data.status === 'success') {
            msg = 'Analiz işlemleri tamamlandı';
            this.f_getCases();
            // this.p_selectedProjectData.design.column_list = resp.data.result.column_list;
            // this.column_list.splice(0, this.column_list.length);
          } else {
            msg = resp.data.message;
          }
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İfade analiz işlemleri hakkında' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        });
    },
    f_analyzeHimsInspectionEligibility: function () {
      this.d_eligibleForHimsInspectionMatch = false;
      for (let i in this.column_list) {
        if (this.column_list[i].options) {
          for (let op_ind in this.column_list[i].options) {
            if (this.column_list[i].options[op_ind].any_of_this_regx_in_hims_inspection_data && this.column_list[i].options[op_ind].any_of_this_regx_in_hims_inspection_data.length > 0) {
              this.d_eligibleForHimsInspectionMatch = true;
              break;
            }
          }
        }
        if (this.d_eligibleForHimsInspectionMatch) {
          break;
        }
      }
    },
    f_analyzeWdmrRuleEligibility: function () {
      this.d_eligibleForWdmrRuleMatch = false;
      for (let i in this.column_list) {
        if (this.column_list[i].options) {
          for (let op_ind in this.column_list[i].options) {
            if (this.column_list[i].options[op_ind].any_of_this_regx_in_wdmr_rule_data && this.column_list[i].options[op_ind].any_of_this_regx_in_wdmr_rule_data.length > 0) {
              this.d_eligibleForWdmrRuleMatch = true;
              break;
            }
          }
        }
        if (this.d_eligibleForWdmrRuleMatch) {
          break;
        }
      }
    },
    f_analyzeColumnOptionMixedWdmrRuleEligibility: function () {
      this.d_eligibleForColumnOptionMixedWdmrRuleMatch = false;
      for (let i in this.column_list) {
        if (this.column_list[i].options) {
          for (let op_ind in this.column_list[i].options) {
            let wdmr_rule = false;
            if (this.column_list[i].options[op_ind].any_of_this_regx_in_wdmr_rule_data && this.column_list[i].options[op_ind].any_of_this_regx_in_wdmr_rule_data.length > 0) {
              wdmr_rule = true;
            }
            let opt_rule = false;
            if (this.column_list[i].options[op_ind].rule && this.column_list[i].options[op_ind].rule.query_list && this.column_list[i].options[op_ind].rule.query_list.length > 0) {
              opt_rule = true;
            }
            if (wdmr_rule && opt_rule) {
              this.d_eligibleForColumnOptionMixedWdmrRuleMatch = true;
              break;
            }
          }
        }
        if (this.d_eligibleForColumnOptionMixedWdmrRuleMatch) {
          break;
        }
      }
    },
    f_analyzeHimsInspectionSearchEligibility: function () {
      this.d_eligibleForHimsInspectionSearch = false;
      for (let i in this.column_list) {
        if (this.column_list[i].search_text_list && this.column_list[i].search_text_list.length > 0) {
          this.d_eligibleForHimsInspectionSearch = true;
          break;
        }
      }
    },
    f_analyzeWdmrRuleSearchEligibility: function () {
      this.d_eligibleForWmdrRuleSearch = false;
      for (let i in this.column_list) {
        if (this.column_list[i].search_text_list && this.column_list[i].search_text_list.length > 0) {
          this.d_eligibleForWmdrRuleSearch = true;
          break;
        }
      }
    },
    f_analyzePatientInspectionData: function (patient_id, row_ind, col_ind, type_list) {
      // console.log(row_ind);
      // console.log(col_ind);
      // type_list = type_list.split(';')
      this.d_iframeHimsProtocolDetails.url = this.PATIENT_APP_FRONTEND_URL + '#/if/himsprotocoldetails?patient_id=' + patient_id;
      this.d_iframeHimsProtocolDetails.url += '&status=closed';
      if (type_list !== 'go_to_hims_inspection') {
        this.d_iframeHimsProtocolDetails.url += '&type_list=' + type_list;
        let txt_list = [];
        if (this.column_list[col_ind].search_text_list) {
          for (let i in this.column_list[col_ind].search_text_list) {
            txt_list.push(this.column_list[col_ind].search_text_list[i]);
          }
        } else {
          txt_list.push(this.column_list[col_ind].label);
        }
        if (txt_list.length > 0) {
          this.d_iframeHimsProtocolDetails.url += '&txt_list=' + txt_list.join(';');
        }
      }
      this.d_showHimsProtocols = true;
    },
    f_analyzePatientInspectionDataOld: function (patient_id, row_ind, col_ind, type_list) {
      // console.log(row_ind);
      // console.log(col_ind);
      type_list = type_list.split(';')
        /*
        type_list = ['care_plan', 'symptom']
        */
      let inspection_rule_data = {
        'query_list': [],
        'compile_str_array': [],
        'compile_str_text': [],
        'compile_str_date': []
      };
      if (type_list.length > 0) {
        let txt_list = [];
        if (this.column_list[col_ind].search_text_list) {
          for (let i in this.column_list[col_ind].search_text_list) {
            txt_list.push(this.column_list[col_ind].search_text_list[i]);
          }
        } else {
          txt_list.push(this.column_list[col_ind].label);
        }
        inspection_rule_data = {
          'query_list': [],
          'compile_str_array': [],
          'compile_str_date': [],
          'compile_str_text': []
        };
        for (let i in type_list) {
          for (let t in txt_list) {
            let txt = txt_list[t];
            let query = {
              "time_of_value": "any_value",
              "data_type": "old_recorded_data",
              "compile_str_array": ["(", 0, ")"],
              "fields": [],
              "compile_str": "",
              "significant": "true",
              "date": "1",
              "type": { "value": "inspection_info", "label": "Protokol muayene bilgileri" }
            };
            let f = {
              "loc": [{
                "label": "Detaylar",
                "value": "details",
                "type": "group"
              }, {
                "label": type_list[i].split(':')[0],
                "value": type_list[i].split(':')[1],
                "type": "text"
              }],
              "using_data_if_not_reaching_record_data": "true",
              "val_list": [txt],
              "values": [txt],
              "significant": "true",
              "value_type": "text",
              "operation": "in"
            };
            query.fields.push(f);
            inspection_rule_data.query_list.push(query);
          }
        }
        inspection_rule_data.compile_str_array.push('(');
        let list_count = inspection_rule_data.query_list.length;
        for (let i = 0; i < list_count; i++) {
          inspection_rule_data.compile_str_array.push(i);
          if (list_count - 1 !== i) { inspection_rule_data.compile_str_array.push('or'); }
        }
        inspection_rule_data.compile_str_array.push(')');

      }
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Analiz yapılıyor. Lütfen bekleyiniz.' } });
      PatientService.get_project_patient(patient_id)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            this.patient_data = resp.data.result;
            this.d_showHimsProtocols = true;
            this.d_inspectionRuleData = inspection_rule_data;
          } else {
            alert('Hasta kaydı hatası :', resp.data.message)
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          }
        });
    },
    f_trainMachineLearning: function () {
      let data = {
        'key': this.d_selectedWdm163_key,
        'wdm3_id': this.d_selectedProjectData.id
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Makine öğrenmesi modeli eğitim işleminiz gerçekleşiyor. Lütfen bekleyiniz.' } });
      MachineLearningService.train(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            this.d_selectedProjectData = resp.data.result.wdm3_data;
            this.d_selectedWdm163_data = resp.data.result.wdm163_data;
            this.$forceUpdate();
          } else {
            alert('error', resp.data.message);
          }
        });
    },
    f_deleteWdm163Data: function (key) {
      if (this.d_userPolestarPermissions.indexOf('owner') !== -1 || this.d_userPolestarPermissions.indexOf('admin') !== -1 || this.d_userPolestarPermissions.indexOf('project_add_edit_machine_learning_model') !== -1) {
        let modal_data = { 'type': 'confirm', 'text': 'Makine öğrenmesi modelini silmek istediğinize emin misiniz?', 'centered': true, 'title': 'Makine öğrenmesi modeli silme İşlemi hakkında', 'function_name': 'f_deleteWdm163DataConfirmed', 'confirm': false, 'arguments': [key] };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_deleteWdm163DataConfirmed: function (args) {
      let key = args[0];
      let data = {
        'key': key,
        'wdm3_id': this.d_selectedProjectData.id
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Silme işleminiz gerçekleşiyor. Lütfen bekleyiniz.' } });
      PoleStarService.delete_wdm163_data(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            this.d_selectedProjectData = resp.data.result.wdm3_data;
            if (this.d_selectedWdm163_key === key) {
              this.d_selectedWdm163_key = '';
              this.d_selectedWdm163_data = '';
            }
            this.$forceUpdate();
          } else {
            alert('error', resp.data.message);
          }
        });
    },
    f_editMachineLearning: function () {
      if (this.d_userPolestarPermissions.indexOf('owner') !== -1 || this.d_userPolestarPermissions.indexOf('admin') !== -1 || this.d_userPolestarPermissions.indexOf('project_add_edit_machine_learning_model') !== -1) {
        this.f_createWdm163ColList();
        this.d_machineLearningData.wisdom_data = JSON.parse(JSON.stringify(this.d_selectedWdm163_data));
        this.d_machineLearningData.show = true;
      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_selectWdm163: function (key) {
      this.d_selectedWdm163_key = key;
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Lütfen bekleyiniz.' } });
      GlobalService.get_one_cb_document({ 'key': key, 'bucket': 'polestar' })
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_selectedWdm163_data = resp.data.result;
            this.f_wisdomDataShowChange();
            if (!this.d_selectedWdm163_data.analyze_result || (this.d_selectedWdm163_data.analyze_result && this.d_selectedWdm163_data.analyze_result.length === 0)) {
              this.d_navMachineLearningDetails = 'ml_model_details';
            }
            this.$forceUpdate();
          } else {
            alert('error', resp.data.message);
          }
          this.$store.commit('Loading', { 'status': false, 'data': {} });
        });
    },
    f_createNewMachineLearning: function () {
      if (this.d_userPolestarPermissions.indexOf('owner') !== -1 || this.d_userPolestarPermissions.indexOf('admin') !== -1 || this.d_userPolestarPermissions.indexOf('project_add_edit_machine_learning_model') !== -1) {
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Makine öğrenmesi modeliniz hazırlanıyor. Lütfen bekleyiniz.' } });
        setTimeout(function () {
          this.f_prepareWdm163();
        }.bind(this), 500)
      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_prepareWdm163: function () {
      let pre_data = {
        'id': 'new',
        'data': {},
        'type': 'wdm163',
        'reference_wdm': {},
        'wdm3_id': this.d_selectedProjectData.id
      };
      pre_data['reference_wdm']['version'] = this.option_data['wdm163']['version'];
      pre_data['reference_wdm']['owner_type'] = this.option_data['wdm163']['owner_type'];
      if (this.option_data['wdm163']['owner_type'] === 'wisdom') {
        pre_data['reference_wdm']['key'] = 'wdm_wisdom_wdm163_v' + this.option_data['wdm163'].version;
      }
      for (let i in this.option_data['wdm163'].param_group.param_order) {
        pre_data['data'][this.option_data['wdm163'].param_group.param_order[i]] = {};
      }
      pre_data['data']['general']['wdm3_name'] = { 'val': this.d_selectedProjectData.data.general.name.val };
      pre_data['data']['general']['wdm3_key'] = { 'val': 'wdm3_' + this.d_selectedProjectData.id };
      this.f_createWdm163ColList();
      this.d_machineLearningData.wisdom_data = pre_data;
      this.d_machineLearningData.show = true;
      this.$store.commit('Loading', { 'status': false, 'data': {} });
    },
    f_createWdm163ColList: function () {
      let col_list = [];
      for (let i in this.column_list) {
        let lbl = this.column_list[i].label + '( no:' + i + ' ) -' + this.column_list[i].param_type + ' -';
        // We took the column index in value. So when changing we can use value field.
        let x = {
          'label': lbl,
          'value': i.toString(),
          'param_type': this.column_list[i].param_type
        }
        col_list.push(x);
      }
      this.option_data['wdm163'].general.parameters.input_variables.options = col_list;
      this.option_data['wdm163'].general.parameters.numeric_output_variable.options = col_list;
      this.option_data['wdm163'].general.parameters.categorical_output_variable.options = col_list;
    },
    f_goToThisProject: function (project_id) {
      // this.d_selectedProjectData = '';
      // this.column_list = [];
      // this.d_projectWdmList = [];
      let wdm3_id = project_id;
      PoleStarService.get_this_project({ 'wdm3_id': wdm3_id })
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_selectedProjectData = resp.data.result;
            this.d_project_id = this.d_selectedProjectData.id;
            if (this.d_selectedProjectData.design) {
              this.column_list = this.d_selectedProjectData.design.column_list;
            }
            this.d_userPolestarPermissions = ClsPolestar.f_createUserPermissions(this.d_selectedProjectData, this.user.username);
            if (this.user.permissions_result.indexOf('admin') !== -1 || this.user.permissions_result.indexOf('wisdom') !== -1) {
              this.d_userPolestarPermissions.push('admin');
            }
            this.d_polestarSettings = ClsPolestar.f_getProjectSettings(this.d_selectedProjectData, this.user.username);
            this.f_sendProjectInfoTosocket();
            this.d_projectWdmList = ClsPolestar.f_createProjectWdmList(this.d_selectedProjectData);
            this.f_controlIsEligible();
            this.f_wisdomDataShowChange();
            this.f_getCases();
            // if (this.d_timerControlProjectUpdate.status !== 'active') {
            //   this.f_getCases();
            // }
            this.f_controlAndCreateTimer();
          } else {
            alert('error', resp.data.message);
          }
        });
    },
    f_controlAndCreateTimer: function () {
      let data_updating_status = '';
      let data_option_algorithm_status = '';
      let algorithm_analyzing_status = '';
      let data_wdmr_algorithm_status = '';
      let data_wdmr_option_algorithm_status = '';
      try {
        data_updating_status = this.d_selectedProjectData.data.general.data_updating_status.val.value;
      } catch (err) {}
      try {
        data_option_algorithm_status = this.d_selectedProjectData.data.general.data_option_algorithm_status.val.value;
      } catch (err) {}
      try {
        algorithm_analyzing_status = this.d_selectedProjectData.data.general.algorithm_analyzing_status.val.value;
      } catch (err) {}
      try {
        data_wdmr_algorithm_status = this.d_selectedProjectData.data.general.data_wdmr_algorithm_status.val.value;
      } catch (err) {}
      try {
        data_wdmr_option_algorithm_status = this.d_selectedProjectData.data.general.data_wdmr_option_algorithm_status.val.value;
      } catch (err) {}
      if (data_updating_status === 'continuing' || data_option_algorithm_status === 'continuing' || algorithm_analyzing_status === 'continuing' || data_wdmr_algorithm_status === 'continuing' || data_wdmr_option_algorithm_status === 'continuing') {
        this.d_timerControlProjectUpdate.status = 'active';
        this.d_timerControlProjectUpdate.interval += 5000;
        if (this.d_timerControlProjectUpdate.interval > this.d_timerControlProjectUpdate.max) {
          this.d_timerControlProjectUpdate.interval = this.d_timerControlProjectUpdate.max;
        }
        // console.log('this.d_timerControlProjectUpdate.interval : ', this.d_timerControlProjectUpdate.interval);
        setTimeout(function () {
          this.f_goToThisProject(this.d_project_id);
        }.bind(this), this.d_timerControlProjectUpdate.interval)
        if (this.d_timerControlProjectUpdate.status !== 'active') {
          // this.d_timerControlProjectUpdate.status = 'active';
          // this.d_timerControlProjectUpdate.timer = setInterval(function () {
          //   // console.log("this.d_project_id ", this.d_project_id);
          //   this.f_goToThisProject(this.d_project_id);
          // }.bind(this), this.d_timerControlProjectUpdate.interval);
        }
      } else {
        // Proje devam ediyor modunda olmasa bile projeyi başlatan algoritma analizi ve benzeri çalışmalar için kontrol etmeye biraz daha devam ediyoruz.
        // check_count_after_not_continue değeri burada kontrol ediliyor. Hedeflenen bir adede kadar kontrol ediyor.
        // 5 saniyede bir iki kez daha kontrol yapılıyor ve pasife alınıyor.
        // console.log('this.d_timerControlProjectUpdate.check_count_after_not_continue: ', this.d_timerControlProjectUpdate.check_count_after_not_continue);
        if (this.d_timerControlProjectUpdate.status === 'active') {
          if (this.d_timerControlProjectUpdate.check_count_after_not_continue >= 2) {
            this.d_timerControlProjectUpdate.timer = 0;
            this.d_timerControlProjectUpdate.interval = 0;
            this.d_timerControlProjectUpdate.check_count_after_not_continue = 0;
            this.d_timerControlProjectUpdate.status = 'passive';
            // window.clearInterval(this.d_timerControlProjectUpdate.timer);
            this.f_goToThisProject(this.d_project_id);
          } else {
            this.d_timerControlProjectUpdate.check_count_after_not_continue += 1;
            // 5 saniye sonra yeniden projeyi kontrol ediyoruz.
            setTimeout(function () {
              this.f_goToThisProject(this.d_project_id);
            }.bind(this), 5000)
          }
        }
      }
    },
    f_controlIsEligible: function () {
      // console.log("this.d_selectedProjectData.data.general.layer : ", this.d_selectedProjectData.data.general.layer);
      if (this.d_selectedProjectData && this.d_selectedProjectData.data && this.d_selectedProjectData.data.general) {
        let project_data_type = '';
        try {
          project_data_type = this.d_selectedProjectData.data.general.project_data_type.val.value;
        } catch (err) {}
        if (project_data_type === 'layer') {
          this.d_eligibleForCaseSearch = true;
          if (!this.d_selectedProjectData.data.general.layer) {
            this.d_eligibleForCaseSearch = false;
          } else {
            if (this.column_list.length === 0) {
              this.d_eligibleForCaseSearch = false;
            } else {
              let wdm_det = false;
              for (let i in this.column_list) {
                if (this.column_list[i].type === 'wdm') {
                  wdm_det = true;
                  break;
                }
              }
              if (!wdm_det) {
                this.d_eligibleForCaseSearch = false;
              }
            }
          }
        } else if (project_data_type === 'mssql') {
          let sql_list = '';
          try {
            sql_list = this.d_selectedProjectData.data.general.sql_list.list;
          } catch (err) {}
          if (sql_list.length > 0) {
            this.d_eligibleForCaseSearch = true;
          }
        }
      }
    },
    f_saveWisdomData: function () {
      let return_data = ClsWdmrForever.turn(this.wisdom_data, this.option_data['wdm3'], ['name', 'delete']);
      let wdmr_name = return_data.name;
      if (wdmr_name) {
        this.wisdom_data.label = wdmr_name;
      }
      let wdmr_require_alerts = return_data.wdmr_require_alerts;
      let only_one_param_is_filled = return_data.only_one_param_is_filled;
      if (!only_one_param_is_filled) {
        let modal_data = {
          'type': 'alert',
          'text': 'Veri modelini kaydedebilmek için en az bir veriniz dolu olmalıdır.',
          'centered': true,
          'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.'
        };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      if (wdmr_require_alerts && wdmr_require_alerts.length > 0) {
        let require_txt = '';
        for (let r in wdmr_require_alerts) {
          require_txt += ' *' + wdmr_require_alerts[r].label;
        }
        let modal_data = {
          'type': 'alert',
          'text': require_txt,
          'centered': true,
          'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.'
        };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      let data = {};
      data = { 'data': this.wisdom_data };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'İşlem gerçekleştiriliyor. Lütfen bekleyiniz.' } });
      PoleStarService.save_polestar_project(data).then(resp => {
        this.$store.commit('Loading', { 'status': false, 'data': {} });
        if (resp.data.status === "success") {
          this.d_selectedProjectData = resp.data.result.project;
          this.show_wisdom_data_modal = false;
          this.f_showProjectDetailsResume();
          this.d_projectWdmList = ClsPolestar.f_createProjectWdmList(this.d_selectedProjectData);
          this.f_controlIsEligible();
        } else {
          let modal_data = { 'type': 'alert', 'text': resp.data.message, 'centered': true, 'title': this.$t('wdm16.13274') };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        }
        this.$forceUpdate();
      });
    },
    f_showProjectDetailsResume: function () {
      this.d_showProjectDetails = false;
      setTimeout(function () {
        this.d_showProjectDetails = true;
        this.$forceUpdate();
      }.bind(this), 500)
    },
    f_editProject: function () {
      if (this.d_userPolestarPermissions.indexOf('owner') !== -1 || this.d_userPolestarPermissions.indexOf('admin') !== -1 || this.d_userPolestarPermissions.indexOf('project_general_info_edit') !== -1) {
        if (this.option_data['wdm3']) {
          this.option_data['wdm3']['general']['parameters']['layer']['options'] = [];
          this.option_data['wdm3'].general.parameters.layer_tree_name.options = [];
          this.option_data['wdm3'].general.parameters.layer_location.parameters.layer_location_name.options = [];
          this.option_data['wdm3'].general.parameters.layer_location.parameters.location_servers.options = [];

          this.wisdom_op = "edit";
          this.wisdom_data = JSON.parse(JSON.stringify(this.d_selectedProjectData));
          // yeni grup tanımlandığında problem oluşturuyor. Dolayısıyla yeni bir grup tanımlandıysa wdmde onu önce edit modda da olsa oluşturuyoruz.
          // First we get all wdm246 layer wdm permissions to add wdm3 layer parameter.
          let data = { 'wdm22_id': this.d_wdmr22.id };
          this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Lütfen bekleyiniz.' } });
          LayerService.get_user_layer_list(data)
            .then(resp => {
              this.$store.commit('Loading', { 'status': false, 'data': {} });
              if (resp.data.status === 'success') {
                if (resp.data.result.wdm246_list && resp.data.result.wdm246_list.length > 0) {
                  this.option_data['wdm3']['general']['parameters']['layer']['op_if'] = '1';
                  for (let i in resp.data.result.wdm246_list) {
                    let new_item = resp.data.result.wdm246_list[i];
                    new_item["if"] = {
                        "friend": {
                            "layer_region": {
                                "operation": {"or_options": ["authorized_layers"] },
                                "parent_param": "yes",
                                "index_list": ["general", "layer_region"],
                                "name": {"value": "layer_region", "label": "Katman Bölgesi"}
                            }
                        }
                    }
                    this.option_data['wdm3']['general']['parameters']['layer']['options'].push(new_item);
                  }
                }
                let data = {};
                PoleStarService.layer_tree_list_get(data)
                  .then(resp => {
                    if (resp.data.status === "success") {
                      // console.log(resp.data);
                      // console.log(this.option_data['wdm3']);
                      if (resp.data.layer_tree_list.length > 0) {
                        this.option_data['wdm3']['general']['parameters']['layer_tree_name']['op_if'] = '1';
                        for (let i in resp.data.layer_tree_list) {
                          this.option_data['wdm3'].general.parameters.layer_tree_name.options.push(resp.data.layer_tree_list[i]);
                        }
                      }
                      if (resp.data.hospital_patient.length > 0) {
                        for (let i in resp.data.hospital_patient) {
                          let new_layer_item = resp.data.hospital_patient[i];
                          this.option_data['wdm3'].general.parameters.layer.options.push(new_layer_item);
                        }
                      }
                      this.option_data['wdm3'].general.parameters.layer_location.parameters.layer_location_name.op_if = '1';
                      this.option_data['wdm3'].general.parameters.layer_location.parameters.location_servers.op_if = '1';
                      let added_location_list = [];
                      let added_server_list = [];
                      for (let i in resp.data.layer_regions) {
                        if (added_location_list.indexOf(resp.data.layer_regions[i].location.value) === -1) {
                          let new_location_item = resp.data.layer_regions[i].location;
                          // console.log('resp.data.layer_regions[i].layer_tree.value: ', resp.data.layer_regions[i].layer_tree.value);
                          new_location_item["if"] = {
                              "friend": {
                                  "layer_tree_name": {
                                      "operation": {"or_options": [resp.data.layer_regions[i].layer_tree.value] },
                                      // "parent_param": "yes",
                                      "index_list": ["general", "layer_tree_name"],
                                      "name": {"value": "layer_tree_name", "label": "Katman Ağacı Adı"}
                                  }
                              }
                          }
                          added_location_list.push(resp.data.layer_regions[i].location.value);
                          this.option_data['wdm3'].general.parameters.layer_location.parameters.layer_location_name.options.push(new_location_item);
                        }
                        if (added_server_list.indexOf(resp.data.layer_regions[i].server.value) === -1) {
                          added_server_list.push(resp.data.layer_regions[i].server.value);
                          let new_item = resp.data.layer_regions[i].server;
                          new_item["if"] = {
                              "friend": {
                                  "layer_location_name": {
                                      "operation": {"or_options": [resp.data.layer_regions[i].location.value] },
                                      "parent_param": "yes",
                                      "index_list": ["general", "layer_location", "layer_location_name"],
                                      "name": {"value": "layer_location_name", "label": "Lokasyon Adı"}
                                  }
                              }
                          }
                          this.option_data['wdm3'].general.parameters.layer_location.parameters.location_servers.options.push(new_item);

                        }
                      }
                      // this.d_hospitalPatientLayerList = resp.data.hospital_patient;

                      this.show_wisdom_data_modal = true;
                    }
                  });
              }
            });
        } else {
          let msg = 'Veri modeli yükleniyor lütfen daha sonra yeniden deneyiniz.';
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Proje düzenleme işlemi hakkında' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        }
      } else {
        let msg = 'Proje düzenleme yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Proje düzenleme işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_calculateRowNoStyle: function (row_ind) {
      let style = '';
      let w = '60px';
      if (this.d_dataset.menu && this.d_dataset.menu.data.show_case_name && this.d_dataset.menu.data.show_case_name.status) {
        w = '100px';
      }
      if (this.d_rowRightClickElementID === 'right_click_row_no_' + row_ind.toString()) {
        style = 'position: sticky; left: 0; width: ' + w + '!important; border-right: solid 1px #c3d8d8; cursor: pointer; background-color: #95edd2; font-size: 10px;';
      } else {
        style = 'position: sticky; left: 0; width: ' + w + '!important; border-right: solid 1px #c3d8d8; cursor: pointer; background-color: ' + this.d_polestarSettings.style.backgroundColor + '; font-size: 10px; color:' + this.d_polestarSettings.style.color;
      }
      return style;
    },
    f_clickAnyWhereInThePage: function () {
      if (this.d_rowRightClickElementID) {
        let el = document.getElementById(this.d_rowRightClickElementID);
        if (el) {
          el.remove();
        }
        this.d_rowRightClickElementID = '';
      }
      this.f_dontShowRightClickElements();
    },
    f_rightClickAnyWhereInThePage: function (e) {
      return;
      console.log(this.d_rowRightClickElementID);
      console.log('e.target.id ', e.target.id);
      let row_ind = '';
      if (e.target.id.indexOf('row_no_') !== -1) {
        row_ind = e.target.id.split('row_no_')[1];
      } else if (e.target.id.indexOf('row_no_') !== -1) {
        row_ind = e.target.id.split('td_cell_div_')[1].split('_')[0];
      }
      let x = e.target.id.indexOf('row_no_') === -1;
      let y = e.target.id.indexOf('row_no_') !== -1 && 'right_click_row_no_' + row_ind !== this.d_rowRightClickElementID;
      let z = e.target.id.indexOf('td_cell_div_') !== -1;
      // console.log(x);
      // console.log(y);
      if (this.d_rowRightClickElementID && (x || y || z)) {
        let el = document.getElementById(this.d_rowRightClickElementID);
        if (el) {
          // el.remove();
        }
        this.d_rowRightClickElementID = '';
      }
      this.f_dontShowRightClickElements();
      this.$forceUpdate();
    },
    f_dontShowRightClickElements: function () {
      for (let i in this.d_rowRightClickElementIDList) {
        if (parseInt(i) !== 0) {
          let el = document.getElementById(this.d_rowRightClickElementIDList[i]);
          if (el) {
            el.style.display = 'none';
            // console.log(el);
          }
        }
      }
      this.d_rowRightClickElementIDList = [];
      // this.$forceUpdate();
    },
    f_getUserScreenModes: function () {
      if (this.user.settings && this.user.settings.screen_mode && this.user.settings.screen_mode.patient_follow) {
        this.d_userScreenModes = this.user.settings.screen_mode.patient_follow.list;
      }
    },
    f_showRightClickMenu: function (e) {
      // console.log(e.target);
      // console.log(e);
      // console.log(Object.keys(e.path));
      // console.log(e.path[0]);
      // console.log(e.path[1]);
      let target_id = e.target.id;
      // console.log(target_id);
      /*
      if (e.target.tagName === 'TD') {
        target_id = e.target.id;
      }
      else {
              for (let i in e.path) {
                if (e.path[i].tagName === 'TD' && e.path[i].id.indexOf('td_row_no_') !== -1) {
                  target_id = e.path[i].id;
                  break;
                }
              }
            }*/
      let row_ind = '';
      let col_ind = '';
      if (target_id.indexOf('row_no_') === -1 && target_id.indexOf('td_cell_div_') === -1) {
        return;
      }
      if (target_id.indexOf('td_cell_div_') !== -1) {
        target_id = target_id.split('td_cell_div_')[1];
        row_ind = parseInt(target_id.split('_')[0]);
        col_ind = parseInt(target_id.split('_')[1]);
      } else if (target_id.indexOf('row_no_') !== -1) {
        row_ind = parseInt(target_id.split('row_no_')[1]);
      }
      // console.log('row_ind', row_ind);
      // console.log('col_ind', col_ind);
      /*
        To use this functonality:
        We have three main dom elements, popup_element, parent_element, and targetPageElement. First define this element that will be which.
        They provide us, location, and view like features.
        Then we define a button_list variable to create our menu.
        If you define a function, please be careful to define it in methods part of your vue document.
        If you define a img
          - img will have a src attribute so it should be defined to an image which is already got while opening the page.
      */
      // console.log(e);
      // console.log(e.target);
      if (this.d_userScreenModes.length === 0) {
        this.f_getUserScreenModes();
      }
      this.f_dontShowRightClickElements();
      if (this.d_rowRightClickElementID) {
        let el = document.getElementById(this.d_rowRightClickElementID);
        if (el) {
          el.remove();
        }
      }
      let top = e.pageY;
      let left = e.pageX + 10;
      let popup_element = document.createElement('div');
      let pop_el_id = 'right_click_row_no_' + row_ind;
      if (col_ind !== '') {
        pop_el_id += '_' + col_ind;
      }
      popup_element.id = pop_el_id;
      this.d_rowRightClickElementID = popup_element.id;
      // console.log(popup_element.id);
      popup_element.classList.add("row-right-click");
      let h = 300; // our menu popup will have 300 px height
      let w = 250; // our menu popup will have 300 px height
      // if we have enough length belowe we will start the menu from below, else we will start it 300px upper.
      popup_element.style['height'] = h.toString() + 'px';
      popup_element.style['width'] = w.toString() + 'px';
      if (window.innerHeight - top >= h) {
        // start the similar line with the clicked row.
        popup_element.style.top = top.toString() + 'px';
        popup_element.style.left = left.toString() + 'px';
      } else {
        popup_element.style.top = (top - h).toString() + 'px';
        popup_element.style.left = left.toString() + 'px';
      }
      // popup_element.innerHTML
      let patient_id = this.d_dataset.case_id_list[row_ind].id ? this.d_dataset.case_id_list[row_ind].id : '';
      let parent_element = document.createElement('ul');
      let par_el_id = 'right_click_ul_' + row_ind;
      if (col_ind !== '') {
        par_el_id = '_' + col_ind;
      }
      parent_element.id = par_el_id;
      let vm = this;
      let targetPageElement = document.getElementById('case_table');
      let button_list = [];
      let decision_making_packages = false;
      try {
        if (this.d_selectedProjectData.data.dmp.dmp_list.list.length > 0) {
          decision_making_packages = true;
        }
      } catch (err) {}
      if (col_ind === '') {
        button_list = require('../../options/right_click_buttons.js').options.polestar_left_row_head;
        // console.log(button_list);
        this.f_addUserScreenModesToButtonList(button_list);
        if (decision_making_packages) {
          this.f_addProjectDmpList(button_list);
        }
        this.f_addSmsColumnOperations(button_list);
      } else {
        button_list = require('../../options/right_click_buttons.js').options.polestar_cell;
      }
      let specific_arguments = {
        'patient_id': patient_id,
        'row_ind': row_ind,
        'col_ind': col_ind,
        'd_eligibleForCaseSearch': this.d_eligibleForCaseSearch,
        'decision_making_packages': decision_making_packages
      };
      ClsMenu.f_turnOverButtonList(this, button_list, popup_element, parent_element, targetPageElement, specific_arguments, this.d_icons);
    },
    f_addUserScreenModesToButtonList: function (button_list) {
      button_list.parameters.patient_follow.button_list = {
        'appendChildOrder': { 'img': 'li', 'a': 'li', 'li': 'parent_element' },
        'parent_order': ['img', 'a', 'li'],
        'param_order': [],
        'parameters': {}
      }
      for (let s in this.d_userScreenModes) {
        let screen_mode = {
          'require': [],
          'elements': {
            'li': {
              'attr': {
                'functions': [{
                  'type': 'onclick',
                  'arguments': [{
                    'type': 'var',
                    'name': 'patient_id'
                  }, {
                    'type': 'string',
                    'name': s
                  }],
                  'name': 'f_getPatientData'
                }]
              }
            },
            'a': {
              'attr': {
                'innerHTML': this.d_userScreenModes[s].label,
                'href': 'javascript:'
              },
              'style': {
                'color': 'black'
              }
            },
            'img': {
              'attr': {
                'src': '2940638'
              },
              'style': {
                'width': '20px',
                'margin-right': '5px'
              }
            }
          }
        }
        let param_name = 'screen_mode_' + s;
        button_list.parameters.patient_follow.button_list.parameters[param_name] = screen_mode;
        button_list.parameters.patient_follow.button_list.param_order.push(param_name);
      }
    },
    f_addProjectDmpList: function (button_list) {
      button_list.parameters.decision_making_packages.button_list = {
        'appendChildOrder': { 'img': 'li', 'a': 'li', 'li': 'parent_element' },
        'parent_order': ['img', 'a', 'li'],
        'param_order': [],
        'parameters': {}
      }
      for (let i in this.d_selectedProjectData.data.dmp.dmp_list.list) {
        let dmp = this.d_selectedProjectData.data.dmp.dmp_list.list[i];
        // console.log('dmp', dmp);
        let dmp_data = {
          'require': [],
          'elements': {
            'li': {
              'attr': {
                'functions': [{
                  'type': 'onclick',
                  'arguments': [{
                    'type': 'var',
                    'name': 'row_ind'
                  }, {
                    'type': 'string',
                    'name': dmp.dmp_id.val
                  }],
                  'name': 'f_analyzeRowByThisDecisionMakingPackage'
                }]
              }
            },
            'a': {
              'attr': {
                'innerHTML': dmp.dmp_name.val,
                'href': 'javascript:'
              },
              'style': {
                'color': 'black'
              }
            },
            'img': {
              'attr': {
                'src': '2324511'
              },
              'style': {
                'width': '20px',
                'margin-right': '5px'
              }
            }
          }
        }
        let param_name = 'decision_making_package_' + dmp.dmp_id.val;
        button_list.parameters.decision_making_packages.button_list.parameters[param_name] = dmp_data;
        button_list.parameters.decision_making_packages.button_list.param_order.push(param_name);
      }
    },
    f_addSmsColumnOperations: function (button_list) {
      button_list.parameters.sms.button_list = {
        'appendChildOrder': { 'img': 'li', 'a': 'li', 'li': 'parent_element' },
        'parent_order': ['img', 'a', 'li'],
        'param_order': [],
        'parameters': {}
      }
      for (let i in this.column_list) {
        let sms_type = '';
        if (this.column_list[i]['dmp_sms_operation']) {
          sms_type = 'auto';
        } else if (this.column_list[i]['manuel_sms_operation']) {
          sms_type = 'manuel';
        }
        if (sms_type) {
          let button_data = {
            'require': [],
            'elements': {
              'li': {
                'attr': {
                  'functions': [{
                    'type': 'onclick',
                    'arguments': [{
                      'type': 'var',
                      'name': 'row_ind'
                    }, {
                      'type': 'string',
                      'name': i
                    }, {
                      'type': 'string',
                      'name': sms_type
                    }],
                    'name': 'f_sendSmsToThisRow'
                  }]
                }
              },
              'a': {
                'attr': {
                  'innerHTML': this.column_list[i].label,
                  'href': 'javascript:'
                },
                'style': {
                  'color': 'black'
                }
              },
              'img': {
                'attr': {
                  'src': '1013986'
                },
                'style': {
                  'width': '20px',
                  'margin-right': '5px'
                }
              }
            }
          }
          let param_name = 'sms_operation_' + i;
          button_list.parameters.sms.button_list.parameters[param_name] = button_data;
          button_list.parameters.sms.button_list.param_order.push(param_name);
        }
      }
    },
    f_analyzeRowByThisDecisionMakingPackage: function (row_ind, dmp_id) {
      if (this.user && this.user.permissions_result && this.user.permissions_result.indexOf('polestar_wisdomstats_analyze-decision-making-package') !== -1) {
        let row_data = {
          'row_no': this.d_dataset.case_id_list[row_ind].no,
          'dataset_key': this.d_dataset.case_id_list[row_ind].dataset_key
        };
        let data = {
          'wdmr24_id': dmp_id,
          'wdm3_id': this.d_selectedProjectData.id,
          'row_list': [row_data]
        };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Karar Destek Paketiyle analiz yapılıyor. Lütfen bekleyiniz.' } });
        DecisionMakingService.analyze_dataset_rows_with_dmp(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            let msg = '';
            // console.log(resp.data.result);
            if (resp.data.status === 'success') {
              this.d_rowDmpAnalyzeResults = resp.data.result;
              this.d_rowDmpAnalyzeResults.columns = [];
              for (let r in this.d_rowDmpAnalyzeResults.rows) {
                for (let dmp_id in this.d_rowDmpAnalyzeResults.rows[r].result.dmp) {
                  for (let t in this.d_rowDmpAnalyzeResults.rows[r].result.dmp[dmp_id].true_rules) {
                    let true_rule = this.d_rowDmpAnalyzeResults.rows[r].result.dmp[dmp_id].true_rules[t];
                    let rule_id = true_rule.id;
                    if (true_rule.operation_no && true_rule.operation_no.val && true_rule.operation_no.val !== '') {
                      let true_rule_result_msg = true_rule.message + '[' + dmp_id + '-' + rule_id + '-' + true_rule.operation_no.val + ']';
                      if (this.d_rowDmpAnalyzeResults.columns.indexOf(true_rule_result_msg) === -1) {
                        this.d_rowDmpAnalyzeResults.columns.push(true_rule_result_msg);
                      }
                    } else {
                      let true_rule_result_msg = true_rule.message + '[' + dmp_id + '-' + rule_id + ']';
                      if (this.d_rowDmpAnalyzeResults.columns.indexOf(true_rule_result_msg) === -1) {
                        this.d_rowDmpAnalyzeResults.columns.push(true_rule_result_msg);
                      }
                    }
                  }
                }
              }
              msg = 'Analiz işlemleri tamamlandı.';
              this.$forceUpdate();
            } else {
              msg = resp.data.message;
              let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Karar Destek Paketi analiz işlemi hakkında' };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
            }
          });
      } else {
        let msg = 'Karar destek paketiyle proje analizi yapabilmeniz için yetki almanız gerekmektedir.';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Karar destek paketi proje işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_mouseOverMenu: function (id_list) {
      // console.log(id_list);
      this.f_dontShowRightClickElements();
      if (id_list.length > 1) {
        let parent_element_id = id_list[id_list.length - 2];
        let child_element_id = id_list[id_list.length - 1];
        for (let i in id_list) {
          this.d_rowRightClickElementIDList.push(id_list[i]);
        }
        // console.log(parent_element_id);
        // console.log(child_element_id);
        // console.log(this.d_rowRightClickElementIDList);
        let parent_element = document.getElementById(parent_element_id);
        let child_element = document.getElementById(child_element_id);
        if (parent_element && child_element) {
          let top = parseInt(parent_element.style.top.replace('px', ''));
          let left = parseInt(parent_element.style.left.replace('px', '')) + parseInt(parent_element.style.width.replace('px', ''));
          // console.log('top ', top);
          // console.log('left ', left);
          let h = 300; // our menu popup will have 300 px height
          let w = 250; // our menu popup will have 300 px height
          // if we have enough length belowe we will start the menu from below, else we will start it 300px upper.
          child_element.style['height'] = h.toString() + 'px';
          child_element.style['width'] = w.toString() + 'px';
          if (window.innerHeight - top >= h) {
            // start the similar line with the clicked row.
            child_element.style.top = top.toString() + 'px';
            child_element.style.left = left.toString() + 'px';
          } else {
            child_element.style.top = (top - h).toString() + 'px';
            child_element.style.left = left.toString() + 'px';
          }
          child_element.style.display = '';
        }
        // console.log(child_element);
        // console.log(e.target.id);
      }
    },
    f_deleteRowApply: function (row_ind, total_cases) {
      this.d_dataset.case_rows.splice(row_ind, 1);
      this.d_dataset.case_id_list.splice(row_ind, 1);
      this.d_dataset.cell_dates.splice(row_ind, 1);
      this.d_dataset.total_cases = total_cases;
      this.$forceUpdate();
    },
    f_deleteDatasetRow: function (row_ind) {
      let data = this.d_dataset.case_id_list[row_ind];
      data.wdm3_id = this.d_selectedProjectData.id;
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Satır Siliniyor. Lütfen bekleyiniz.' } });
      PoleStarService.delete_dataset_row(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            this.f_deleteRowApply(row_ind, resp.data.result.total_cases);
          } else {
            alert('Error :' + resp.data.message);
          }
        });
    },
    f_openHospitalPatientRelation: function (row_ind) {
      this.d_relateHospitalPatient.show = true;
      let data = this.d_dataset.case_id_list[row_ind];
    },
    f_newRowApply: function (type, data, row_ind) {
      this.d_dataset.total_cases = data.total_cases;
      let new_case_rows = data.new_case_rows;
      let new_cell_dates = data.new_cell_dates;
      let case_id_list = data.case_id_list;
      if (type === 'down') {
        if (row_ind === this.d_dataset.case_rows.length) {
          this.d_dataset.case_rows.push(new_case_row);
          this.d_dataset.cell_dates.push(new_cell_dates);
          this.d_dataset.case_id_list.push(case_id_list);
        } else {
          this.d_dataset.case_rows.splice(row_ind + 1, 0, new_case_rows);
          this.d_dataset.cell_dates.splice(row_ind + 1, 0, new_cell_dates);
          this.d_dataset.case_id_list.splice(row_ind + 1, 0, case_id_list);
        }
      } else if (type === 'up') {
        this.d_dataset.case_rows.splice(row_ind, 0, new_case_rows);
        this.d_dataset.cell_dates.splice(row_ind, 0, new_cell_dates);
        this.d_dataset.case_id_list.splice(row_ind, 0, case_id_list);
      }
      this.$forceUpdate();
    },
    f_addNewRow: function (row_ind, type) {
      // we add the row to the same dataset.
      let data = this.d_dataset.case_id_list[row_ind];
      data.wdm3_id = this.d_selectedProjectData.id;
      data.type = type;
      // this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Satır Ekleniyor. Lütfen bekleyiniz.' } });
      PoleStarService.add_new_row(data)
        .then(resp => {
          // this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            this.f_newRowApply(type, resp.data.result, row_ind);
          } else {
            alert('Error :' + resp.data.message);
          }
        });
    },
    f_sendProjectInfoTosocket: function () {
      setTimeout(function () {
        let socket_send = {
          'type': 'add_wisdomstats_project_id_to_socket',
          'id': this.d_selectedProjectData.id,
          'user': { 'username': this.user.username, 'first_name': this.user.first_name, 'last_name': this.user.last_name }
        };
        this.$socket.send(JSON.stringify(socket_send));
      }.bind(this), 2000);
    },
    f_sendDatasetInfoTosocket: function () {
      setTimeout(function () {
        let socket_send = {
          'type': 'dataset_key_list',
          'dataset_key_list': this.d_datasetKeyList,
          'username': this.user.username
        };
        this.$socket.send(JSON.stringify(socket_send));
      }.bind(this), 5000);
    },
    f_tableKeyDown: function (e) {
      // console.log(e.key);
      if (e.key === 'ArrowRight') {
        if (this.d_selected_cells.length === 1) {
          if (!this.d_editCell) {
            let row_ind = parseInt(this.d_selected_cells[0].split('-')[0]);
            let col_ind = parseInt(this.d_selected_cells[0].split('-')[1]);
            if (col_ind < this.d_dataset.case_rows[0].length - 1) {
              this.d_selected_cells[0] = row_ind.toString() + '-' + (col_ind + 1).toString();
            }
          }
        }
      } else if (e.key === 'ArrowLeft') {
        if (this.d_selected_cells.length === 1) {
          if (!this.d_editCell) {
            let row_ind = parseInt(this.d_selected_cells[0].split('-')[0]);
            let col_ind = parseInt(this.d_selected_cells[0].split('-')[1]);
            if (col_ind !== 0) {
              this.d_selected_cells[0] = row_ind.toString() + '-' + (col_ind - 1).toString();
            }
          }
        }
      } else if (e.key === 'ArrowUp') {
        if (this.d_selected_cells.length === 1) {
          if (!this.d_editCell) {
            let row_ind = parseInt(this.d_selected_cells[0].split('-')[0]);
            let col_ind = parseInt(this.d_selected_cells[0].split('-')[1]);
            if (row_ind !== 0) {
              this.d_selected_cells[0] = (row_ind - 1).toString() + '-' + col_ind.toString();
            }
          }
        }
      } else if (e.key === 'ArrowDown') {
        if (this.d_selected_cells.length === 1) {
          if (!this.d_editCell) {
            let row_ind = parseInt(this.d_selected_cells[0].split('-')[0]);
            let col_ind = parseInt(this.d_selected_cells[0].split('-')[1]);
            if (row_ind < this.d_dataset.case_rows.length - 1) {
              this.d_selected_cells[0] = (row_ind + 1).toString() + '-' + col_ind.toString();
            }
          }
        }
      } else if (e.key === 'F2') {
        if (this.d_selected_cells.length === 1) {
          if (this.d_editCell) {
            this.f_cancelEditCell();
            document.getElementById('case_table').focus();
          } else {
            let row_ind = parseInt(this.d_selected_cells[0].split('-')[0]);
            let col_ind = parseInt(this.d_selected_cells[0].split('-')[1]);
            this.f_editThisCell(row_ind, col_ind);
          }
        }
      } else if (e.key === 'Escape') {
        if (this.d_selected_cells.length === 1) {
          // this.d_selected_cells = [];
          if (this.d_editCell) {
            this.f_cancelEditCell();
          }
          document.getElementById('case_table').focus();
        }
      } else if (e.key === 'Enter') {
        if (this.d_selected_cells.length === 1) {
          if (this.d_editCell) {
            this.f_cancelEditCell();
            let row_ind = parseInt(this.d_selected_cells[0].split('-')[0]);
            let col_ind = parseInt(this.d_selected_cells[0].split('-')[1]);
            if (row_ind < this.d_dataset.case_rows.length - 1) {
              this.d_selected_cells[0] = (row_ind + 1).toString() + '-' + col_ind.toString();
              this.f_editThisCell(row_ind + 1, col_ind);
            }
            document.getElementById('case_table').focus();
          } else {
            let row_ind = parseInt(this.d_selected_cells[0].split('-')[0]);
            let col_ind = parseInt(this.d_selected_cells[0].split('-')[1]);
            this.f_editThisCell(row_ind, col_ind);
          }
        }
      }
      this.$forceUpdate();
    },
    f_cancelEditCell: function () {
      let row_ind = this.d_editCell.split('_')[0];
      let col_ind = this.d_editCell.split('_')[1];
      if (this.d_cellTextTimer.text !== this.d_dataset['case_rows'][row_ind][col_ind]) {
        this.d_dataset['case_rows'][row_ind][col_ind] = this.d_cellTextTimer.text;
        this.f_sendCellSocket();
      }
      clearTimeout(this.d_cellTextTimer.timeout);
      this.d_cellTextTimer = { 'timeout': '', 'text': '' };
      this.d_editCell = '';
    },
    f_openCellEditMode: function (row_ind, col_ind) {
      //
    },
    f_editThisCell: function (row_ind, col_ind) {
      if (this.d_userPolestarPermissions.indexOf('owner') !== -1 || this.d_userPolestarPermissions.indexOf('admin') !== -1 || this.d_userPolestarPermissions.indexOf('can_edit_manually_project_cells') !== -1) {
        this.d_cellTextTimer = { 'timeout': '', 'text': this.d_dataset.case_rows[row_ind][col_ind] };
        this.d_editCell = row_ind.toString() + '_' + col_ind.toString();
        let cell_id = 'cell_' + row_ind.toString() + '_' + col_ind.toString();
        setTimeout(function () {
          let x = document.getElementById(cell_id);
          if (x) {
            x.focus();
          }
          this.$forceUpdate();
        }.bind(this), 50);
        // window.location.hash = '#' + cell_id;
      } else {
        let msg = 'Proje hücrelerini düzenleme yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Proje hücrelerini düzenleme işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_printArticlePdf: function (wdm73_key, lang = 'en') {
      let data = {
        'wdm73_key': wdm73_key,
        'lang': lang
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Makale pdf çevirme işlemi başlatıldı. Lütfen bekleyiniz.' } });
      PoleStarService.print_article_pdf(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            let file_name = wdm73_key + ".pdf";
            FileSaver.saveAs(resp.data.result.file, file_name);
          } else {
            alert('Error :' + resp.data.message);
          }
        });
    },
    f_fastAnalyzeMode: function () {
      this.d_fastAnalyzeModeShowModal = true;
    },
    f_showRowModal: function (row_ind) {
      this.d_rowData = {
        'show': true,
        'row_ind': row_ind,
        'row_data': this.d_dataset.case_id_list[row_ind]
      };
    },
    f_getAnalyzeName: function (wdm72_result) {
      if (wdm72_result.col_index !== undefined) {
        return this.column_list[wdm72_result.col_index].label + ' (' + wdm72_result.analyze_type + ')';
      } else {
        if (wdm72_result.rc_ind !== undefined) {
          return this.column_list[wdm72_result.rc_ind].label + ' & ' + this.column_list[wdm72_result.cc_ind].label + ' (' + wdm72_result.analyze_type + ')';
        }
      }
    },
    f_addTableToArticle: function (table, analyze_no, table_name) {
      if (this.d_selectedProjectData.data.articles && this.d_selectedProjectData.data.articles.article && this.d_selectedProjectData.data.articles.article.list.length > 0) {
        this.d_tableToArticle = {
          'show': false,
          'wdmr_key_list': [],
          'buck': 'polestar', // wisdom, polestar, ...
          'table': table, // table data contains multidimensional array. [[0,1,2,3], [4,5,6,7], ...]
          'wdm72_key': this.d_selectedWdm72Key,
          'selected_wdm73_key': this.d_selectedWdm73Key,
          'analyze_no': analyze_no,
          'table_name': table_name,
          'article_list': JSON.parse(JSON.stringify(this.d_selectedProjectData.data.articles.article.list))
        }
        this.d_tableToArticle.show = true;
      } else {
        alert('Henüz eklenmiş makale bulunmamaktadır.');
      }
    },
    f_addTableToWdmr: function () {
      let data = {
        'wdmr_key_list': this.d_tableToArticle.wdmr_key_list,
        'buck': this.d_tableToArticle.buck, // wisdom, polestar, ...
        'table': this.d_tableToArticle.table, // table data contains multidimensional array. [[0,1,2,3], [4,5,6,7], ...]
        'table_name': this.d_tableToArticle.table_name,
        'wdm72_key': this.d_tableToArticle.wdm72_key,
        'selected_wdm73_key': this.d_tableToArticle.selected_wdm73_key, // we get this, to return the wdmr data of this wdmr73, because it is selected
        'analyze_no': this.d_tableToArticle.analyze_no
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Tablo aktarım işlemi başlatıldı. Lütfen bekleyiniz.' } });
      WdmService.add_table_to_wdmr(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            this.d_selectedWdm73Data = resp.data.result['wdmr73_data'];
            alert('Tablo makaleye aktarıldı.');
            this.d_tableToArticle.show = false;
            this.$forceUpdate();
          } else {
            alert('Error :' + resp.data.message)
          }
        });
    },
    f_addChartToArticleAsFigure: function (chart, analyze_no, chart_name) {
      let chart_data = {
        'backgroundColor': chart.backgroundColor ? chart.backgroundColor : '#fff',
        'name': chart.name ? chart.name : '',
        'datasets': [],
        'type': chart.type,
        'labels': chart.labels
      };
      for (let d in chart.datasets) {
        let new_dataset = {}
        if (chart.datasets[d].label !== undefined) {
          new_dataset.label = chart.datasets[d].label;
        }
        if (chart.datasets[d].lineTension !== undefined) {
          new_dataset.lineTension = chart.datasets[d].lineTension;
        }
        if (chart.datasets[d].borderColor !== undefined) {
          new_dataset.borderColor = chart.datasets[d].borderColor;
        }
        if (chart.datasets[d].backgroundColor !== undefined) {
          new_dataset.backgroundColor = chart.datasets[d].backgroundColor;
        }
        if (chart.datasets[d].showLine !== undefined) {
          new_dataset.showLine = chart.datasets[d].showLine;
        }
        if (chart.datasets[d].data !== undefined) {
          new_dataset.data = chart.datasets[d].data;
        }
        chart_data.datasets.push(new_dataset);
      }
      if (this.d_selectedProjectData.data.articles && this.d_selectedProjectData.data.articles.article && this.d_selectedProjectData.data.articles.article.list.length > 0) {
        this.d_chartToArticleAsFigure = {
          'show': false,
          'wdmr_key_list': [],
          'buck': 'polestar', // wisdom, polestar, ...
          'chart': chart_data, // chart_data
          'wdm72_key': this.d_selectedWdm72Key,
          'selected_wdm73_key': this.d_selectedWdm73Key,
          'analyze_no': analyze_no,
          'chart_name': chart_name,
          'article_list': JSON.parse(JSON.stringify(this.d_selectedProjectData.data.articles.article.list))
        }
        this.d_chartToArticleAsFigure.show = true;
      } else {
        alert('Henüz eklenmiş makale bulunmamaktadır.');
      }
    },
    f_addChartToWdmrAsFigure: function () {
      let data = {
        'wdmr_key_list': this.d_chartToArticleAsFigure.wdmr_key_list,
        'buck': this.d_chartToArticleAsFigure.buck, // wisdom, polestar, ...
        'chart': this.d_chartToArticleAsFigure.chart,
        'chart_name': this.d_chartToArticleAsFigure.chart_name,
        'wdm72_key': this.d_chartToArticleAsFigure.wdm72_key,
        'selected_wdm73_key': this.d_chartToArticleAsFigure.selected_wdm73_key, // we get this, to return the wdmr data of this wdmr73, because it is selected
        'analyze_no': this.d_chartToArticleAsFigure.analyze_no
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Grafik aktarım işlemi başlatıldı. Lütfen bekleyiniz.' } });
      WdmService.add_chart_to_wdmr_as_figure(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            this.d_selectedWdm73Data = resp.data.result['wdmr73_data'];
            alert('Grafik makaleye aktarıldı.');
            this.d_chartToArticleAsFigure.show = false;
            this.$forceUpdate();
          } else {
            alert('Error :' + resp.data.message)
          }
        });
    },
    f_createPDFofWdm72Results: function (wdm72_key, type, lang_list = ['en', 'tr']) {
      let data = {
        'wdm72_key': wdm72_key,
        'type': type, // all, filtered
        'lang_list': lang_list
      };
      if (type === 'filtered') {
        data['selected_analyze_types'] = this.d_filterAnalyzeData.selected_analyze_types;
        data['selected_p_value'] = this.d_filterAnalyzeData.selected_p_value;
        data['selected_columns'] = this.d_filterAnalyzeData.selected_columns;
        data['selected_stats'] = this.d_filterAnalyzeData.selected_stats;
        data['user_score_list'] = this.d_filterAnalyzeData.user_score_list ? this.d_filterAnalyzeData.user_score_list : '';
      }
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'PDF üretim işlemi başlatıldı. Hazırlandıkça gönderilecektir.  Lütfen bekleyiniz.' } });
      PoleStarService.create_pdf_of_wdm72_results(data)
        .then(resp => {
          // console.log(resp.data);
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            let file_name = wdm72_key + ".pdf";
            FileSaver.saveAs(resp.data.result, file_name);
          } else {
            alert('Error :' + resp.data.message)
          }
        });
    },
    f_selectionControlOfTextListForArticle: function () {
      let is_first_text_selected = false;
      for (let i in this.d_articleList.other) {
        for (let t in this.d_articleList.other[i].text_list) {
          if (this.d_articleList.other[i].text_list[t].selected) {
            is_first_text_selected = true;
            break;
          }
        }
        if (is_first_text_selected) {
          break;
        }
      }
      for (let i in this.d_articleList.wdm72_result) {
        for (let t in this.d_articleList.wdm72_result[i].text_list) {
          if (this.d_articleList.wdm72_result[i].text_list[t].selected) {
            is_first_text_selected = true;
            break;
          }
        }
        if (is_first_text_selected) {
          break;
        }
      }
      for (let i in this.d_articleList.other) {
        for (let t in this.d_articleList.other[i].text_list) {
          if (is_first_text_selected) {
            this.d_articleList.other[i].text_list[t].selected = 0;
          } else {
            this.d_articleList.other[i].text_list[t].selected = 1;
          }
        }
      }
      for (let i in this.d_articleList.wdm72_result) {
        for (let t in this.d_articleList.wdm72_result[i].text_list) {
          if (is_first_text_selected) {
            this.d_articleList.wdm72_result[i].text_list[t].selected = 0;
          } else {
            this.d_articleList.wdm72_result[i].text_list[t].selected = 1;
          }
        }
      }
      this.$forceUpdate();
    },
    f_createAllTextOfWdm72Results: function (wdm72_key, type) {
      let data = {
        'wdm72_key': wdm72_key,
        'type': type // analyze_methods, all, rule_algoritm, analyze_methods_and_rule_algoritm, analyze_methods_and_results, results
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Algoritma yazı üretim işlemi başlatıldı. Lütfen bekleyiniz.' } });
      PoleStarService.create_all_text_of_wdm72_results(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            this.f_showArticleList(false, resp.data.result.text_list);
          } else {
            alert('Error :' + resp.data.message)
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          }
        });
    },
    f_prepareDatasetRuleAlgoritmForArticle: function () {
      //
    },
    f_showArticleList: function (wdm72_result, other_text_list = []) {
      if (this.d_selectedProjectData.data.articles && this.d_selectedProjectData.data.articles.article && this.d_selectedProjectData.data.articles.article.list.length > 0) {
        this.d_articleList.list = JSON.parse(JSON.stringify(this.d_selectedProjectData.data.articles.article.list));
        this.d_articleList.show = true;
        this.d_articleList.other = [];
        this.d_articleList.wdm72_result = [];
        if (wdm72_result) {
          let wdm_result_data = { 'no': wdm72_result.no, 'text_list': wdm72_result.text_list };
          this.d_articleList.wdm72_result.push(wdm_result_data);
        }
        if (other_text_list.length > 0) {
          this.d_articleList.other = this.d_articleList.other.concat(other_text_list);
        }
        this.d_wdmParameters.option_data = { 'wdm73': this.option_data['wdm73'] };
        this.d_wdmParameters.data_type = 'wdm73';
      } else {
        alert('Henüz eklenmiş makale bulunmamaktadır.');
      }
    },
    f_sendToArticle: function () {
      if (this.d_articleList.selected_articles.length > 0) {
        if (this.d_wdmParameters.selected_index_data.index_list.length > 0) {
          let wdm_72_key = this.d_selectedWdm72Key;
          let data = {
            'other': this.d_articleList.other,
            'wdm72_key': this.d_selectedWdm72Key,
            'wdm72_result': this.d_articleList.wdm72_result,
            'selected_articles': this.d_articleList.selected_articles,
            'index_list': this.d_wdmParameters.selected_index_data.index_list
          };
          this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Makaleye aktarım işlemi başlatıldı. Lütfen bekleyiniz.' } });
          PoleStarService.add_text_list_to_article(data)
            .then(resp => {
              this.$store.commit('Loading', { 'status': false, 'data': {} });
              if (resp.data.status === 'success') {
                // this.d_articleList.show = false;
                alert('Makale aktarım işlemi tamamlandı.');
              } else {
                alert('Error :' + resp.data.message)
                this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
              }
            });
        } else {
          alert('Lütfen parametre seçimi yapınız');
        }
      } else {
        alert('Lütfen parametre seçimi yapınız');
      }
    },
    f_getPatientData: function (patient_id, screen_mode = '') {
      // console.log(screen_mode);
      this.d_iframePatientWdmr.url = this.PATIENT_APP_FRONTEND_URL + '#/if/patientwdmr?patient_id=' + patient_id;
      if (screen_mode) {
        this.d_iframePatientWdmr.url += '&smi=' + screen_mode;
      }
      this.d_showPatientWdmr = true;
    },
    f_getPatientDataOld: function (patient_id, screen_mode = '') {
      // console.log(screen_mode);
      if (patient_id) {
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Vaka verileri getiriliyor. Lütfen bekleyiniz.' } });
        PatientService.get_project_patient(patient_id)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.status === 'success') {
              this.patient_data = resp.data.result;
              this.d_screenModeIndex = screen_mode.toString();
              this.d_iframePatientWdmr.url = this.PATIENT_APP_FRONTEND_URL + '#/if/patientwdmr?patient_id=' + this.patient_data.id;
              if (screen_mode) {
                this.d_iframePatientWdmr.url += '&smi=' + screen_mode;
              }
              this.d_showPatientWdmr = true;
            } else {
              alert('Hasta kaydı hatası :', resp.data.message)
              this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            }
          });
      }
    },
    f_patientCombine: function (row_ind) {
      console.log(row_ind);
      this.d_combinePatientData = {
        'hims_patient_no': '',
        'show': true,
        'row_ind': row_ind
      }
    },
    f_patientFindFromHospital: function () {
      if (this.d_combinePatientData.hims_patient_no) {
        let data = {
          'hospital_id': this.user.hospital_id,
          'hospital_group': this.user.hospital_group,
          'hims_patient_no': this.d_combinePatientData.hims_patient_no
        }
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Hasta getiriliyor. Lütfen bekleyiniz.' } });
        PatientService.find_hospital_patient(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.status === 'success') {
              console.log(resp.data.result);
              this.d_combinePatientData.patient = resp.data.result;
              this.$forceUpdate();
            } else {
              alert('Hasta kaydı hatası :', resp.data.message)
              this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
            }
          });
      } else {
        alert('Lütfen hastaya ait hastane nosunu giriniz.');
      }
    },
    f_combineWithThisPatient: function () {
      let data = {
        'patient': this.d_combinePatientData.patient,
        'wdm3_id': this.d_selectedProjectData.id,
        'row_no': this.d_dataset.case_id_list[this.d_combinePatientData.row_ind].no,
        'dataset_key': this.d_dataset.case_id_list[this.d_combinePatientData.row_ind].dataset_key
      }
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Hasta İlişkilendiriliyor. Lütfen bekleyiniz.' } });
      PoleStarService.combine_with_this_patient(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            this.d_dataset.case_id_list[this.d_combinePatientData.row_ind].id = this.d_combinePatientData.patient.id;
            this.$forceUpdate();
          } else {
            alert('Error :', resp.data.message)
            this.$store.commit('Loading', { 'status': false, 'data': { 'label': '' } });
          }
        });
    },
    f_wisdomDataShowChange: function () {
      if (this.change === "0") {
        this.change = "1";
      } else {
        this.change = "0";
      }
      if (this.d_wisdomData.change === "0") {
        this.d_wisdomData.change = "1";
      } else {
        this.d_wisdomData.change = "0";
      }
      // this.f_showTrueFalse();
      this.$forceUpdate();
    },
    f_deleteCaseFilterAlgorithm: function (type) {
      if (this.d_userPolestarPermissions.indexOf('owner') !== -1 || this.d_userPolestarPermissions.indexOf('admin') !== -1 || this.d_userPolestarPermissions.indexOf('project_add_edit_statistical_analyze') !== -1 || this.d_userPolestarPermissions.indexOf('project_add_edit_machine_learning_model') !== -1) {
        if (type === 'wdm72') {
          this.$delete(this.d_selectedWdm72Data, 'rule_algoritm');
          let data = {};
          data = { 'data': this.d_selectedWdm72Data };
          this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kayıt yapılıyor. Lütfen bekleyiniz.' } });
          PoleStarService.save_statistics(data).then(resp => {
            if (resp.data.status === "success") {
              // this.d_selectedProjectData = resp.data.result.project_data;
              this.d_selectedWdm72Data = resp.data.result.wdm72_data;
              this.d_showRuleAlgorithm.show = false;
              this.$store.commit('Loading', { 'status': false, 'data': {} });
              this.$forceUpdate();
            } else {
              let modal_data = { 'type': 'alert', 'text': resp.data.message, 'centered': true, 'title': this.$t('wdm16.13274') };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
            }
          });
        } else if (type === 'wdm163') {
          this.$delete(this.d_selectedWdm163_data, 'rule_algoritm');
          let data = {};
          data = { 'data': this.d_selectedWdm163_data };
          this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kayıt yapılıyor. Lütfen bekleyiniz.' } });
          MachineLearningService.save(data).then(resp => {
            if (resp.data.status === "success") {
              // this.d_selectedProjectData = resp.data.result.project_data;
              this.d_selectedWdm163_data = resp.data.result.wdm163_data;
              this.d_showRuleAlgorithm.show = false;
              this.$store.commit('Loading', { 'status': false, 'data': {} });
              this.$forceUpdate();
            } else {
              let modal_data = { 'type': 'alert', 'text': resp.data.message, 'centered': true, 'title': this.$t('wdm16.13274') };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
            }
          });
        }
      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }

    },
    f_saveCaseFilter: function () {
      let parenthesis_control = ClsRule.f_controlParanthesisDesign(this.d_columnRule.compile_str_array);
      // console.log('parenthesis_control : ', parenthesis_control);
      if (parenthesis_control) {
        // When we write our code with mode param, we have to convert it to query_list format to make a filter.
        if (this.d_columnRule.wra_settings.mode === 'param') {
          ClsRule.f_paramToQuery(this.d_columnRule.query_list, this.d_columnRule.compile_str_array, this.d_columnRule.wps_data);
        }
        let data = {};
        if (this.d_showRuleAlgorithm.type === 'wdm72') {
          this.d_selectedWdm72Data.rule_algoritm = {
            'query_list': JSON.parse(JSON.stringify(this.d_columnRule.query_list)),
            'compile_str_array': JSON.parse(JSON.stringify(this.d_columnRule.compile_str_array)),
            'compile_str_text': JSON.parse(JSON.stringify(this.d_columnRule.compile_str_text)),
            'compile_str_date': JSON.parse(JSON.stringify(this.d_columnRule.compile_str_date))
          };
          data = { 'data': this.d_selectedWdm72Data };
          this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kayıt yapılıyor. Lütfen bekleyiniz.' } });
          PoleStarService.save_statistics(data).then(resp => {
            if (resp.data.status === "success") {
              // this.d_selectedProjectData = resp.data.result.project_data;
              this.d_selectedWdm72Data = resp.data.result.wdm72_data;
              this.d_showRuleAlgorithm.show = false;
              this.$store.commit('Loading', { 'status': false, 'data': {} });
              this.$forceUpdate();
            } else {
              let modal_data = { 'type': 'alert', 'text': resp.data.message, 'centered': true, 'title': this.$t('wdm16.13274') };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
            }
          });
        } else if (this.d_showRuleAlgorithm.type === 'wdm163') {
          this.d_selectedWdm163_data.rule_algoritm = {
            'query_list': JSON.parse(JSON.stringify(this.d_columnRule.query_list)),
            'compile_str_array': JSON.parse(JSON.stringify(this.d_columnRule.compile_str_array)),
            'compile_str_text': JSON.parse(JSON.stringify(this.d_columnRule.compile_str_text)),
            'compile_str_date': JSON.parse(JSON.stringify(this.d_columnRule.compile_str_date))
          };
          data = { 'data': this.d_selectedWdm163_data };
          this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kayıt yapılıyor. Lütfen bekleyiniz.' } });
          MachineLearningService.save(data).then(resp => {
            if (resp.data.status === "success") {
              // this.d_selectedProjectData = resp.data.result.project_data;
              this.d_selectedWdm163_data = resp.data.result.wdm163_data;
              this.d_showRuleAlgorithm.show = false;
              this.$store.commit('Loading', { 'status': false, 'data': {} });
              this.$forceUpdate();
            } else {
              let modal_data = { 'type': 'alert', 'text': resp.data.message, 'centered': true, 'title': this.$t('wdm16.13274') };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
            }
          });
        }
      } else {
        let msg = 'Lütfen parentezlerinizi kontrol ediniz.';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Algoritma kuralı oluşturma işlemi hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_caseFilter: function (type) {
      if (this.d_userPolestarPermissions.indexOf('owner') !== -1 || this.d_userPolestarPermissions.indexOf('admin') !== -1 || this.d_userPolestarPermissions.indexOf('project_add_edit_statistical_analyze') !== -1 || this.d_userPolestarPermissions.indexOf('project_add_edit_machine_learning_model') !== -1) {
        this.d_columnRule = JSON.parse(JSON.stringify(this.d_prepareRuleNew));
        let data = '';
        if (type === 'wdm72') {
          data = this.d_selectedWdm72Data;
        } else if (type === 'wdm163') {
          data = this.d_selectedWdm163_data;
        }
        if (data.rule_algoritm) {
          this.d_columnRule.query_list = data.rule_algoritm.query_list;
          this.d_columnRule.compile_str_array = data.rule_algoritm.compile_str_array;
          if (data.rule_algoritm.compile_str_date) {
            this.d_columnRule.compile_str_date = data.rule_algoritm.compile_str_date;
          }
          if (data.rule_algoritm.compile_str_text) {
            this.d_columnRule.compile_str_text = data.rule_algoritm.compile_str_text;
          }
        }
        ClsPolestar.f_prepareColumnRuleOptionData(this.d_columnRule, this.column_list);
        this.d_showRuleAlgorithm.show = true;
        this.d_showRuleAlgorithm.type = type;
      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_calculateTableTdStyle: function (r_index, c_index) {
      let style = 'min-width: 100px; text-align: center; border: solid 1px #c3d8d8;';
      if (r_index === 0 || c_index === 0) {
        style += ' font-weight: bold;';
      }
      if (r_index === 0) {
        style += 'position: sticky; z-index: 1000; top: 0; background-color: #00bcd4;';
      }
      if (c_index === 0) {
        style += 'position: sticky; left: 0;';
      }
      return style;
    },
    f_showTableFullScreen: function (table, label = '') {
      this.d_tableFullScreenData.label = label;
      this.d_tableFullScreenData.table = table;
      this.d_tableFullScreenData.show = true;
    },
    f_showTableCellFullText: function (c) {
      this.d_selectedTableCellFullText = c.toString();
      this.d_showTableCellFullText = true;
    },
    f_showChartData: function (chart_data) {
      let new_chart_data = {
        backgroundColor: "#fff",
        labels: chart_data.labels,
        datasets: []
      }
      for (let d in chart_data.datasets) {
        let new_dataset = {}
        if (chart_data.datasets[d].label !== undefined) {
          new_dataset.label = chart_data.datasets[d].label;
        }
        if (chart_data.datasets[d].lineTension !== undefined) {
          new_dataset.lineTension = chart_data.datasets[d].lineTension;
        }
        if (chart_data.datasets[d].borderColor !== undefined) {
          new_dataset.borderColor = chart_data.datasets[d].borderColor;
        }
        if (chart_data.datasets[d].backgroundColor !== undefined) {
          new_dataset.backgroundColor = chart_data.datasets[d].backgroundColor;
        }
        if (chart_data.datasets[d].showLine !== undefined) {
          new_dataset.showLine = chart_data.datasets[d].showLine;
        }
        if (chart_data.datasets[d].data !== undefined) {
          new_dataset.data = chart_data.datasets[d].data;
        }
        new_chart_data.datasets.push(new_dataset);
      }
      this.chart_data = JSON.parse(JSON.stringify(new_chart_data));
      this.d_resetChartData = JSON.parse(JSON.stringify(this.chart_data));
      this.d_chartSize.chart_type = chart_data.type;
      this.d_chartSize.x_mode = 'standart';
      this.d_chartSize.y_mode = 'standart';
      this.d_chartSize.x_interval_type = 'all';
      this.d_chartSize.height = window.innerHeight - 200;
      this.d_chartSize.width = window.innerWidth - 100;
      this.d_showGraphicView = true;
    },
    f_calculateStyleDatasetTable: function (row_ind, col_ind) {
      let style = '';
      // style = 'white-space: nowrap; border-right: solid 1px #c1c7cc; width: 150px;';
      style = 'white-space: nowrap; border-right: solid 1px #c1c7cc;';
      let hims_auto_match = 0;
      try {
        hims_auto_match = this.d_dataset.menu.data.show_hims_auto_match.status;
      } catch (err) {}
      if (hims_auto_match && Object.keys(this.d_dataset.cell_dates[row_ind][col_ind]).length > 0) {
        let det = false;
        for (let i in this.d_dataset.cell_dates[row_ind][col_ind]) {
          if (this.d_dataset.cell_dates[row_ind][col_ind][i].hims_auto_match) {
            det = true;
            break;
          }
        }
        if (det) {
          style += 'box-shadow: #08e8ef 0px 0px 2px 2px;'; // background-color: #c1fdff;
        }
      }
      let cell_point = row_ind.toString() + '_' + col_ind.toString();
      if (this.d_dataset.case_style && this.d_dataset.case_style[cell_point] && this.d_dataset.case_style[cell_point].backgroundColor) {
        style += 'background-color: ' + this.d_dataset.case_style[cell_point].backgroundColor + ';';
      } else {
        if (this.d_selected_cells.indexOf((this.d_datasetPagination.start + row_ind).toString() + '-' + col_ind.toString()) !== -1) {
          style += 'background-color: rgb(236, 255, 236);';
        } else {
          style += 'background-color: ' + this.d_polestarSettings.style.backgroundColor + ';';
        }
      }
      return style;
    },
    f_deleteWdm72Data: function (wdm72_key) {
      if (this.d_userPolestarPermissions.indexOf('owner') !== -1 || this.d_userPolestarPermissions.indexOf('admin') !== -1 || this.d_userPolestarPermissions.indexOf('project_add_edit_statistical_analyze') !== -1) {
        let modal_data = { 'type': 'confirm', 'text': 'Analiz modelini silmek istediğinize emin misiniz?', 'centered': true, 'title': 'Analiz Modelini Silme İşlemi Hakkında', 'function_name': 'f_deleteWdm72DataConfirmed', 'confirm': false, 'arguments': [wdm72_key] };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_deleteWdm72DataConfirmed: function (args) {
      let wdm72_key = args[0];
      let data = {
        'wdm72_key': wdm72_key,
        'wdm3_id': this.d_selectedProjectData.id
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Silme işleminiz gerçekleşiyor. Lütfen bekleyiniz.' } });
      PoleStarService.delete_wdm72_data(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            this.d_selectedProjectData = resp.data.result.wdm3_data;
            if (this.d_selectedWdm72Key === wdm72_key) {
              this.d_selectedWdm72Key = '';
              this.d_selectedWdm72Data = {};
            }
            this.$forceUpdate();
          } else {
            alert('error', resp.data.message);
          }
        });
    },
    f_deleteWdm73Data: function (wdm73_key) {
      let modal_data = { 'type': 'confirm', 'text': 'Makaleyi silmek istediğinize emin misiniz?', 'centered': true, 'title': 'Makale Silme İşlemi Hakkında', 'function_name': 'f_deleteWdm73DataConfirmed', 'confirm': false, 'arguments': [wdm73_key] };
      this.$store.commit('modal', modal_data);
      this.$store.commit('modal_show', true);
    },
    f_deleteWdm73DataConfirmed: function (args) {
      let wdm73_key = args[0];
      let data = {
        'wdm73_key': wdm73_key,
        'wdm3_id': this.d_selectedProjectData.id
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Silme işleminiz gerçekleşiyor. Lütfen bekleyiniz.' } });
      PoleStarService.delete_wdm73_data(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            this.d_selectedProjectData = resp.data.result.wdm3_data;
            if (this.d_selectedWdm73Key === wdm73_key) {
              this.d_selectedWdm73Key = '';
              this.d_selectedWdm73Data = {};
            }
            this.$forceUpdate();
          } else {
            alert('error', resp.data.message);
          }
        });
    },
    f_createChartDataForCorrelation: function (wdm72_result, show_type = '') {
      let chart_data = {
        backgroundColor: "#fff",
        labels: [],
        datasets: []
      };
      let normalized_list_length = 0;
      for (let p in wdm72_result.parameters) {
        normalized_list_length = wdm72_result.parameters[p]['normalized_list'].length;
        let new_dataset = {
          label: p,
          lineTension: 0,
          borderColor: this.f_calculateRGBA(),
          backgroundColor: 'rgba(255, 255, 255, 0)',
          showLine: true,
          // backgroundColor: hexToRgba('#20a8d8', 90),
          data: wdm72_result.parameters[p]['normalized_list']
        };
        chart_data.datasets.push(new_dataset);
      }
      for (let i = 0; i < normalized_list_length; i++) {
        chart_data.labels.push('');
      }
      if (show_type === 'show_modal') {
        this.chart_data = chart_data;
        this.d_resetChartData = JSON.parse(JSON.stringify(this.chart_data));
        this.d_chartSize.chart_type = 'line';
        this.d_chartSize.x_mode = 'standart';
        this.d_chartSize.y_mode = 'standart';
        this.d_chartSize.x_interval_type = 'all';
        this.d_chartSize.height = window.innerHeight - 200;
        this.d_chartSize.width = window.innerWidth - 100;
        this.d_showGraphicView = true;
      } else {
        return chart_data;
      }
    },
    f_createChartData: function (val_list, caption, show_type = '') {
      let chart_data = {
        backgroundColor: "#fff",
        labels: [],
        datasets: [{
          label: caption,
          lineTension: 0,
          borderColor: '#20a8d8',
          backgroundColor: '#ffffff00',
          showLine: true,
          // backgroundColor: hexToRgba('#20a8d8', 90),
          data: []
        }]
      };
      for (let i in val_list) {
        chart_data.datasets[0].data.push(val_list[i]);
        chart_data.labels.push('');
        /*
        her 10 adetten bir tanesinde x labelını gösteriyoruz.
        if (parseInt(i) === 0) {
          chart_data.labels.push(x_value);
        } else {
          if (parseInt(i) % 20 === 0) {
            chart_data.labels.push(x_value);
          } else {
            chart_data.labels.push('');
          }
        }
      */
      }
      if (show_type === 'show_modal') {
        this.chart_data = chart_data;
        this.d_resetChartData = JSON.parse(JSON.stringify(this.chart_data));
        this.d_chartSize.chart_type = 'line';
        this.d_chartSize.x_mode = 'standart';
        this.d_chartSize.y_mode = 'standart';
        this.d_chartSize.x_interval_type = 'all';
        this.d_chartSize.height = window.innerHeight - 200;
        this.d_chartSize.width = window.innerWidth - 100;
        this.d_showGraphicView = true;
      } else {
        return chart_data;
      }
    },
    f_createWdm72ColList: function () {
      let col_list = [];
      for (let i in this.column_list) {
        let lbl = this.column_list[i].label + '( no:' + i + ' ) -' + this.column_list[i].param_type + ' -';
        // We took the column index in value. So when changing we can use value field.
        let x = { 'label': lbl, 'value': i.toString(), 'param_type': this.column_list[i].param_type }
        col_list.push(x);
      }
      this.option_data['wdm72'].general.parameters.analyze_column_list.options = col_list;
      this.option_data['wdm72'].general.parameters.row_parameter_list.options = col_list;
      this.option_data['wdm72'].general.parameters.column_parameter_list.options = col_list;
      this.option_data['wdm72'].general.parameters.before_after.parameters.first_column.options = col_list;
      this.option_data['wdm72'].general.parameters.before_after.parameters.second_column.options = col_list;
    },
    f_createNewStatsByCopying: function (wdm72_key) {
      if (this.d_userPolestarPermissions.indexOf('owner') !== -1 || this.d_userPolestarPermissions.indexOf('admin') !== -1 || this.d_userPolestarPermissions.indexOf('project_add_edit_statistical_analyze') !== -1) {
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Lütfen bekleyiniz.' } });
        GlobalService.get_one_cb_document({ 'key': wdm72_key, 'bucket': 'polestar' })
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.status === 'success') {
              let pre_data = {
                'id': 'new',
                'data': {},
                'type': 'wdm72',
                'reference_wdm': {},
                'wdm3_id': this.d_selectedProjectData.id
              };
              pre_data['reference_wdm']['version'] = this.option_data['wdm72']['version'];
              pre_data['reference_wdm']['owner_type'] = this.option_data['wdm72']['owner_type'];
              if (this.option_data['wdm72']['owner_type'] === 'wisdom') {
                pre_data['reference_wdm']['key'] = 'wdm_wisdom_wdm72_v' + this.option_data['wdm72'].version;
              }
              pre_data['data'] = resp.data.result.data;
              this.f_createWdm72ColList();
              this.d_statisticWisdomData = pre_data;
              this.d_showStatisticsModal = true;
            } else {
              alert('error', resp.data.message);
            }
          });
      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_editStatisticalAnalyze: function (wdm72_key) {
      if (this.d_userPolestarPermissions.indexOf('owner') !== -1 || this.d_userPolestarPermissions.indexOf('admin') !== -1 || this.d_userPolestarPermissions.indexOf('project_add_edit_statistical_analyze') !== -1) {
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Lütfen bekleyiniz.' } });
        GlobalService.get_one_cb_document({ 'key': wdm72_key, 'bucket': 'polestar' })
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.status === 'success') {
              this.d_statisticWisdomData = resp.data.result;
              this.f_createWdm72ColList();
              this.d_showStatisticsModal = true;
            } else {
              alert('error', resp.data.message);
            }
          });
      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_editWdm73Data: function (wdm73_key) {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Lütfen bekleyiniz.' } });
      GlobalService.get_one_cb_document({ 'key': wdm73_key, 'bucket': 'polestar' })
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            this.d_wdm73WisdomData = resp.data.result;
            this.d_showWdm73Modal = true;
          } else {
            alert('error', resp.data.message);
          }
        });
    },
    f_getWdmList: function () {
      // Öncelikle en sık kullanılan wdm3 versiyonunu getiriyoruz. Dolayısıyla ekranda en aktif kullanılan wdm getirilmiş oluyor.
      let data = {
        'wdm_list': [{
          'type': 'wdm3',
          'owner_type': 'wisdom'
        }],
        'target_lang': ''
      };
      WdmService.get_wdm_last_list(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.option_data['wdm3'] = resp.data.result.wdm_list['wdm3'];
          } else {
            console.log('errror', resp.data.message);
          }
        });
      // ekranda diğer kullanılabilecek wdmler de ayrı ca getiriliyor.
      data = {
        'wdm_list': [{
          'type': 'wdm72',
          'owner_type': 'wisdom'
        }, {
          'type': 'wdm73',
          'owner_type': 'wisdom'
        }, {
          'type': 'wdm163',
          'owner_type': 'wisdom'
        }, {
          'type': 'wdm24',
          'owner_type': 'wisdom'
        }, {
          'type': 'wdm229',
          'owner_type': 'wisdom'
        }, {
          'type': 'wdm332',
          'owner_type': 'wisdom'
        }],
        'target_lang': ''
      };
      WdmService.get_wdm_last_list(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            for (let i in resp.data.result.wdm_list) {
              if (i === 'wdm24') {
                let op_data = resp.data.result.wdm_list[i];
                op_data.general.parameters.who.disabled = 1;
                op_data.general.parameters.wdm.disabled = 1;
                this.d_wisdomData.option_data[i] = op_data;
              } else {
                this.option_data[i] = resp.data.result.wdm_list[i];
              }
            }
          } else {
            console.log('errror', resp.data.message);
          }
        });
    },
    f_addNewRows: function (row_count) {
      this.d_newRowData = {
        'row_count': row_count,
        'project_id': this.d_selectedProjectData.id
      };
      let row_control = '';
      try {
        row_control = parseInt(this.d_newRowData.row_count);
        this.d_newRowData.row_count = row_control;
      } catch (err) {}
      if (row_control) {
        PoleStarService.add_new_rows(this.d_newRowData)
          .then(resp => {
            let msg = '';
            if (resp.data.status === 'success') {
              this.f_getCases();
              msg = 'Başarıyla eklendi.';
            } else {
              msg = resp.data.message;
            }
            this.d_showNewRowModal = false;
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İstatistik için verilerin hazırlanması işlemi' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);

          });
      } else {
        let msg = 'Lütfen satır sayısını kontrol ediniz.';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İstatistik için verilerin hazırlanması işlemi' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_columnRuleShow: function () {
      ClsPolestar.f_prepareColumnRuleOptionData(this.d_columnRule, this.column_list);
      this.f_selectViewMode('column_filter_show');
    },
    addEventListenerToTable: function () {
      const handler = (evt) => {
        // console.log(document.getElementById('case_table').offsetWidth);
        // console.log(document.getElementById('case_table').scrollWidth);
        // console.log(document.getElementById('case_table').scrollLeft);
        if (this.d_tableModeSettings.show_selected_columns.length === 0) {
          if (document.getElementById('case_table').scrollWidth < (document.getElementById('case_table').scrollLeft + document.getElementById('case_table').offsetWidth)) {
            this.d_tableModeSettings.start += this.d_tableModeSettings.perpage;
            this.d_tableModeSettings.end += this.d_tableModeSettings.perpage;
            document.getElementById('case_table').scrollLeft = 10;
            this.$forceUpdate();
          } else if (document.getElementById('case_table').scrollLeft === 0) {
            if (this.d_tableModeSettings.start !== 0) {
              this.d_tableModeSettings.start -= this.d_tableModeSettings.perpage;
              this.d_tableModeSettings.end -= this.d_tableModeSettings.perpage;
              this.$forceUpdate();
            }
          }
        }
      };
      setTimeout(function () {
        // console.log(document.getElementById('case_table'));
        if (document.getElementById('case_table')) {
          var div = document.getElementById("case_table");
          div.addEventListener("scroll", handler);
        }
      }, 50);
    },
    f_prepareStatisticalText: function (type) {
      if (!this.d_selectedProjectData.data) {
        this.d_selectedProjectData.data = {};
      }
      this.d_selectedProjectData.data.article_summary = {
        'purpose': { 'val': '' },
        'material_methods': { 'val': '' },
        'caption': { 'val': '' },
        'result': { 'val': '' },
        'keywords': { 'val': '' },
        'conclusion': { 'val': '' }
      };
      this.d_selectedProjectData.data.article = {
        'introduction': { 'val': '' },
        'material_and_methods': { 'val': '' },
        'material_and_methods_statistics': { 'val': '' },
        'results': { 'val': '' },
        'material_and_methods_case_selection': { 'val': '' },
        'discussion': { 'val': '' }
      };
      if (type === 'all') {
        this.d_selectedProjectData.data.article_summary.material_methods.val += 'Bu çalışmaya toplam' + this.d_dataset.total_cases.toString() + ' vaka dahil edilmiştir.';
        this.d_selectedProjectData.data.article.material_and_methods.val += 'Bu çalışmaya toplam' + this.d_dataset.total_cases.toString() + ' vaka dahil edilmiştir.';
        let input_txt = '';
        let output_txt = '';
        for (let i in this.column_list) {
          if (this.column_list[i].io && this.column_list[i].io === 'output') {
            if (this.column_list[i].loc) {
              output_txt += this.column_list[i].label + ',';
            }
          } else if (this.column_list[i].io && this.column_list[i].io === 'input') {
            if (this.column_list[i].loc) {
              input_txt += this.column_list[i].label + ',';
            }
          }
        }
        if (input_txt && output_txt) {
          this.d_selectedProjectData.data.article_summary.purpose.val = input_txt + ' verilerinin' + output_txt + ' verileri üzerine etkisi değerlendirilmesi amaçlanmıştır.';
        }
        let analyze_models = {
          'cross_columns': 'Karşılaştırmalı değerlendirme',
          'mean': 'Ortalama',
          'median': 'Medyan',
          'frequency': 'Sıklık'
        };
        let statistic_models = '';
        let statistic_results = '';
        for (let i in this.d_dataset.analyze) {
          if (statistic_results) {
            statistic_results += '\n';
          }
          if (analyze_models[this.d_dataset.analyze[i].analyze_type] && statistic_models.indexOf(analyze_models[this.d_dataset.analyze[i].analyze_type]) === -1) {
            if (statistic_models) {
              statistic_models += ',' + analyze_models[this.d_dataset.analyze[i].analyze_type];
            } else {
              statistic_models = analyze_models[this.d_dataset.analyze[i].analyze_type];
            }
          }
          if (this.d_dataset.analyze[i].analyze_type === 'cross_columns') {
            let row_parameter_list_text = '';
            let column_parameter_list_text = '';
            for (let r in this.d_dataset.analyze[i].row_parameter_list) {
              row_parameter_list_text += this.d_dataset.analyze[i].row_parameter_list[r].loc[this.d_dataset.analyze[i].row_parameter_list[r].loc.length - 1].label + ',';
            }
            for (let r in this.d_dataset.analyze[i].row_parameter_list) {
              column_parameter_list_text += this.d_dataset.analyze[i].column_parameter_list[r].loc[this.d_dataset.analyze[i].column_parameter_list[r].loc.length - 1].label + ',';
            }
            if (row_parameter_list_text && column_parameter_list_text) {
              statistic_results += '\n' + row_parameter_list_text + ' verisi' + column_parameter_list_text + ' verisiyle karşılaştırmalı olarak değerlendirildi.';
            }
            for (let x in this.d_dataset.analyze[i].cross_row_column_data_list) {
              for (let p in this.d_dataset.analyze[i].cross_row_column_data_list[x].parameters) {
                statistic_results += p;
                for (let z in this.d_dataset.analyze[i].cross_row_column_data_list[x].parameters[p]) {
                  statistic_results += ',' + z + ': %' + this.d_dataset.analyze[i].cross_row_column_data_list[x].parameters[p][z]['percent'].toFixed(1).toString();
                }
              }
            }
          } else if (this.d_dataset.analyze[i].analyze_type === 'mean') {
            for (let c in this.d_dataset.analyze[i].analyze_column_list) {
              statistic_results += '\n' + this.d_dataset.analyze[i].analyze_column_list[c].label + ' ortalaması' + this.d_dataset.analyze[i].analyze_column_list[c].mean.toFixed(1).toString() + ' (min:' + this.d_dataset.analyze[i].analyze_column_list[c].min.toFixed(1).toString() + ' - max:' + this.d_dataset.analyze[i].analyze_column_list[c].max.toFixed(1).toString() + ') olarak tespit edildi.';
            }
          } else if (this.d_dataset.analyze[i].analyze_type === 'median') {
            for (let c in this.d_dataset.analyze[i].analyze_column_list) {
              statistic_results += '\n' + this.d_dataset.analyze[i].analyze_column_list[c].label + ' medyan değeri' + this.d_dataset.analyze[i].analyze_column_list[c].median.toFixed(1).toString() + ' (min:' + this.d_dataset.analyze[i].analyze_column_list[c].min.toFixed(1).toString() + ' - max:' + this.d_dataset.analyze[i].analyze_column_list[c].max.toFixed(1).toString() + ') olarak tespit edildi.';
            }
          } else if (this.d_dataset.analyze[i].analyze_type === 'frequency') {
            for (let c in this.d_dataset.analyze[i].analyze_column_list) {
              statistic_results += '\n' + this.d_dataset.analyze[i].analyze_column_list[c].label + ' sıklık değerlendirmesi;';
              for (let val in this.d_dataset.analyze[i].analyze_column_list[c].val_frequency_data) {
                statistic_results += '\n' + val + ': toplam=' + this.d_dataset.analyze[i].analyze_column_list[c].val_frequency_data[val].total + ' .';
                statistic_results += '(%' + this.d_dataset.analyze[i].analyze_column_list[c].val_frequency_data[val].valid_percent.toFixed(1).toString() + ').';
              }

            }
          }
        }
        this.d_selectedProjectData.data.article.material_and_methods_statistics.val += statistic_models + ' istatistiksel modelleri analizlerde kulanıldı. Wisdom Stats 1.3.41 Programı Analizlerde Kullanıldı.';
        this.d_selectedProjectData.data.article.results.val += statistic_results;
      }
      let loading_msg = 'Makaleye aktarım işlemi gerçekleştiriliyor. Lütfen bekleyiniz.';
      this.f_savePoleStarProject(loading_msg);
    },
    f_showChartViewFromTable: function (table, chart_type, use_last_row = true, use_last_column = false) {
      this.chart_data = {
        labels: [],
        backgroundColor: "#fff",
        datasets: []
      };
      // when cross columns table contains a total row at the end of the table. So we dont get it
      let dataset_length = 0;
      if (table.length > 0) {
        let first_row_length = table[0].length;
        if (use_last_column) {
          dataset_length = first_row_length - 1;
        } else {
          dataset_length = first_row_length - 2;
        }
      }
      for (let d = 0; d < dataset_length; d++) {
        let new_dataset = {};
        if (chart_type === 'line') {
          new_dataset = {
            label: table[0][d + 1],
            lineTension: 0,
            borderColor: this.f_calculateRGBA(),
            backgroundColor: 'rgba(255, 255, 255, 0)',
            showLine: true,
            data: []
          };
        } else if (chart_type === 'bar') {
          let color = this.f_calculateRGBA();
          new_dataset = {
            label: table[0][d + 1],
            backgroundColor: color,
            borderColor: color,
            data: []
          };
        }
        this.chart_data.datasets.push(new_dataset);
      }
      for (let r in table) {
        // First row does not be taken, because this row includes column names
        let row_index = parseInt(r);
        if (row_index > 0) {
          if ((row_index !== table.length - 1) || ((row_index === table.length - 1) && use_last_row)) {
            for (let c in table[row_index]) {
              let col_index = parseInt(c);
              let cell_data = table[row_index][col_index];
              if (col_index === 0) {
                this.chart_data.labels.push(cell_data);
              } else {
                if (col_index !== table[row_index].length - 1) {
                  this.chart_data.datasets[col_index - 1].data.push(cell_data);
                } else {
                  if (use_last_column) {
                    this.chart_data.datasets[col_index - 1].data.push(cell_data);
                  }
                }
              }
            }
          }
        }
      }
      this.d_resetChartData = JSON.parse(JSON.stringify(this.chart_data));
      this.d_chartSize.chart_type = chart_type;
      this.d_chartSize.x_mode = 'standart';
      this.d_chartSize.y_mode = 'standart';
      this.d_chartSize.x_interval_type = 'all';
      this.d_chartSize.height = window.innerHeight - 200;
      this.d_chartSize.width = window.innerWidth - 100;
      this.d_showGraphicView = true;
    },
    f_showChartView: function (type, data) {
      this.chart_data = {
        labels: [],
        datasets: []
      };
      if (type === 'val_frequency_data') {
        let label = data.type === 'wdm' ? data.loc[data.loc.length - 1].label : data.label;
        let k = { 'label': label, 'backgroundColor': 'rgba(255, 0, 0, 0.1)', 'data': [] };
        for (let i in data.val_frequency_data) {
          this.chart_data.labels.push(i);
          k.data.push(data.val_frequency_data[i].total);
        }
        this.chart_data.datasets.push(k);
      } else if (type === 'cross_row_column_data_list') {
        for (let r in data) {
          this.chart_data.labels.push(r);
          for (let c in data[r]) {
            let det = false;
            for (let d in this.chart_data.datasets) {
              if (this.chart_data.datasets[d].label === c) {
                det = true;
                this.chart_data.datasets[d].data.push(data[r][c].total);
                break;
              }
            }
            if (!det) {
              let new_dataset = { 'label': c, 'data': [], 'backgroundColor': this.f_calculateRGBA() };
              new_dataset.data.push(data[r][c].total);
              this.chart_data.datasets.push(new_dataset);
            }
          }
        }
      } else if (type === 'two_p1_and_number_val_list') {
        /*
          data =
          {
           'pr1': {'val_list': [1,2,3,4,5,7] },
           'pr2': {'val_list': [1,2,3,4,5,7] }
          }
        */
        console.log(Object.keys(data));
        let pr_first = Object.keys(data)[0];
        if (pr_first) {
          for (let i in data[pr_first].val_list) {
            this.chart_data.labels.push('');
          }
          for (let pr in data) {
            let new_dataset = {
              label: pr,
              lineTension: 0,
              borderColor: this.f_calculateRGBA(),
              backgroundColor: 'rgba(255, 255, 255, 0)',
              showLine: true,
              data: []
            };
            for (let v in data[pr].val_list) {
              new_dataset.data.push(data[pr].val_list[v]);
            }
            this.chart_data.datasets.push(new_dataset);
          }
        }
      }
      this.d_resetChartData = JSON.parse(JSON.stringify(this.chart_data));
      this.d_chartSize.chart_type = 'line';
      this.d_chartSize.x_mode = 'standart';
      this.d_chartSize.y_mode = 'standart';
      this.d_chartSize.x_interval_type = 'all';
      this.d_chartSize.height = window.innerHeight - 200;
      this.d_chartSize.width = window.innerWidth - 100;
      this.d_showGraphicView = true;
    },
    f_calculateRGBA: function () {
      let r = Math.floor(Math.random() * 256);
      let g = Math.floor(Math.random() * 256);
      let b = Math.floor(Math.random() * 256);
      return 'rgba(' + r.toString() + ',' + g.toString() + ',' + b.toString() + ', 1)';
    },
    f_goToProjectArticle: function (project_id) {
      this.$router.push({ path: '/polestar/article', query: { project_id: project_id } });
    },
    f_deleteModel: function (analyze_ind) {
      this.d_dataset.analyze.splice(analyze_ind, 1);
      this.d_selectedAnalyzeResultInd = '';
      this.$forceUpdate();
    },
    f_isBdropdownVisible: function (col_ind) {
      this.d_filterColumnVal = col_ind;
      this.$forceUpdate();
    },
    f_selectAnalyzeResult: function (result_ind) {
      this.d_selectedAnalyzeResultInd = result_ind;
    },
    f_saveWdm72: function () {
      let return_data = ClsWdmrForever.turn(this.d_statisticWisdomData, this.option_data['wdm72'], ['name', 'delete']);
      let wdmr_name = return_data.name;
      // console.log('4588');
      // this.d_statisticWisdomData = return_data.wdmr;
      // this.$forceUpdate();
      if (wdmr_name) {
        this.d_statisticWisdomData.label = wdmr_name;
      }
      let wdmr_require_alerts = return_data.wdmr_require_alerts;
      let only_one_param_is_filled = return_data.only_one_param_is_filled;
      if (!only_one_param_is_filled) {
        let modal_data = {
          'type': 'alert',
          'text': 'Veri modelini kaydedebilmek için en az bir veriniz dolu olmalıdır.',
          'centered': true,
          'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.'
        };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      if (wdmr_require_alerts && wdmr_require_alerts.length > 0) {
        let require_txt = '';
        for (let r in wdmr_require_alerts) {
          require_txt += ' *' + wdmr_require_alerts[r].label;
        }
        let modal_data = {
          'type': 'alert',
          'text': require_txt,
          'centered': true,
          'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.'
        };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      let data = {};
      data = { 'data': this.d_statisticWisdomData };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kayıt yapılıyor. Lütfen bekleyiniz.' } });
      PoleStarService.save_statistics(data).then(resp => {
        if (resp.data.status === "success") {
          this.d_selectedProjectData = resp.data.result.project_data;
          this.d_selectedWdm72Data = resp.data.result.wdm72_data;
          this.d_showStatisticsModal = false;
          this.f_wisdomDataShowChange();
          this.$store.commit('Loading', { 'status': false, 'data': {} });
        } else {
          let modal_data = { 'type': 'alert', 'text': resp.data.message, 'centered': true, 'title': this.$t('wdm16.13274') };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        }
        this.$forceUpdate();
      });

    },
    f_saveWdm163: function () {
      let return_data = ClsWdmrForever.turn(this.d_machineLearningData.wisdom_data, this.option_data['wdm163'], ['name', 'delete']);
      let wdmr_name = return_data.name;
      if (wdmr_name) {
        this.d_machineLearningData.wisdom_data.label = wdmr_name;
      }
      let wdmr_require_alerts = return_data.wdmr_require_alerts;
      let only_one_param_is_filled = return_data.only_one_param_is_filled;
      if (!only_one_param_is_filled) {
        let modal_data = {
          'type': 'alert',
          'text': 'Veri modelini kaydedebilmek için en az bir veriniz dolu olmalıdır.',
          'centered': true,
          'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.'
        };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      if (wdmr_require_alerts && wdmr_require_alerts.length > 0) {
        let require_txt = '';
        for (let r in wdmr_require_alerts) {
          require_txt += ' *' + wdmr_require_alerts[r].label;
        }
        let modal_data = {
          'type': 'alert',
          'text': require_txt,
          'centered': true,
          'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.'
        };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      let data = {};
      data = { 'data': this.d_machineLearningData.wisdom_data };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kayıt yapılıyor. Lütfen bekleyiniz.' } });
      MachineLearningService.save(data).then(resp => {
        if (resp.data.status === "success") {
          this.d_selectedProjectData = resp.data.result.project_data;
          this.d_selectedMachineLearningData = resp.data.result.wdm163_data;
          this.d_machineLearningData.show = false;
          this.f_wisdomDataShowChange();
          this.$store.commit('Loading', { 'status': false, 'data': {} });
        } else {
          let modal_data = { 'type': 'alert', 'text': resp.data.message, 'centered': true, 'title': this.$t('wdm16.13274') };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        }
        this.$forceUpdate();
      });

    },
    f_saveWdm73Data: function () {
      let data = { 'data': this.d_selectedWdm73Data };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kayıt yapılıyor. Lütfen bekleyiniz.' } });
      PoleStarService.save_wdm73(data)
        .then(resp => {
          if (resp.data.status === "success") {
            this.d_selectedWdm73Data = resp.data.result.wdm73_data;
            this.f_wisdomDataShowChange();
            this.$store.commit('Loading', { 'status': false, 'data': {} });
          } else {
            let modal_data = { 'type': 'alert', 'text': resp.data.message, 'centered': true, 'title': this.$t('wdm16.13274') };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          }
          this.$forceUpdate();
        });
    },
    f_saveWdm73: function () {
      let return_data = ClsWdmrForever.turn(this.d_wdm73WisdomData, this.option_data['wdm73'], ['name', 'delete']);
      let wdmr_name = return_data.name;
      if (wdmr_name) {
        this.d_wdm73WisdomData.label = wdmr_name;
      }
      let wdmr_require_alerts = return_data.wdmr_require_alerts;
      let only_one_param_is_filled = return_data.only_one_param_is_filled;
      if (!only_one_param_is_filled) {
        let modal_data = {
          'type': 'alert',
          'text': 'Veri modelini kaydedebilmek için en az bir veriniz dolu olmalıdır.',
          'centered': true,
          'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.'
        };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      if (wdmr_require_alerts && wdmr_require_alerts.length > 0) {
        let require_txt = '';
        for (let r in wdmr_require_alerts) {
          require_txt += ' *' + wdmr_require_alerts[r].label;
        }
        let modal_data = {
          'type': 'alert',
          'text': require_txt,
          'centered': true,
          'title': 'Veri modeli kayıt işlemi zorunlu alanları tamamlayınız.'
        };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        return;
      }
      let data = {};
      data = { 'data': this.d_wdm73WisdomData };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kayıt yapılıyor. Lütfen bekleyiniz.' } });
      PoleStarService.save_wdm73(data)
        .then(resp => {
          if (resp.data.status === "success") {
            this.d_selectedProjectData = resp.data.result.project_data;
            this.d_selectedWdm73Data = resp.data.result.wdm73_data;
            this.d_showWdm73Modal = false;
            this.f_wisdomDataShowChange();
            this.$store.commit('Loading', { 'status': false, 'data': {} });
          } else {
            let modal_data = { 'type': 'alert', 'text': resp.data.message, 'centered': true, 'title': this.$t('wdm16.13274') };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          }
          this.$forceUpdate();
        });

    },
    f_selectWdm73: function (wdm73_key) {
      this.d_selectedWdm73Key = wdm73_key;
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Lütfen bekleyiniz.' } });
      GlobalService.get_one_cb_document({ 'key': wdm73_key, 'bucket': 'polestar' })
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_selectedWdm73Data = resp.data.result;
            this.$forceUpdate();
          } else {
            alert('error', resp.data.message);
          }
          this.$store.commit('Loading', { 'status': false, 'data': {} });
        });
    },
    f_selectWdm72: function (wdm72_key) {
      this.d_wdm72Pagination = {
        'start': 0,
        'end': 10,
        'current': 1,
        'perpage': 10,
        'all_count': 0
      };
      this.d_filterAnalyzeData = {
        'user_score_list': '',
        'selected_columns': [],
        'filter_analyze_types': [],
        'filter_stats': [],
        'filter_p_value': ['', '0.001', '0.05', '0.06', '0.07', '0.08', '0.09', '0.1'],
        'selected_analyze_types': [],
        'selected_stats': [],
        'show': false,
        'selected_p_value': ''
      };
      this.d_selectedWdm72Key = wdm72_key;
      this.d_navAnalyzeResult = '';
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Lütfen bekleyiniz.' } });
      GlobalService.get_one_cb_document({ 'key': wdm72_key, 'bucket': 'polestar' })
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_navAnalyzeResult = 'analyze_model';
            this.d_selectedWdm72Data = resp.data.result;
            this.d_selectedWdm72Columns = [];
            let x_list = ['selected_row_parameters', 'selected_column_parameters', 'selected_columns'];
            for (let p in x_list) {
              let param = x_list[p];
              try {
                for (let i in this.d_selectedWdm72Data.data.general[param]) {
                  let det = false;
                  for (let c in this.d_selectedWdm72Columns) {
                    if (this.d_selectedWdm72Columns[c].value === i) {
                      det = true;
                      break;
                    }
                  }
                  if (!det) {
                    let col_data = {
                      'label': this.d_selectedWdm72Data.data.general[param][i].name.label,
                      'value': this.d_selectedWdm72Data.data.general[param][i].name.value
                    }
                    this.d_selectedWdm72Columns.push(col_data);
                  }
                }
              } catch (err) {}
            }
            this.f_getWdm72AnalyzeList();
            this.$forceUpdate();
          } else {
            alert('error', resp.data.message);
          }
        });
    },
    f_getWdm72AnalyzeList: function () {
      let analyze_data = {
        'pagination': this.d_wdm72Pagination,
        'wdm72_key': this.d_selectedWdm72Key,
        'selected_analyze_types': this.d_filterAnalyzeData.selected_analyze_types,
        'selected_p_value': this.d_filterAnalyzeData.selected_p_value,
        'selected_columns': this.d_filterAnalyzeData.selected_columns,
        'selected_stats': this.d_filterAnalyzeData.selected_stats,
        'user_score_list': this.d_filterAnalyzeData.user_score_list ? this.d_filterAnalyzeData.user_score_list : ''
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Lütfen bekleyiniz.' } });
      PoleStarService.get_analyze_data(analyze_data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            this.d_selectedWdm72Results = resp.data.result.analyze_list;
            if (resp.data.result.selected_columns) {
              this.d_filterAnalyzeData.filter_selected_columns = resp.data.result.selected_columns;
            }
            /*
                user_score_list = {
                    'wisdom': {
                        'score_list': {
                            10: {'count': 5, 'selection': 0}
                        }
                    }
                }
            */
            // console.log(resp.data.result.user_score_list);
            if (resp.data.result.user_score_list) {
              if (this.d_filterAnalyzeData.user_score_list) {
                for (let u in resp.data.result.user_score_list) {
                  if (!this.d_filterAnalyzeData.user_score_list[u]) {
                    this.d_filterAnalyzeData.user_score_list[u] = resp.data.result.user_score_list[u];
                  } else {
                    for (let s in resp.data.result.user_score_list[u].score_list) {
                      if (!this.d_filterAnalyzeData.user_score_list[u].score_list[s]) {
                        this.d_filterAnalyzeData.user_score_list[u].score_list[s] = resp.data.result.user_score_list[u].score_list[s];
                      }
                    }
                  }
                }
              } else {
                this.d_filterAnalyzeData.user_score_list = resp.data.result.user_score_list;
              }
            }
            this.d_filterAnalyzeData.filter_stats = resp.data.result.filter_stats;
            this.d_filterAnalyzeData.filter_analyze_types = resp.data.result.filter_analyze_types;
            this.d_wdm72Pagination.all_count = resp.data.result.all_count;
          } else {
            alert('error', resp.data.message);
          }
        });
    },
    f_analyzeStatisticalModel: function (wdm72_key) {
      if (this.d_userPolestarPermissions.indexOf('owner') !== -1 || this.d_userPolestarPermissions.indexOf('admin') !== -1 || this.d_userPolestarPermissions.indexOf('project_add_edit_statistical_analyze') !== -1) {
        if (wdm72_key !== this.d_selectedWdm72Key) {
          this.d_selectedWdm72Data = {};
        }
        this.d_wdm72RefreshedFromSocket = '';
        this.d_selectedWdm72Key = wdm72_key;
        let data = {
          'list': [{
            'wdm3_id': this.d_selectedProjectData.id,
            'wdm72_list_type': 'selected',
            'wdm72_key_list': [this.d_selectedWdm72Key]
          }]
        };
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Analizler yapılıyor. Lütfen bekleyiniz.' } });
        PoleStarService.analyze_model(data)
          .then(resp => {
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.status === 'success') {
              if (resp.data.result && resp.data.result[this.d_selectedProjectData.id] && resp.data.result[this.d_selectedProjectData.id][this.d_selectedWdm72Key]) {
                this.d_selectedWdm72Results = resp.data.result[this.d_selectedProjectData.id][this.d_selectedWdm72Key]['analyze_list'];
                this.d_wdm72Pagination.all_count = resp.data.result[this.d_selectedProjectData.id][this.d_selectedWdm72Key]['all_count'];
              }
              let modal_data = { 'type': 'alert', 'text': 'Analizleriniz başlatılmıştır. Tamamlanınca bilgi verilecektir.', 'centered': true, 'title': 'Kutup Yıldızı Analizleri Hakkında' };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
            } else {
              alert('error:\n' + resp.data.message);
            }
            this.$forceUpdate();
          });
      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_analyze: function () {
      this.d_showStatsSelect = false;
      if (!this.d_dataset.analyze) {
        this.d_dataset.analyze = [];
      }
      let x = {
        'row_parameter_group_type': this.d_selectedAnalyzeModel.row_parameter_group_type,
        'analyze_type': this.d_selectedAnalyzeModel.type,
        'analyze_model': this.d_selectedAnalyzeModel.analyze_model,
        'analyze_column_list': this.analyze_column_list,
        'column_parameter_list': this.column_parameter_list,
        'row_parameter_list': this.row_parameter_list,
        'result': {}
      };
      this.d_dataset.analyze.push(x);
      let analyze_data = {
        'column_list': this.column_list,
        'data': this.d_dataset,
        'analyze_location': 'this_index',
        'main_array_ind': this.d_dataset.analyze.length - 1
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Analizler yapılıyor. Lütfen bekleyiniz.' } });
      PoleStarService.analyze_model(analyze_data)
        .then(resp => {
          if (resp.data.status === 'success') {
            this.d_dataset = resp.data.result;
            this.f_datasetMenuControl();
          } else {
            alert('error:\n' + resp.data.message);
          }
          this.$store.commit('Loading', { 'status': false, 'data': {} });
        });
    },
    f_datasetMenuControl: function () {
      if (!this.d_dataset.menu) {
        this.d_dataset.menu = { 'data': {} };
      } else if (!this.d_dataset.menu.data) {
        this.d_dataset.menu.data = {};
      }
      if (!this.d_dataset.menu.data.cell_selection_mode) {
        this.d_dataset.menu.data.cell_selection_mode = {
          'status': 'one_cell_select_mode'
        }
      }
    },
    f_prepareWdm73: function () {
      if (this.d_userPolestarPermissions.indexOf('owner') !== -1 || this.d_userPolestarPermissions.indexOf('admin') !== -1 || this.d_userPolestarPermissions.indexOf('project_article_add_edit') !== -1) {
        let pre_data = {
          'id': 'new',
          'data': {},
          'type': 'wdm73',
          'reference_wdm': {},
          'wdm3_id': this.d_selectedProjectData.id
        };
        pre_data['reference_wdm']['version'] = this.option_data['wdm72']['version'];
        pre_data['reference_wdm']['owner_type'] = this.option_data['wdm72']['owner_type'];
        if (this.option_data['wdm72']['owner_type'] === 'wisdom') {
          pre_data['reference_wdm']['key'] = 'wdm_wisdom_wdm72_v' + this.option_data['wdm72'].version;
        }
        for (let i in this.option_data['wdm73'].param_group.param_order) {
          pre_data['data'][this.option_data['wdm73'].param_group.param_order[i]] = {};
        }
        pre_data['data']['general']['wdm3_name'] = { 'val': this.d_selectedProjectData.data.general.name.val };
        pre_data['data']['general']['wdm3_key'] = { 'val': 'wdm3_' + this.d_selectedProjectData.id };
        this.d_wdm73WisdomData = pre_data;
        this.d_showWdm73Modal = true;
        // this.d_showStatsSelect = true;
        this.$store.commit('Loading', { 'status': false, 'data': {} });
      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_addColumnsByParamTypes: function (param_types) {
      let selected_columns = {};
      for (let c in this.column_list) {
        if ((param_types === 'all' && ['select', 'checkbox', 'radiogroup', 'object_list_options', 'integer', 'float'].indexOf(this.column_list[c].param_type) !== -1) || param_types.indexOf(this.column_list[c].param_type) !== -1) {
          let lbl = this.column_list[c].label + '( no:' + c + ' ) -' + this.column_list[c].param_type + ' -';
          // We took the column index in value. So when changing we can use value field.
          let x = { 'label': lbl, 'value': c.toString(), 'param_type': this.column_list[c].param_type }
          selected_columns[c] = { 'detail': {}, 'name': x };
          selected_columns[c]['detail'] = {
            'param_type_usage': {
              'val': {
                'value': 'auto',
                'label': 'Otomatik'
              }
            },
            'option_group_type': {
              'val': {
                'value': 'every_option_itself',
                'label': 'Her bir seçenek ayrı ayrı'
              }
            },
            'selected_column_timeline_mode': {
              'val': {
                'value': 'no',
                'label': 'hayır'
              }
            }
          }
        }
      }
      return selected_columns;
    },
    f_selectViewMode: function (view_mode) {
      this.d_showMode = view_mode;
      this.$forceUpdate();
    },
    f_sortColumn: function (col_ind) {
      alert('Geliştirme aşamasında');
    },
    f_convertSelectedDataSetToExcel: function () {
      let data = { 'column_list': [], 'dataset': this.d_dataset.case_rows };
      for (let c in this.column_list) {
        if (this.column_list[c].type === 'wdm') {
          data.column_list.push(this.column_list[c].loc[this.column_list[c].loc.length - 1].label);
        } else {
          data.column_list.push(this.column_list[c].label);
        }
      }
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Excel dökümanınız hazırlanıyor. Lütfen bekleyiniz.' } });
      FiletransferService.convert_dataset_to_excel(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          if (resp.data.status === 'success') {
            FileSaver.saveAs(resp.data.result.file, "dataset.xlsx");
          } else {
            alert('errror:', resp.data.message);
          }
        })
    },
    f_convertSelectedTableToExcel: function (table) {
      let data = { 'column_list': [], 'dataset': table };
      FiletransferService.convert_dataset_to_excel(data)
        .then(resp => {
          if (resp.data.status === 'success') {
            FileSaver.saveAs(resp.data.result.file, "table.xlsx");
          } else {
            alert('errror:', resp.data.message);
          }
        })
    },
    f_dataSetStyle: function (x) {
      let height = 0;
      if (this.d_fullScreenGeneral) {
        x -= 60;
      }
      if (this.d_fullScreen) {
        height = window.innerHeight;
      } else {
        height = window.innerHeight - x;
      }
      let overflow_x = 'auto';
      if (this.d_showMode === 'analyze_results') {
        overflow_x = 'hidden';
      }
      return 'overflow-y: auto; height:' + height.toString() + 'px; overflow-x:' + overflow_x + '; margin-bottom: 0px; border: none;';
    },
    f_calculateAnalyzeModelListStyle: function () {
      let height = 0;
      if (this.d_fullScreen) {
        height = window.innerHeight - 140;
      } else {
        height = window.innerHeight - 360;
      }
      return 'overflow-y: auto; height:' + height.toString() + 'px; overflow-x: hidden; margin-bottom: 0px;';
    },
    f_calculateAnalyzeModelDetailsStyle: function () {
      let height = 0;
      if (this.d_fullScreen) {
        height = window.innerHeight - 155;
      } else {
        height = window.innerHeight - 380;
      }
      return 'overflow-y: auto; height:' + height.toString() + 'px; overflow-x: hidden; margin-bottom: 0px;';
    },
    f_calculateAnalyzeResultsStyle: function () {
      let height = 0;
      if (this.d_fullScreenResults) {
        height = window.innerHeight - 100;
      } else if (this.d_fullScreen) {
        height = window.innerHeight - 170;
      } else {
        height = window.innerHeight - 390;
      }
      return 'overflow-y: auto; height:' + height.toString() + 'px; overflow-x: hidden; margin-bottom: 0px;';
    },
    f_selectMenu: function (menu) {
      this.d_selectedMenu = menu.value;
    },
    DateFormat: function (date) {
      return moment(date, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');
    },
    DateFormatWithHourMin: function (date) {
      if (date) {
        return moment(date).format('DD/MM/YYYY HH:mm');
      } else {
        return 'yok';
      }
    },
    f_downloadExcelFile: function (file) {
      FileSaver.saveAs(file, "cases.xlsx");
    },
    f_cancelProjectUpdate: function () {
      if (this.d_userPolestarPermissions.indexOf('owner') !== -1 || this.d_userPolestarPermissions.indexOf('admin') !== -1 || this.d_userPolestarPermissions.indexOf('project_general_info_edit') !== -1) {
        let msg = '';
        let project_data_type = '';
        try {
          project_data_type = this.d_selectedProjectData.data.general.project_data_type.val.value;
        } catch (err) {}

        if (project_data_type === 'layer') {
          msg = 'Lütfen en az bir adet wdm kolonu seçimi yapınız. Ana kuralınız yok ise lütfen hazırlayınız.';
        } else if (project_data_type === 'mssql') {
          msg = 'Lütfen sql bilgilerini proje ayarlarında doldurunuz.';
        }

        let x = this.d_eligibleForCaseSearch;
        if (x) {
          let txt = '';
          let txt_html = '';
          txt_html += '<div style="color: red;"> <strong> Aktif proje veri güncelleme işlemi son adımı tamamlanarak durdurulacaktır. </strong> </div>';
          txt_html += '<div style="color: red;"> <strong> Devam etmek istiyor musunuz ? </strong> </div>';
          // console.log(txt_html);
          let modal_data = {
            'style': 'text-align: left;',
            'size': 'lg',
            'type': 'confirm',
            'text': txt,
            'text_html': txt_html,
            'centered': false,
            'title': 'Proje veri güncelleme iptal işlemi hakkında',
            'function_name': 'f_cancelProjectUpdate_confirmed',
            'confirm': false,
            'arguments': []
          };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        } else {
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Veri güncelleme iptal işlemi' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        }
      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }

    },
    f_cancelProjectUpdate_confirmed: function (args) {
      let data = {
        'wdm3_id': this.d_selectedProjectData.id
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Proje veri güncelleme iptal ediliyor. Lütfen bekleyiniz.' } });
      PoleStarService.prepare_project_cases_cancelled(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          let msg = '';
          if (resp.data.status === 'success') {
            msg = 'İptal işleminiz başlamıştır.';
            this.d_selectedProjectData['data']['general']['data_updating_status'] = {
              "val": {
                "label": "İptal Edildi",
                "value": "cancelled"
              }
            };
            this.$forceUpdate();
            this.d_dataset = { 'excel_file': '', 'case_rows': [], 'case_columns': [], 'cell_features': [], 'column_val_list': [] };
          } else {
            let msg = resp.data.message;
          }
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Verilerin hazırlanması işlemi hakkında' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        });
    },
    f_prepareProjectCases: function (type, start_type='manuel') {
      if (this.d_userPolestarPermissions.indexOf('owner') !== -1 || this.d_userPolestarPermissions.indexOf('admin') !== -1 || this.d_userPolestarPermissions.indexOf('project_general_info_edit') !== -1) {
          let msg = '';
          let project_data_type = '';
          try {
            project_data_type = this.d_selectedProjectData.data.general.project_data_type.val.value;
          } catch (err) {}

          if (project_data_type === 'layer') {
            msg = 'Lütfen en az bir adet wdm kolonu seçimi yapınız. Ana kuralınız yok ise lütfen hazırlayınız.';
          } else if (project_data_type === 'mssql') {
            msg = 'Lütfen sql bilgilerini proje ayarlarında doldurunuz.';
          }

          let x = this.d_eligibleForCaseSearch;
          // let y = this.d_selectedProjectData.layer_main_rules && Object.keys(this.d_selectedProjectData.layer_main_rules).length > 0;
          if (x) {
            let txt = '';
            let txt_html = '';
            if (type === 'delete_all_and_recreate') {
              txt_html += '<div style="color: blue; font-size: 20px; font-weight: bold; text-align: center;"> --- 1 --- </div>';
              txt_html += '<div><strong style="color: blue;"> Veri Güncelleme Algoritması </strong></div>';

              txt_html += '<div><strong> * Tüm dataseti temizler : </strong> <span style="color: green; font-weight: bold;"> Evet </span> </div>';
              txt_html += '<div><strong> * Ana kurala uygun olan yeni satırlar tespit edilirse eklenir : </strong> <span style="color: green; font-weight: bold;"> Evet </span> </div>';
              txt_html += '<div><strong> * Yeni satır eklerken mevcut datasetteki aynı satır ID varsa eklenir : </strong> <span style="color: red; font-weight: bold;"> Hayır </span> </div>';
              txt_html += '<div><strong> * Mevcut dataset verilerini günceller : </strong> <span style="color: red; font-weight: bold;"> Hayır </span> </div>';
              txt_html += '<div><strong> * Ana algoritma kuralı ile datasette mevcut kayıtlı satırın projeye uygunluğunu denetler : </strong> <span style="color: red; font-weight: bold;"> Hayır </span> </div>';
              txt_html += '<div><strong> * Algoritma kolon analiz verilerini temizler : </strong> <span style="color: green; font-weight: bold;"> Evet </span> </div>';
            } else if (type === 'only_add_new_cases') {
              txt_html += '<div style="color: blue; font-size: 20px; font-weight: bold; text-align: center;"> --- 2 --- </div>';
              txt_html += '<div><strong style="color: blue;"> Veri Güncelleme Algoritması </strong></div>';

              txt_html += '<div><strong> * Tüm dataseti temizler : </strong> <span style="color: red; font-weight: bold;"> Hayır </span> </div>';
              txt_html += '<div><strong> * Ana kurala uygun olan yeni satırlar tespit edilirse eklenir : </strong> <span style="color: green; font-weight: bold;"> Evet </span> </div>';
              txt_html += '<div><strong> * Yeni satır eklerken mevcut datasetteki aynı satır ID varsa eklenir : </strong> <span style="color: red; font-weight: bold;"> Hayır </span> </div>';
              txt_html += '<div><strong> * Mevcut dataset verilerini günceller : </strong> <span style="color: red; font-weight: bold;"> Hayır </span> </div>';
              txt_html += '<div><strong> * Ana algoritma kuralı ile datasette mevcut kayıtlı satırın projeye uygunluğunu denetler : </strong> <span style="color: red; font-weight: bold;"> Hayır </span> </div>';
              txt_html += '<div><strong> * Algoritma kolon analiz verilerini temizler : </strong> <span style="color: red; font-weight: bold;"> Hayır </span> </div>';
            } else if (type === 'delete_manuel_edit') {
              txt_html += '<div style="color: blue; font-size: 20px; font-weight: bold; text-align: center;"> --- 3 --- </div>';
              txt_html += '<div><strong style="color: blue;"> Veri Güncelleme Algoritması </strong></div>';

              txt_html += '<div><strong> * Tüm dataseti temizler : </strong> <span style="color: red; font-weight: bold;"> Hayır </span> </div>';
              txt_html += '<div><strong> * Ana kurala uygun olan yeni satırlar tespit edilirse eklenir : </strong> <span style="color: red; font-weight: bold;"> Hayır </span> </div>';
              txt_html += '<div><strong> * Yeni satır eklerken mevcut datasetteki aynı satır ID varsa eklenir : </strong> <span style="color: red; font-weight: bold;"> Hayır </span> </div>';
              txt_html += '<div><strong> * Mevcut dataset verilerini günceller : </strong> <span style="color: green; font-weight: bold;"> Evet </span> </div>';
              txt_html += '<div><strong> * Ana algoritma kuralı ile datasette mevcut kayıtlı satırın projeye uygunluğunu denetler : </strong> <span style="color: red; font-weight: bold;"> Hayır </span> </div>';
              txt_html += '<div><strong> * Algoritma kolon analiz verilerini temizler : </strong> <span style="color: green; font-weight: bold;"> Evet </span> </div>';
            } else if (type === 'delete_manuel_edit_not_delete_algorithm_columns') {
              txt_html += '<div style="color: blue; font-size: 20px; font-weight: bold; text-align: center;"> --- 4 --- </div>';
              txt_html += '<div><strong style="color: blue;"> Veri Güncelleme Algoritması </strong></div>';

              txt_html += '<div><strong> * Tüm dataseti temizler : </strong> <span style="color: red; font-weight: bold;"> Hayır </span> </div>';
              txt_html += '<div><strong> * Ana kurala uygun olan yeni satırlar tespit edilirse eklenir : </strong> <span style="color: red; font-weight: bold;"> Hayır </span> </div>';
              txt_html += '<div><strong> * Yeni satır eklerken mevcut datasetteki aynı satır ID varsa eklenir : </strong> <span style="color: red; font-weight: bold;"> Hayır </span> </div>';
              txt_html += '<div><strong> * Mevcut dataset verilerini günceller : </strong> <span style="color: green; font-weight: bold;"> Evet </span> </div>';
              txt_html += '<div><strong> * Ana algoritma kuralı ile datasette mevcut kayıtlı satırın projeye uygunluğunu denetler : </strong> <span style="color: red; font-weight: bold;"> Hayır </span> </div>';
              txt_html += '<div><strong> * Algoritma kolon analiz verilerini temizler : </strong> <span style="color: red; font-weight: bold;"> Hayır </span> </div>';
            } else if (type === 'update_dataset_all_cells_add_new_data') {
              txt_html += '<div style="color: blue; font-size: 20px; font-weight: bold; text-align: center;"> --- 5 --- </div>';
              txt_html += '<div><strong style="color: blue;"> Veri Güncelleme Algoritması </strong></div>';

              txt_html += '<div><strong> * Tüm dataseti temizler : </strong> <span style="color: red; font-weight: bold;"> Hayır </span> </div>';
              txt_html += '<div><strong> * Ana kurala uygun olan yeni satırlar tespit edilirse eklenir : </strong> <span style="color: green; font-weight: bold;"> Evet </span> </div>';
              txt_html += '<div><strong> * Yeni satır eklerken mevcut datasetteki aynı satır ID varsa eklenir : </strong> <span style="color: red; font-weight: bold;"> Hayır </span> </div>';
              txt_html += '<div><strong> * Mevcut dataset verilerini günceller : </strong> <span style="color: green; font-weight: bold;"> Evet </span> </div>';
              txt_html += '<div><strong> * Ana algoritma kuralı ile datasette mevcut kayıtlı satırın projeye uygunluğunu denetler : </strong> <span style="color: red; font-weight: bold;"> Hayır </span> </div>';
              txt_html += '<div><strong> * Algoritma kolon analiz verilerini temizler : </strong> <span style="color: green; font-weight: bold;"> Evet </span> </div>';
            } else if (type === 'update_dataset_all_cells_add_new_data_not_delete_algorithm_columns') {
              txt_html += '<div style="color: blue; font-size: 20px; font-weight: bold; text-align: center;"> --- 6 --- </div>';
              txt_html += '<div><strong style="color: blue;"> Veri Güncelleme Algoritması </strong></div>';

              txt_html += '<div><strong> * Tüm dataseti temizler : </strong> <span style="color: red; font-weight: bold;"> Hayır </span> </div>';
              txt_html += '<div><strong> * Ana kurala uygun olan yeni satırlar tespit edilirse eklenir : </strong> <span style="color: green; font-weight: bold;"> Evet </span> </div>';
              txt_html += '<div><strong> * Yeni satır eklerken mevcut datasetteki aynı satır ID varsa eklenir : </strong> <span style="color: red; font-weight: bold;"> Hayır </span> </div>';
              txt_html += '<div><strong> * Mevcut dataset verilerini günceller : </strong> <span style="color: green; font-weight: bold;"> Evet </span> </div>';
              txt_html += '<div><strong> * Ana algoritma kuralı ile datasette mevcut kayıtlı satırın projeye uygunluğunu denetler : </strong> <span style="color: red; font-weight: bold;"> Hayır </span> </div>';
              txt_html += '<div><strong> * Algoritma kolon analiz verilerini temizler : </strong> <span style="color: red; font-weight: bold;"> Hayır </span> </div>';
            } else if (type === 'calculate_case_count') {
              txt_html += '<div style="color: blue; font-size: 20px; font-weight: bold; text-align: center;"> --- 6 --- </div>';
              txt_html += '<div><strong style="color: blue;"> Veri Güncelleme Algoritması </strong></div>';
              txt_html += '<div><strong> * Ana kurala uygun olan satır adedi hesaplanacak.</div>';
            }

            txt_html += '<div><strong style="color: blue;"> Proje Ayarları, Veri güncelleme sonrası operasyonlar </strong></div>';
            try {
              let k = this.d_selectedProjectData.data.general.layer_data_usage_type_while_updating_dataset.val.label;
              txt_html += '<div><strong> * Katman verisi kullanım tipi : </strong>' + k + '</div>';
            } catch (err) {
              txt_html += '<div><strong> * Katman verisi kullanım tipi : </strong> - </div>';
            }

            try {
              let k = this.d_selectedProjectData.data.settings.auto_data_update_algorithm_run.val.label;
              txt_html += '<div><strong> * Algoritma kolonları : </strong>' + k + '</div>';
            } catch (err) {
              txt_html += '<div><strong> * Algoritma kolonları : </strong> - ' + '</div>';
            }

            try {
              let k = this.d_selectedProjectData.data.general.case_limit.val.label;
              txt_html += '<div><strong> * Yeni veri ekleme limiti var mı ? : </strong>' + k + '</div>';
            } catch (err) {
              txt_html += '<div><strong> * Yeni veri ekleme limiti var mı ? : </strong> - </div>';
            }

            try {
              let k = this.d_selectedProjectData.data.general.limit.val;
              txt_html += '<div><strong> * Yeni veri ekleme limiti : </strong>' + k + '</div>';
            } catch (err) {
              txt_html += '<div><strong> * Yeni veri ekleme limiti : </strong> - </div>';
            }

            try {
              let k = this.d_selectedProjectData.data.general.new_data_starting_point_type.val.label;
              txt_html += '<div><strong> * Yeni veri ekleme başlangıç noktası belirleme : </strong>' + k + '</div>';
              if (k === 'i_will_define') {
                try {
                  let m = this.d_selectedProjectData.data.general.user_defined_beginning_point.val;
                  txt_html += '<div><strong> * Başlangıç noktası : </strong>' + m + '</div>';
                } catch (err) {
                  txt_html += '<div><strong> * Başlangıç noktası : </strong> - </div>';
                }
              } else {
                txt_html += '<div><strong>*** Not:</strong> </div>';
                txt_html += '<div><strong>*** </strong> Tanımlanan ID datasete dahil edilmemektedir. </div>';
                txt_html += '<div><strong>*** </strong> Sonraki ilk ID ana algoritma kuralınıza uygun ise dahil edilmektedir. </div>';
              }
            } catch (err) {
              txt_html += '<div><strong> * Yeni veri ekleme başlangıç noktası belirleme : </strong> en baştan (default) </div>';
            }

            try {
              let k = this.d_selectedProjectData.data.settings.auto_data_update_stat_models.val.label;
              txt_html += '<div><strong> * İstatistik modelleri : </strong> ' + k + '</div>';
            } catch (err) {
              txt_html += '<div><strong> * Algoritma kolonları : </strong> - ' + '</div>';
            }

            try {
              let k = this.d_selectedProjectData.data.settings.auto_data_update_machine_learning_models.val.label;
              txt_html += '<div><strong> * Makine öğrenmesi modelleri : </strong> ' + k + '</div>';
            } catch (err) {
              txt_html += '<div><strong> * Algoritma kolonları : </strong> - ' + '</div>';
            }
            txt_html += '<div style="color: red;"> <strong> Devam etmek istiyor musunuz ? </strong> </div>';
            // console.log(txt_html);
            let modal_data = {
              'style': 'text-align: left;',
              'size': 'lg',
              'type': 'confirm',
              'text': txt,
              'text_html': txt_html,
              'centered': false,
              'title': 'Proje verilerini hazırlama işlemi hakkında',
              'function_name': 'f_prepareProjectCases_confirmed',
              'confirm': false,
              'arguments': [type, start_type]
            };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          } else {
            let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Verilerin hazırlanması işlemi' };
            this.$store.commit('modal', modal_data);
            this.$store.commit('modal_show', true);
          }
      } else {
        let msg = 'Yetkiniz yok. Lütfen yetkili ile temasa geçiniz';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İşlem hakkında' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
      }
    },
    f_prepareProjectCases_confirmed: function (args) {
      let data = {
        'wdm3_id': this.d_selectedProjectData.id,
        'type': args[0],
        'start_type': args[1],
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Vaka listeniz hazırlanıyor. Lütfen bekleyiniz.' } });
      PoleStarService.prepare_project_cases(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          let msg = '';
          if (resp.data.status === 'success') {
            msg = 'Veri hazırlama işleminiz başlamıştır. Tamamlandığında bilgi verilecektir.';
            this.d_selectedProjectData['data']['general']['data_updating_status'] = {
              "val": {
                "label": "devam ediyor",
                "value": "continuing"
              }
            };
            this.f_controlAndCreateTimer();
            this.$forceUpdate();
            this.d_dataset = { 'excel_file': '', 'case_rows': [], 'case_columns': [], 'cell_features': [], 'column_val_list': [] };
          } else {
            let msg = resp.data.message;
          }
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Verilerin hazırlanması işlemi hakkında' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        });
    },
    f_analyzeColumnOptionRuleEligibility: function () {
      this.d_eligibleForColumnOptionRuleMatch = false;
      for (let i in this.column_list) {
        if (this.column_list[i].options) {
          for (let op_ind in this.column_list[i].options) {
            if (this.column_list[i].options[op_ind].rule && this.column_list[i].options[op_ind].rule.query_list && this.column_list[i].options[op_ind].rule.query_list.length > 0) {
              this.d_eligibleForColumnOptionRuleMatch = true;
              break;
            }
          }
        }
        if (this.d_eligibleForColumnOptionRuleMatch) {
          break;
        }
      }
    },
    f_getCases: function () {
      if (this.d_selectedProjectData.design && this.d_selectedProjectData.design.column_list && this.d_selectedProjectData.design.column_list.length > 0) {
        let data = {
          'project': { 'id': this.d_selectedProjectData.id },
          'pagination': this.d_datasetPagination,
        };
        // this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Vaka listeniz hazırlanıyor. Lütfen bekleyiniz.' } });
        PoleStarService.get_project_cases(data)
          .then(resp => {
            // this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.status === 'success') {
              // WE CREATE COLUMN LIST AGAIN, BECAUSE WE MAY HAVE NEW COLUMNS BY AUTO CREATING
              this.d_selectedProjectData = resp.data.result.wdmr3_data;
              this.column_list = this.d_selectedProjectData.design.column_list;
              this.d_dataset = resp.data.result;
              this.f_datasetMenuControl();
              this.d_datasetKeyList = [];
              for (let i in this.d_dataset.case_id_list) {
                if (this.d_datasetKeyList.indexOf(this.d_dataset.case_id_list[i].dataset_key) === -1) {
                  this.d_datasetKeyList.push(this.d_dataset.case_id_list[i].dataset_key);
                }
              }
              this.f_sendDatasetInfoTosocket();
              // this.f_createColumnOptionList();
              if (this.d_dataset.filter && this.d_dataset.filter[this.user.username]) {
                this.d_columnRule.query_list = this.d_dataset.filter[this.user.username].query_list;
                this.d_columnRule.compile_str_array = this.d_dataset.filter[this.user.username].compile_str_array;
                if (this.d_dataset.filter[this.user.username].compile_str_date) {
                  this.d_columnRule.compile_str_date = this.d_dataset.filter[this.user.username].compile_str_date;
                }
                if (this.d_dataset.filter[this.user.username].compile_str_text) {
                  this.d_columnRule.compile_str_text = this.d_dataset.filter[this.user.username].compile_str_text;
                }
              }
              // this.addEventListenerToTable();
              // this.f_analyzeHimsInspectionEligibility();
              this.f_analyzeWdmrRuleEligibility();
              this.f_analyzeColumnOptionMixedWdmrRuleEligibility();
              this.f_analyzeColumnOptionRuleEligibility();
              this.f_analyzeWdmrRuleSearchEligibility();
              // this.f_analyzeHimsInspectionSearchEligibility();
            } else {
              let msg = resp.data.message;
              let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İstatistik için verilerin hazırlanması işlemi' };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
            }
            this.mother_children.update_column_eligibility = 1;
          });
      } else {
        this.$store.commit('Loading', { 'status': false, 'data': {} });
        let msg = 'Lütfen kolon listenizi hazırlayınız';
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'İstatistik için verilerin hazırlanması işlemi' };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        this.d_dataset = '';
        this.$forceUpdate();
      }
    },
    f_createColumnOptionList: function () {
      for (let c_index in this.d_dataset.column_val_list) {
        if (!this.column_list[c_index].options) {
          this.column_list[c_index].options = [];
        }
        for (let r_index in this.d_dataset.column_val_list[c_index]) {
          let opt_val = this.d_dataset.column_val_list[c_index][r_index];
          if (['select', 'radiogroup', 'object_list_options', 'checkbox'].indexOf(this.column_list[c_index].param_type) !== -1) {
            // console.log(opt_val);
            let det = false;
            for (let opt_ind in this.column_list[c_index].options) {
              if (this.column_list[c_index].options[opt_ind].value === opt_val.value) {
                det = true;
                break;
              }
            }
            if (!det) {
              this.column_list[c_index].options.push(opt_val);
            }
            this.column_list[c_index].options.sort();
          }
        }
        // console.log(this.column_list[c_index].options);
      }
    },
    f_savePoleStarProject: function (loading_msg = 'Kayıt işlemi gerçekleştiriliyor. Lütfen bekleyiniz.') {
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': loading_msg } });
      let data = { 'data': this.d_selectedProjectData };
      PoleStarService.save_polestar_project(data).then(resp => {
        let msg = '';
        this.$store.commit('Loading', { 'status': false, 'data': {} });
        if (resp.data.status === "success") {
          this.d_selectedProjectData = resp.data.result.project;
          msg = 'Proje başarıyla kaydedildi.';
        } else {
          msg = resp.data.message;
        }
        let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': this.$t('wdm16.13274') };
        this.$store.commit('modal', modal_data);
        this.$store.commit('modal_show', true);
        this.d_mainRuleShowModal = false;
        this.$forceUpdate();
      });
    },
    f_getProjectAnalyzeReports: function () {
      if (this.d_selectedProjectData && this.d_selectedProjectData.id) {
        this.d_projectReports.list = [];
        this.$forceUpdate();
        this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Proje analiz raporları getiriliyor. Lütfen bekleyiniz.' } });
        let query = 'project_id=' + this.d_selectedProjectData.id;
        ProjectService.project_get_analyze_reports(query)
          .then(resp => {
            let msg = '';
            this.$store.commit('Loading', { 'status': false, 'data': {} });
            if (resp.data.status_code === "2000") {
              this.d_projectReports.list = resp.data.list;
            } else {
              msg = resp.data.message;
              let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': msg };
              this.$store.commit('modal', modal_data);
              this.$store.commit('modal_show', true);
            }
            this.$forceUpdate();
          });
      }
    },
    f_showChartWithTimeOut: function () {
      this.d_showChartView = false;
      setTimeout(function () {
        this.d_showChartView = true;
      }.bind(this), 500)
    },
    f_showWdmRuleA: function () {
      this.d_showWdmRuleA = false;
      setTimeout(function () {
        this.d_showWdmRuleA = true;
      }.bind(this), 500)
    },
    f_sendCellSocket: function () {
      let row_ind = this.d_editCell.split('_')[0];
      let col_ind = this.d_editCell.split('_')[1];
      let param_type = this.column_list[col_ind]['param_type'];
      let column_type = this.column_list[col_ind]['type'];
      let new_cell_date = {};
      let new_case_rows_value = '';
      // console.log('this.d_cellTextTimer', JSON.stringify(this.d_cellTextTimer));
      let case_rows_text = '';
      if (['select', 'object_list_options', 'checkbox', 'radiogroup'].indexOf(param_type) !== -1) {
        let option_cell_date_list = [];
        if (this.d_cellTextTimer.text === '') {
          new_case_rows_value = '';
        } else {
          let opt = {};
          for (let i in this.column_list[col_ind].options) {
            if (this.column_list[col_ind].options[i].value === this.d_cellTextTimer.text) {
              opt.label = this.column_list[col_ind].options[i].label;
              opt.value = this.column_list[col_ind].options[i].value;
              if (this.column_list[col_ind].options[i].cell_dates) {
                option_cell_date_list = this.column_list[col_ind].options[i].cell_dates;
                break;
              }
            }
          }
          new_case_rows_value = opt.label;
          // we must have cell_dates value of any option. But we control it. If we have not, we create a new cell dates data.
          if (option_cell_date_list.length === 0) {
            let x = { 'list': [{ 'val': { 'value': opt.value, 'label': opt.label } }] };
            option_cell_date_list.push(x);
          }
          let object_of_option_cell_date_list = {};
          for (let i in option_cell_date_list) {
            let wdmr_like_id = 'manuel_' + i;
            object_of_option_cell_date_list[wdmr_like_id] = {
              'id': wdmr_like_id,
              'type': 'manuel',
              'fill': 'manuel',
              'list': option_cell_date_list[i].list,
              'created_by': this.user.username,
              'date': new Date().toISOString()
            };
          }
          new_cell_date = object_of_option_cell_date_list;
        }
        // console.log(new_cell_date);
      } else {
        new_cell_date = {
          'manuel_0': {
            'id': 'manuel_0',
            'type': 'manuel',
            'fill': 'manuel',
            'list': [{ 'val': this.d_cellTextTimer.text }],
            'created_by': this.user.username,
            'date': new Date().toISOString()
          }
        };
        new_case_rows_value = this.d_cellTextTimer.text;
      }
      // console.log('new_case_rows_value', new_case_rows_value);
      this.d_dataset['case_rows'][row_ind][col_ind] = new_case_rows_value;
      this.d_dataset['cell_dates'][row_ind][col_ind] = new_cell_date;
      let socket_send = {
        'user': {
          'username': this.user.username,
          'first_name': this.user.first_name,
          'last_name': this.user.last_name
        },
        'type': 'polestar_cell_edit',
        'id': this.d_selectedProjectData.id,
        'row_ind': row_ind,
        'col_ind': col_ind,
        'row_no': this.d_dataset['case_id_list'][row_ind].no,
        'dataset_key': this.d_dataset['case_id_list'][row_ind].dataset_key,
        'val': {
          'case_rows': new_case_rows_value,
          'cell_dates': new_cell_date
        }
      };
      this.$socket.send(JSON.stringify(socket_send));
      this.$forceUpdate();
    },
    f_addEditColumnList: function () {
      let data = {
        'column_list': this.column_list,
        'project_id': this.d_selectedProjectData.id
      };
      this.$store.commit('Loading', { 'status': true, 'data': { 'label': 'Kolon işlemleri gerçekleştiriliyor. Lütfen bekleyiniz.' } });
      PoleStarService.edit_column_list(data)
        .then(resp => {
          this.$store.commit('Loading', { 'status': false, 'data': {} });
          let msg = '';
          if (resp.data.status === 'success') {
            msg = 'Kayıt işlemi gerçekleştirildi';
            // this.d_selectedColumnInd = '';
            this.d_selectedProjectData.design.column_list = resp.data.result.column_list;
            this.column_list.splice(0, this.column_list.length);
            for (let i in this.d_selectedProjectData.design.column_list) {
              this.column_list.push(this.d_selectedProjectData.design.column_list[i]);
            }
          } else {
            msg = resp.data.message;
          }
          this.mother_children.save_columns = 0;
          this.f_getCases();
          let modal_data = { 'type': 'alert', 'text': msg, 'centered': true, 'title': 'Veri Seti Kayıt işlemi' };
          this.$store.commit('modal', modal_data);
          this.$store.commit('modal_show', true);
        });
    },
  },
  watch: {
    'mother_children.save_columns': function () {
      if (this.mother_children.save_columns) {
        this.f_addEditColumnList();
        this.mother_children.save_columns = 0;
      }
    },
    'mother_children.update_project_data': function () {
      // console.log(this.mother_children);
      this.d_selectedProjectData = this.mother_children.d_selectedProjectData;
      this.f_controlIsEligible();
      this.$forceUpdate();
    },
    'mother_children.forceUpdate': function () {
      this.$forceUpdate();
    },
    'mother_children.get_project_cases': function () {
      this.f_getCases();
    },
    'mother_children.get_project_data': function () {
      this.f_goToThisProject();
    },
    'mother_children.article_naming': function () {
      for (let ar in this.d_selectedProjectData.data.articles.article.list) {
        if (this.d_selectedProjectData.data.articles.article.list[ar].wdm73_key.val === this.mother_children.wdm73_key) {
          this.d_selectedProjectData.data.articles.article.list[ar].wdm73_name.val = this.mother_children.a_name;
        }
        break;
      }
      this.$forceUpdate();
    },
    'mother_children.send_table_to_article': function () {
      this.f_addTableToArticle(this.mother_children.wdm72_result_data.table, this.mother_children.wdm72_result_data.analyze_no, this.mother_children.wdm72_result_data.table_name);
    },
    'd_cellTextTimer.text': function () {
      clearTimeout(this.d_cellTextTimer.timeout);
      let vm = this;
      this.d_cellTextTimer.timeout = setTimeout(function () {
        // console.log(vm.d_cellTextTimer.text);
        if (vm.d_editCell) {
          vm.f_sendCellSocket();
        }
      }, 500);
    },
    'd_tableModeSettings.current': function () {
      this.d_tableModeSettings.start = (this.d_tableModeSettings.current - 1) * this.d_tableModeSettings.perpage;
      this.d_tableModeSettings.end = (this.d_tableModeSettings.perpage * this.d_tableModeSettings.current) - 1;
      document.getElementById('case_table').scrollTop = 0;
    },
    'd_showMode': function () {
      if (this.d_showMode === 'dmp_list') {
        this.f_getWdmr24List();
      } else if (this.d_showMode === 'column_filter_show') {
        this.d_columnFilterShowModal = false;
        this.f_openColumnRuleFilterModal();
      } else if (this.d_showMode === 'analyze_results') {
        this.d_navAnalyzeResult = 'analyze_model';
      } else if (this.d_showMode === 'phrase_list') {
        this.f_getPhraseList();
      } else if (this.d_showMode === 'project_report') {
        this.f_getProjectAnalyzeReports();
      } else if (this.d_showMode === 'wtable') {
        this.f_projectDatasetGet(this.$route.query.project_id);
      }
    },
    'StoreModal.show': function () {
      if (!this.StoreModal.show && this.StoreModal.data.function_name) {
        if (this.StoreModal.data.function_name === 'f_deleteWdm72DataConfirmed' && this.StoreModal.data.confirm) {
          this.f_deleteWdm72DataConfirmed(this.StoreModal.data.arguments);
        } else if (this.StoreModal.data.function_name === 'f_deleteWdm73DataConfirmed' && this.StoreModal.data.confirm) {
          this.f_deleteWdm73DataConfirmed(this.StoreModal.data.arguments);
        } else if (this.StoreModal.data.function_name === 'f_prepareProjectCases_confirmed' && this.StoreModal.data.confirm) {
          this.f_prepareProjectCases_confirmed(this.StoreModal.data.arguments);
        } else if (this.StoreModal.data.function_name === 'f_cancelProjectUpdate_confirmed' && this.StoreModal.data.confirm) {
          this.f_cancelProjectUpdate_confirmed(this.StoreModal.data.arguments);
        } else if (this.StoreModal.data.function_name === 'f_deleteWdm163DataConfirmed' && this.StoreModal.data.confirm) {
          this.f_deleteWdm163DataConfirmed(this.StoreModal.data.arguments);
        }
      }
    },
    'd_datasetPagination.current': function () {
      this.d_datasetPagination.start = (this.d_datasetPagination.current - 1) * this.d_datasetPagination.perpage;
      this.d_datasetPagination.end = (this.d_datasetPagination.perpage * this.d_datasetPagination.current) - 1;
      this.f_getCases();
    },
    'd_wdm72Pagination.current': function () {
      this.d_wdm72Pagination.start = (this.d_wdm72Pagination.current - 1) * this.d_wdm72Pagination.perpage;
      this.d_wdm72Pagination.end = (this.d_wdm72Pagination.perpage * this.d_wdm72Pagination.current);
      this.d_selectedWdm72Results = [];
      this.f_getWdm72AnalyzeList();
    },
    'd_chartSize.x_interval_type': function () {
      this.d_chartSize.x_interval = 1;
    },
    'd_chartSize.chart_type': function () {
      for (let d in this.chart_data.datasets) {
        if (this.d_chartSize.chart_type === 'line') {
          this.chart_data.datasets[d].lineTension = 0;
          this.chart_data.datasets[d].backgroundColor = 'rgba(255, 255, 255, 0)';
          this.chart_data.datasets[d].showLine = true;
        } else {
          this.chart_data.datasets[d].backgroundColor = this.chart_data.datasets[d].borderColor;
          this.$delete(this.chart_data.datasets[d], 'lineTension');
          this.$delete(this.chart_data.datasets[d], 'showLine');
        }
      }
      this.f_showChartWithTimeOut();
      this.$forceUpdate();
    },
    'd_chartSize.x_interval': function () {
      this.chart_data.labels = [];
      for (let i in this.d_resetChartData.labels) {
        if (parseInt(i) === 0 || parseInt(i) === this.d_resetChartData.labels.length - 1) {
          this.chart_data.labels.push(this.d_resetChartData.labels[i]);
        } else {
          if (parseInt(i) % this.d_chartSize.x_interval === 0) {
            this.chart_data.labels.push(this.d_resetChartData.labels[i]);
          } else {
            this.chart_data.labels.push('');
          }
        }
      }
      this.f_showChartWithTimeOut();
      this.$forceUpdate();
    },
    'd_chartSize.x_mode': function () {
      /*
        chart_data = {
         'datasets': [], these are the matching y values
         'labels': [] ==>>>> this is the x axis
        }
      */
      if (this.d_chartSize.x_mode === 'standart') {
        this.chart_data.labels = JSON.parse(JSON.stringify(this.d_resetChartData.labels));
        for (let d in this.chart_data.datasets) {
          this.chart_data.datasets[d].data = JSON.parse(JSON.stringify(this.d_resetChartData.datasets[d].data));
        }
        this.chart_data.labels = this.d_resetChartData.labels;
      } else {
        let object_list = [];
        for (let i = 0; i < this.chart_data.labels.length; i++) {
          let new_obj = { 'label': this.chart_data.labels[i] };
          for (let d in this.chart_data.datasets) {
            new_obj[d] = this.chart_data.datasets[d].data[i];
          }
          object_list.push(new_obj);
        }
        let param = 'label';
        let asc_or_desc = 1;
        if (this.d_chartSize.x_mode === 'x_descending') {
          asc_or_desc = -1;
        }

        function compare(a, b) {
          // Use toUpperCase() to ignore character casing
          let param_a = '';
          let param_b = '';
          try {
            param_a = a[param].toLocaleLowerCase();
            param_b = b[param].toLocaleLowerCase();
          } catch (err) {
            param_a = a[param];
            param_b = b[param];
          }

          let comparison = 0;
          if (param_a > param_b) {
            comparison = 1;
          } else if (param_a < param_b) {
            comparison = -1;
          }
          return comparison * asc_or_desc;
        }
        object_list.sort(compare);
        this.chart_data.labels = [];
        for (let d in this.chart_data.datasets) {
          this.chart_data.datasets[d].data = [];
        }
        for (let i in object_list) {
          this.chart_data.labels.push(object_list[i]['label']);
          for (let d in this.chart_data.datasets) {
            this.chart_data.datasets[d].data.push(object_list[i][d]);
          }
        }
      }
      this.f_showChartWithTimeOut();
      this.$forceUpdate();
    },
    'd_chartSize.y_mode': function () {
      /*
        chart_data = {
         'datasets': [], these are the matching y values
         'labels': [] ==>>>> this is the x axis
        }
      */
      if (this.d_chartSize.y_mode === 'standart') {
        this.chart_data.labels = JSON.parse(JSON.stringify(this.d_resetChartData.labels));
        for (let d in this.chart_data.datasets) {
          this.chart_data.datasets[d].data = JSON.parse(JSON.stringify(this.d_resetChartData.datasets[d].data));
        }
        this.chart_data.labels = this.d_resetChartData.labels;
      } else {
        let object_list = [];
        for (let i = 0; i < this.chart_data.labels.length; i++) {
          let new_obj = { 'label': this.chart_data.labels[i] };
          for (let d in this.chart_data.datasets) {
            new_obj[d] = this.chart_data.datasets[d].data[i];
          }
          object_list.push(new_obj);
        }
        let param = 0;
        let asc_or_desc = 1;
        if (this.d_chartSize.y_mode === 'y_descending') {
          asc_or_desc = -1;
        }

        function compare(a, b) {
          // Use toUpperCase() to ignore character casing
          const param_a = a[param];
          const param_b = b[param];

          let comparison = 0;
          if (param_a > param_b) {
            comparison = 1;
          } else if (param_a < param_b) {
            comparison = -1;
          }
          return comparison * asc_or_desc;
        }
        object_list.sort(compare);
        this.chart_data.labels = [];
        for (let d in this.chart_data.datasets) {
          this.chart_data.datasets[d].data = [];
        }
        for (let i in object_list) {
          this.chart_data.labels.push(object_list[i]['label']);
          for (let d in this.chart_data.datasets) {
            this.chart_data.datasets[d].data.push(object_list[i][d]);
          }
        }
      }
      this.f_showChartWithTimeOut();
      this.$forceUpdate();
    }
  }
};

</script>

<style>
canvas {
  background-color: white;
}

.row-right-click {
  overflow-y: auto;
  visibility: visible;
  animation: fadeIn 0.3s;
  -webkit-animation: fadeIn 0.3s;
  background-color: #d9f0f0;
  border: solid 1px #89c1a3;
  color: #fff;
  /*text-align: center;*/
  border-radius: 3px;
  padding: 8px 0;
  position: fixed;
  z-index: 1000;
}

.row-right-click::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #bed0da;
}

.row-right-click::-webkit-scrollbar {
  width: 10px;
  background-color: #bed0da;
}

.row-right-click::-webkit-scrollbar-thumb {
  border-radius: 100px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #bed0da;
}

.row-right-click li {
  cursor: pointer;
  border-bottom: 1px solid rgba(158, 193, 179, 1);
  padding: 5px;
}

.row-right-click ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.row-right-click li:hover {
  border-bottom: 1px solid rgba(158, 193, 179, 1);
  padding: 5px;
  background-color: #bfdfdf;
}

.popup {
  visibility: visible;
  animation: fadeIn 1s;
  -webkit-animation: fadeIn 1s;
  width: 160px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: fixed;
  z-index: 1;
}


/* Popup arrow */

.popup::after {
  content: "";
  position: fixed;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}


/* Add animation (fade in the popup) */

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#polestar-wisdom-stats ul.dropdown-menu {
  background-color: white;
}

#polestar-wisdom-stats a.dropdown-item {
  color: black !important;
}

.cell-class:hover {
  background-color: rgb(233, 253, 233);
}

#column-filter-dropdown-text.b-dropdown-text {
  padding: 0px;
  margin: 0px;
  width: 100%;
  background-color: white;
}

#column-filter-b-dropdown ul.dropdown-menu {
  background-color: #b4bfc1;
}

#descriptive ul.dropdown-menu {
  background-color: white;
}

#descriptive a.dropdown-item {
  color: black !important;
}

#article ul.dropdown-menu {
  background-color: white;
}

#article a.dropdown-item {
  color: black !important;
}

#math_operators ul.dropdown-menu {
  background-color: white;
}

#math_operators a.dropdown-item {
  color: black !important;
}

#comparison ul.dropdown-menu {
  background-color: white;
}

#comparison a.dropdown-item {
  color: black !important;
}

#case_table::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #d9e9ea;
}

#case_table::-webkit-scrollbar {
  width: 5px;
  background-color: #F5F5F5;
}

#case_table::-webkit-scrollbar-thumb {
  border-radius: 100px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #4788ac;
}

.general_scroll_class::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #d9e9ea;
}

.general_scroll_class::-webkit-scrollbar {
  width: 10px;
  background-color: #F5F5F5;
}

.general_scroll_class::-webkit-scrollbar-thumb {
  border-radius: 100px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #4788ac;
}

.normal-mode {}

.full-screen-mode {
  position: fixed;
  z-index: 1021;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background-color: rgba(0, 0, 0, .5);*/
  /*display: table;*/
  -webkit-transition: opacity .3s ease;
  transition: opacity .3s ease;
}

</style>

